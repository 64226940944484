import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Schedule from '@material-ui/icons/Schedule';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8,4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
 
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SignUp() {
  const classes = useStyles();
  const toast = useToast();

  const [teacher, setTeacher] = useState([]);
  const [groups, setGroups] = useState([]);

  const [subject, setSubject] = useState("");
  const [startTime, setStartTime] = useState("");
  const [name, setName] = useState("");

  const [teacherName, setTeacherName] = useState("");
  const [invited, setInvited] = useState([]);

  var sort = function(arr){
    // prop = prop.split('.');
    var len = arr.length;

    arr.sort(function (a, b){
        var i = 0;
        while(i < len ){ a = arr[i].name; b = arr[i].name; i++; }
        if(a<b){
            return -1;
        } else if(a > b){
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
  }

  const handleChangeTeacher = (event) => {
    setTeacherName(event.target.value);
  };

  const handleChangeStudent = (event) => {
    setInvited(event.target.value)
  }

  const scheduleMeet = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/class/scheduleMeet`,{
      adminId: JSON.parse(localStorage.getItem("user")).email,
      subject: subject,
      teacherId: teacherName,
      startTime: startTime,
      groups: invited,
      name: name
    })
    .then(res => {
      setSubject("");
      setName("");
      setTeacherName("");

      toast({
        title: "Class Scheduled And Students Notified"
      })
    })
    .catch(err => {
      toast({
        title:"Class Scheduling Failed",
        position: "top"
      })
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getTeacherList`,{
      adminId: JSON.parse(localStorage.getItem("user")).email
    })
    .then((res) => {
      setTeacher(res.data.teacher);
    })
    .catch(err => {
      toast({
        title: "Error Occured",
        position: "top"
      })
    })

    axios.post(`${process.env.REACT_APP_SERVER}/group/getExisting`,{
      adminId: JSON.parse(localStorage.getItem("user")).email
    })
    .then((res) => {
      sort(res.data.group);
      setGroups(res.data.group);
    })
    .catch(err => {
      toast({
        title: "Error Occured",
        position: "top"
      })
    })
  }, [toast])
return (
  <Container component="main" maxWidth="md">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <Schedule />
      </Avatar>
      <Typography component="h1" variant="h5">
        Scheduling a new class
      </Typography>
      <form className={classes.form} onSubmit={scheduleMeet}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <TextField
              required
              fullWidth
              id="Name"
              label="Meeting Name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete=""
            />
          </Grid>
          <Grid item xs={12} >
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Teacher</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={teacherName}
                  onChange={handleChangeTeacher}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {teacher.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} >
            <TextField
              required
              fullWidth
              id="Subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              label="Subject"
              name="subject"
              autoComplete=""
            />
          </Grid>
          <Grid item xs={4} >
            <TextField
              variant="filled"
              required
              fullWidth
              label="Starting Time:-"
              disabled
            />
          </Grid>
          
          <Grid item xs={8} >
            <TextField
              variant="outlined"
              required
              fullWidth
              id="S_time"
              type="time"
              name="S_time"
              onChange={e => setStartTime(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="demo-mutiple-name-label">Students</InputLabel>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={invited}
              onChange={handleChangeStudent}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {groups.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          DONE
        </Button>
      </form>
    </div>
  </Container>
);
}