import styled from "styled-components";

export const Conference = styled.div`
    position: relative;
    width: 100%;
    background-color: #1E232F;
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
    
`

export const TabContainer = styled.div`
    position: absolute;
    bottom: 3%;
    left: 25%;

    @media (max-width: 768px){
        position: unset;
        width: 100%;
        left: 0%;
        display: grid;
        place-items: center;
    }
`