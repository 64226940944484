import React, {useState} from 'react';
import { isAuth } from '../../helper/auth';
import { Redirect } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" href="#">
        PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/yiCOCqZ-ig4)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '1200px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide({history, match}) {
  const classes = useStyles();
  const toast = useToast();

  const [nPass, setnPass] = useState("");
  const [cPass, setcPass] = useState("");

  const passwordReset = (e) => {
    e.preventDefault();

    if(nPass === cPass){

      axios.post(`${process.env.REACT_APP_SERVER}/auth/resetPassword`, {
        newPassword: nPass, resetPasswordLink: match.params.token
      })
      .then(res => {

        setnPass("");
        setcPass("");

        setTimeout(() => {
          history.push("/signIn");
        }, 1000);
        toast({
          title: "Password Reset Successfull",
          isClosable: true,
          position: "top",
          status: "success"
        })
      })
      .catch(err => {
        toast({
          title: "Password Reset Failed",
          isClosable: true,
          position: "top",
          status: "error"
        })
      })
    } else {
      toast({
        title: "Password Don't Match",
        position: "top",
        status: "error"
      })
    }
  }  

  return (
    <>
    {isAuth() ? <Redirect to='/' /> : null}
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <RotateLeftIcon />
          </Avatar>
          
          <form className={classes.form} onSubmit={passwordReset}>

            <br/><br/><br/>
            <h5 align="center">Reset your password</h5>  
            <label>New Password</label>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              value={nPass}
              onInput={e => setnPass(e.target.value)}
              autoComplete="password"
              autoFocus
            />

            <label>Confirm Password</label>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="Con_password"
              name="Con_password"
              value={cPass}
              onInput = { e => setcPass(e.target.value)}
              autoComplete="password"
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              SUBMIT
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    </>
  );
}