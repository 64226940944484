import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Button, Box, Stack, Center, useToast, Heading, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel, List, Input} from '@chakra-ui/react';
import { intializeTeacherStream, sendMessagePublic } from './teacherStreamSection';
import { 
    JoinTeacherInterface,
    VideoContainer,
    VideoHolder,
    ClassDetailContainer,
    ClassDetailWrapper,
    ClassTopic,
    ClassTextWrapper,
    ClassDetail,
    ClassStartText,
    ClassBtnWrapper,
    ButtonJoin,
    ClassInterface,
    VideoHolderClass,
    OwnVideoHolder,
    OwnVideo,
    StudentVideoContainer,
    ParticipantsHolder,
    ButtonHolder,
    Utils,
    Attendence,
    Disconnect,
    MessageContainerTeacher
} from './ClassStartComponents';
import {
    MessageInput,
    Messages
} from '../ClassEntry/ClassEntryComponents';
import { BsFillMicFill } from 'react-icons/bs';
import { BiMicrophoneOff, BiCameraOff, BiCamera } from 'react-icons/bi';
import { AiOutlineSend } from 'react-icons/ai';
import { MdScreenShare,MdStopScreenShare } from 'react-icons/md';
import Header from '../Header';
import Badge from '@material-ui/core/Badge';
import { SocketContext } from '../../helper/socketService';

export const attendenceFunction = (meetId ,list) => {

    axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER}/class/attendence/${meetId}`,
        responseType: 'blob',
        headers:{},
        data:{
            attendence: list
        }
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute("download", `Attendence_${meetId}.csv`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          alert("File Download Failed");
        });
}

function ClassStart({match}){

    const toast = useToast();
    const [meetData, setMeetData] = useState([]);
    const [message, setMessage] = useState("");

    const handleMessage = (e) => {
        setMessage(e.target.value)
    }

    const sendMessage = () => {
        if(message !== ""){
            sendMessagePublic(match.params.meetId, message);
            setMessage("");
        }
    }

    useEffect(()=>{
        axios.post(`${process.env.REACT_APP_SERVER}/class/getClassDetails`,{
            meetId: match.params.meetId
        })
        .then((res) => {
            setMeetData(res.data.meet);
            intializeTeacherStream(match.params.meetId);
        })
        .catch(err => {
            toast({
                title: "Error Occured.",
                description: "If the error persists, please inform the admin",
                status: "error",
                duration: 3000,
                isClosable: true
            })
        })
    }, [match.params.meetId, toast])

    return (
        <>  
            <div id="joinInterface">
                <Header id="headerToggle"/>
                <JoinTeacherInterface>
                    <VideoContainer>
                        <VideoHolder id="videoHolder"></VideoHolder>
                        <Center w={"100%"}>
                        <ButtonHolder join={true}>
                            <Tooltip label="Audio Control" bg="white" color="purple.600">
                                <Button colorScheme="purple" id="stopAudio" mr={3} w={"100%"}>
                                    <BsFillMicFill id="stopAudioIcon"  /><BiMicrophoneOff id="startAudioIcon" style={{display:"none"}}/>
                                </Button> 
                            </Tooltip>
                            <Tooltip label="Video Control" bg="white" color="purple.600" >
                                <Button colorScheme="purple" id="stopVideo" mr={3} w={"100%"}>
                                    <BiCamera id="stopCameraIcon"/><BiCameraOff id="startCameraIcon"  style={{display:"none"}}  />
                                </Button>
                            </Tooltip>
                        </ButtonHolder>
                        </Center>
                    </VideoContainer>
                    <ClassDetailContainer>
                        {typeof meetData === 'undefined' ? null : 
                            meetData.length !== 0 ?
                                meetData.map((meet) => {
                                return (
                                    <ClassDetailWrapper key={meet.meetId}>
                                        <ClassTopic>{meet.name}</ClassTopic>
                                        <ClassTextWrapper>
                                            <ClassDetail>Class ID:  {meet.meetId}</ClassDetail>
                                            <ClassDetail>Class Time: {meet.startTime}</ClassDetail>
                                        </ClassTextWrapper>
                                        <ClassStartText>Ready to start the class?</ClassStartText>
                                        <ClassBtnWrapper>
                                            <ButtonJoin id="startClass" big={true}>Start The Class</ButtonJoin>
                                        </ClassBtnWrapper>
                                    </ClassDetailWrapper>
                                )}
                            )
                        : 
                            <Stack p={3}>
                                <Heading>Class Details</Heading>
                                <Box fontSize={["18px", "22px"]} fontFamily="Georgia" >
                                    The class with the given Id was not found. Please make sure that the class ID is correct. If this is an unusual error, please try refreshing the page. If the error persists, please contact the admin.
                                </Box>    
                            </Stack>
                        }
                    </ClassDetailContainer>
                </JoinTeacherInterface>
            </div>
            <ClassInterface id="classInterface">
                <VideoHolderClass>
                    <OwnVideoHolder>
                        <OwnVideo id="teacherOwnVideo"></OwnVideo>
                        <StudentVideoContainer id="studentVideoContainer"></StudentVideoContainer>
                    </OwnVideoHolder>
                    <ParticipantsHolder>
                        <Tabs pl={1} pr={1} variant="solid-rounded" isFitted width="100%" isManual align="center" pt={5}>
                            <TabList>
                                <Tab><Badge color="secondary" variant="dot" id="pBadge" invisible={true}>Participants</Badge></Tab>
                                <Tab><Badge color="secondary" variant="dot" id="msgBadge" invisible={true}>Chat</Badge></Tab>
                                <Tab><Badge color="secondary" variant="dot" id="rBadge" invisible={true}>Response</Badge></Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <List spacing={2} id="participantsList">
                                    </List>
                                </TabPanel>
                                <TabPanel pl={"15px"} pr={"15px"} pt={0} pb={0}>
                                    <MessageContainerTeacher>
                                        <Messages>
                                            <List spacing={2} pt={3} id="messageList"></List>
                                        </Messages>
                                        <MessageInput>
                                            <Input value={message} onChange={handleMessage} placeholder="Public Chat" onKeyUp={(e) => {if(e.code == 'Enter'){sendMessage()}}} focusBorderColor="purple.600" />
                                            <Button colorScheme="purple" ml={3} borderRadius="25px" onClick={sendMessage}><AiOutlineSend /></Button>
                                        </MessageInput>
                                    </MessageContainerTeacher>
                                </TabPanel>
                                <TabPanel>
                                    <List spacing={2} id="responseList"></List>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ParticipantsHolder>
                </VideoHolderClass>
                <Utils>
                    <Disconnect>
                        <Button id="recordClass" mr={3} disabled>Record Class</Button>
                        <Button id="endClass">End The Class</Button>
                    </Disconnect>
                    <ButtonHolder join={false}>
                        <Tooltip label="Audio Control" bg="white" color="purple.600">
                            <Button colorScheme="purple" id="stopOwnAudio" w={"100%"} mr={3}>
                                <BsFillMicFill id="aOnIcon" style={{display:"none"}} /><BiMicrophoneOff id="aOffIcon" />
                            </Button> 
                        </Tooltip>
                        <Tooltip label="Video Control" bg="white" color="purple.600" >
                            <Button colorScheme="purple" id="stopOwnVideo" w={"100%"} mr={3}>
                                <BiCamera id="cOnIcon" style={{display:"none"}} /><BiCameraOff id="cOffIcon" />
                            </Button>
                        </Tooltip>
                        <Tooltip label="Share Screen" bg="white" color="purple.600">
                            <Button colorScheme="purple" id="shareOwnBtn" w={"100%"} ><MdScreenShare /></Button>
                        </Tooltip> 
                        <Tooltip label="Stop Share" bg="white" color="purple.600">
                            <Button colorScheme="purple" id="stopShareBtn" w={"100%"} disabled><MdStopScreenShare /></Button>
                        </Tooltip> 
                    </ButtonHolder>
                    <Attendence>
                        <Button id="attendenceClass" mr={3} disabled>Attendance</Button>
                        <Button id="mute">Mute Everyone</Button>
                    </Attendence>
                </Utils>
            </ClassInterface>
        </>
    )
}

export default ClassStart;