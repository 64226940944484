import styled from 'styled-components';

export const Section = styled.div`
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #F6F6F6;

    @media screen and (max-width: 768px){
        width: 100%;
        height: 1300px;
    }
`

export const Holder = styled.div`
    display: grid;
    grid-template-rows: 2fr 8fr;
    height: 100%;
    width: 100%;
    grid-gap: 20px;
`
export const TitleHolder = styled.div`
    display: flex;
    align-items: center;
    font-size: 45px;
    width: 100%;
    justify-content: center;
    font-family: sans-serif;
`

export const Title = styled.div`
    border-bottom: 5px solid black;
`

export const FeatureHolder = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;

    @media screen and (max-width: 768px){
        grid-template-rows: auto auto auto auto;
        grid-gap: 20px;
        grid-template-columns: unset;
    }
`

export const FeatureCard = styled.div` 
    padding: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`

export const FeatureIcon = styled.div`
    color: white;
    border-radius: 50px;
    background: #7f00ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7f00ff, #e100ff); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7f00ff, #e100ff);
    height: 120px;
    width: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
`

export const FeatureTextHolder = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FeatureTitle = styled.div`
    color: black;
    font-size: 20px;
`

export const FeatureText = styled.div`
    color: black;
    text-align: center;
`