import * as stateActions from "../components/ClassComponent/stateManagers/stateActions";
import * as cookiesManager from '../components/ClassComponent/stateManagers/cookiesManager';
import randomString from "random-string";

const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
}

const VIDEO_CONSTRAINS = {
    qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
    vga: { width: { ideal: 640 }, height: { ideal: 480 } },
    hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
  };
  

const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
}

let store;

class ClassClient {

    static init(data) {
        store = data.store;
    }

    constructor({classId, name, type, mediasoup, device, handler, socket}) {
        this.name = name
        this.classId = classId
        this.mediasoupClient = mediasoup
        this.handlerDevice = device
        this.handlerName = handler
        this.type = type
        this.socket = socket;

        this.toast = null;
        this.toastId = null;

        this.producerTransport = null;
        this.consumerTransport = null;
        this.device = null;
        
        this.consumers = new Map();
        this.producers = new Map();

        this.stream = null;

        this.producerLabel = new Map();
        this._isOpen = false;
        this.eventListeners = new Map();

        this._webcams = new Map();
        this._webcam = {
            device: null,
            resolution: 'hd' 
        }

        this._audioInputs = new Map();
        this._audioInput = {
            device: null
        }

        Object.keys(_EVENTS).forEach(function (evt){
            this.eventListeners.set(evt, [])
        }.bind(this))

        this.socket.request = function request(callType, data = {}) {
            return new Promise((resolve, reject) => {
                socket.emit(callType, data, (data) => {
                    if(data.error){
                        reject(data.error)
                    } else {
                        resolve(data);
                    }
                })
            })
        }
    }

    async startClass({history, toast, toast_id}) {
        this.toast = toast;
        this.toastId = toast_id;

        store.dispatch(
            stateActions.setClassState("starting")
        );

        await this.socket.request('startClass', { classId: this.classId } )
        .then(async (res) => {
            
            // changed the page content to the other side with status joined.
            setTimeout(async () => {
                store.dispatch(
                    stateActions.setClassState("started")
                );

                history.push(`/join/${this.classId}`);
                console.log("Class Started With Id", res);
                
                this.initSockets();
                await this.joinClass({classId: this.classId, name: this.name, type: this.type});
            }, 2000);
        })
        .catch((err) => {
            store.dispatch(
                stateActions.setClassState("ended")
            )
            // handler for Class Start Error.
            if(!toast.isActive(toast_id)) {
                toast({
                    id: toast_id,
                    description: err,
                    duration: 2000,
                    position: "top-right"
                })
            }
        })
    }

    async enterClass({history, toast, toast_id}) {
        this.toast = toast;
        this.toastId = toast_id;

        store.dispatch(
            stateActions.setClassState("entering")
        );

        await this.socket.request('enterClass', { classId: this.classId})
        .then(async (res) => {

            // enter Class event response.
            setTimeout(async () => {
                store.dispatch(
                    stateActions.setClassState("entered")
                )
                
                this.teacherPeer = res.peer;
                this.teacherSocketId = res.socketId;

                history.push(`/join/${this.classId}`)
                console.log("--- Class Join Request Res Received ----", res);

                this.initSockets();
                this.joinClass({classId: this.classId, name: this.name, type: this.type});
            }, 1500);
        })
        .catch((err) => {
            setTimeout(() => {
                store.dispatch(
                    stateActions.setClassState("ended")
                )
    
                if(!toast.isActive(toast_id)) {
                    toast({
                        id: toast_id,
                        description: err,
                        duration: 3000,
                        position: "top-right"
                    })  
                }
            }, 1500);
        })
    }

    // store use to be integrated in this.
    async joinClass({classId, name, type}) {

        this.socket.request('joinClass', {classId, name, type})
        .then(async function(peer) {
            console.log("Reacher Here!", peer);
            
            // store.dispatch(
            //     stateActions.addPeer({...peer, consumers: []})
            // )

            const data = await this.socket.request('getRouterRtpCapabilities');
            let device = await this.loadDevice(data)
            this.device = device
            await this.initTransports(device)

            store.dispatch(stateActions.setMediaCapabilities({
                canSendMic: this.device.canProduce('audio'),
                canSendWebcam: this.device.canProduce('video')
            }));

            await this._updateAudioInputs();
            await this.produce(mediaType.audio, this._audioInput.device)

            const micStatus = cookiesManager.getMicStatus();
            console.log("--- Mic Status Cookie ---", micStatus);
            if(!(micStatus || {}).micEnabled || !micStatus){
                // audio producer handling.
                this.pauseProducer('audioType');
            }

            const videoStatus = cookiesManager.getDevices();
            console.log("--- Camera Status Cookie ---", videoStatus);
            if(!videoStatus || (videoStatus || {}).webcamEnabled){
                // first update the number of webcams and then 
                // video Producer handling.

                this._updateWebcams();
                this.produce(mediaType.video, this._webcam.device);
            }

            if(this.type === "student"){
                console.log("--- teacher peer received ---", this.teacherPeer);

                //setup for the teacher connection
                if(this.teacherPeer){
                    store.dispatch(
                        stateActions.addPeer({...this.teacherPeer, consumers: []})
                    )
                }
                // this was getting only the teacher producers.
                this.socket.emit('getProducers', {teacherPeer: this.teacherPeer, socketId: this.teacherSocketId});

                //getting the peers other than the teacher in the class.
                await this.socket.request('getPeers', {classId: this.classId})
                    .then((list) => {
                        console.log("--Student Fetch peerList--", list);

                        for(var key in list){
                            if(this.teacherPeer.id !== list[key].id){
                                store.dispatch(
                                    stateActions.addPeer({...list[key], consumers: []})
                                )
                            }
                        }
                    })
                
                // fetch the producers but only of the audio type of other students and not of the teacherPeer.
                this.socket.emit('getProducersOfOther', {
                    teacherPeer: this.teacherPeer,
                    type: 1 // 1 for audio
                })
            } else {
                console.log("--- Teacher Join Event ---");

                await this.socket.request('getPeers', { classId: this.classId })
                    .then((peerList) => {
                        console.log("--- peerList ---", peerList);
                        for(var key in peerList){
                            store.dispatch(
                                stateActions.addPeer({...peerList[key], consumers: []})
                            )
                        }
                    });
                this.socket.emit('getProducers');
            }

        }.bind(this))
        .catch((err) => {
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: typeof err === "string" ? err : "Exception Occurred",
                    position: "top-right",
                    duration: 3000,
                    id: this.toastId
                })
            }
        })
    }

    async loadDevice(routerRtpCapabilities) {
        let device
        try {
            device = new this.mediasoupClient.Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                if(!this.toast.isActive(this.toastId)) {
                    this.toast({
                        id: this.toastId,
                        description: "Unsupported Browser",
                        position: "top-right",
                        duration: 3000
                    })
                }
            }
            if(!this.toast.isActive(this.toastId)) {
                this.toast({
                    id: this.toastId,
                    description: error.name,
                    position: "top-right",
                    duration: 3000
                })
            }
        }
        await device.load({
            routerRtpCapabilities
        })
        return device
    }

    async initTransports(device) { 

        // init producerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities,
            })
            if (data.error) {
                console.error(data.error);
                return;
            }

            this.producerTransport = device.createSendTransport(data);

            this.producerTransport.on('connect', async function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        dtlsParameters,
                        transport_id: data.id
                    })
                    .then(callback)
                    .catch(errback)
            }.bind(this));

            this.producerTransport.on('produce', async function ({
                kind,
                rtpParameters
            }, callback, errback) {
                try {
                    const {
                        producer_id
                    } = await this.socket.request('produce', {
                        producerTransportId: this.producerTransport.id,
                        kind,
                        rtpParameters,
                    });
                    callback({
                        id: producer_id
                    });
                } catch (err) {
                    errback(err);
                }
            }.bind(this))

            this.producerTransport.on('connectionstatechange', function (state) {
                switch (state) {
                    case 'connecting':

                        break;

                    case 'connected':
                        //localVideo.srcObject = stream
                        break;

                    case 'failed':
                        this.producerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

        // init consumerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
            });
            if (data.error) {
                console.error(data.error);
                return;
            }

            // only one needed
            this.consumerTransport = device.createRecvTransport(data);
            this.consumerTransport.on('connect', function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        transport_id: this.consumerTransport.id,
                        dtlsParameters
                    })
                    .then(callback)
                    .catch(errback);
            }.bind(this));

            this.consumerTransport.on('connectionstatechange', async function (state) {
                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        //remoteVideo.srcObject = await stream;
                        //await socket.request('resume');
                        break;

                    case 'failed':
                        this.consumerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

    }

    initSockets() {
        this.socket.on('consumerClosed', function ({
            consumer_id
        }) {
            console.log('closing consumer:', consumer_id)
            this.removeConsumer(consumer_id)
        }.bind(this))

        this.socket.on('consumerPaused', function({
            consumer_id
        }){

            console.log("Reached Here");
            this.pauseConsumer(consumer_id)
        }.bind(this))

        this.socket.on('consumerResumed', function({
            consumer_id
        }){
            this.resumeConsumer(consumer_id)
        }.bind(this))

        this.socket.on('studentProducerPaused', function({id}) {
            stateActions.setMuteState(id, true);
        })
        this.socket.on('studentProducerResumed', function({id}) {
            stateActions.setMuteState(id, false);
        })
        this.socket.on('newProducers', async function (data) {
            console.log('--- new producers ---', data);
            for (let {
                    producer_id,
                    peer_id
                } of data) {
                await this.consume(producer_id, peer_id)
            }
        }.bind(this))

        this.socket.on('newStudent', async function (student){
            console.log("--- new Student Joined ---", student);

            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: "New Student Joined",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
            }
            // handle the toast part here!.

            store.dispatch(
                stateActions.addPeer({...student, consumers: []})
            )
        }.bind(this));

        this.socket.on('newTeacherMessage', ({messageId, sender, message, time, type}) => {
            store.dispatch(
                stateActions.addMessage({messageId, sender, message, time, status: "received", type})
            )
        })

        this.socket.on('studentMessage', ({messageId, sender, message, time}) => {
            store.dispatch(
                stateActions.addMessage({messageId, sender, message, time, status: "received"})
            )
        })

        this.socket.on('privateMessage', ({messageId, sender, message, time, type}) => {
            store.dispatch(
                stateActions.addMessage({messageId, sender, message, time, status: "received", type})
            )
        })

        this.socket.on('studentResponded', ({name, response}) => {
            let message = "";

            switch(response){
                case 'handraise': {
                    message = `${name} raised hand!`
                    break;
                }
                case 'understood': {
                    message = `${name} has understood!`
                    break;
                }
                case 'notUnderstood': {
                    message = `${name} has not understood!`
                    break;
                }
                default:
                    return;
            }

            store.dispatch(
                stateActions.addStudentResponse({
                    response, name, message
                })
            )
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: message,
                    duration: 900,
                    position: 'top-right',
                    id: this.toastId
                })
            }
        })

        this.socket.on('getMuted', () => {
            this.pauseProducer('audioType');
        })

        this.socket.on('indivualMute', () => {
            this.pauseProducer('audioType');
        })

        this.socket.on('youWereRemoved', () => {
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    id: this.toastId,
                    description: "You were removed!",
                    duration: 1000,
                    position: "top-right"
                })
            }

            window.location.href = `${process.env.REACT_APP_CLIENT}/${this.type}`
        })

        this.socket.on('studentLeft', ({socketId, name}) => {

            store.dispatch(
                stateActions.removePeer(socketId)
            )

            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: `${name} left!`,
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        })

        this.socket.on('teacherLeft', () => {
            console.log("--- Teacher Has Left! ---");

            this.toast({
                description: "The class has ended!",
                duration: 1000,
                position: "top-right"
            })
            setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_CLIENT}/${this.type}`
            }, 1001);
        })

        this.socket.on('disconnect', function () {
            this.exit(true)
        }.bind(this))
    }

    async _updateWebcams() {

        this._webcams = new Map();
        const devicesConnected = await navigator.mediaDevices.enumerateDevices();

        for (const device of devicesConnected) {
            if(device.kind !== 'videoinput')
                continue;
            
            this._webcams.set(device.deviceId, device);
        }

        const array = Array.from(this._webcams.values());
        const len = array.length;

        const currrentWebcamId = 
            this._webcam.device ? this._webcam.device.deviceId : undefined;

        if(len === 0){
            this._webcam.device = null;
        } else if(!this._webcams.has(currrentWebcamId))
            this._webcam.device = array[0];
        
        store.dispatch(
            stateActions.setCanChangeWebcam(this._webcams.size > 1)
        )
    }

    async _updateAudioInputs() {

        this._audioInputs = new Map();
        const devicesConnected = await navigator.mediaDevices.enumerateDevices();

        for (const device of devicesConnected) {
            if(device.kind !== 'audioinput')
                continue;
            
            this._audioInputs.set(device.deviceId, device);
        }

        const array = Array.from(this._audioInputs.values());
        const len = array.length;

        const currrentAudioId = 
            this._audioInput.device ? this._audioInput.device.deviceId : undefined;

        if(len === 0){
            this._audioInput.device = null;
        } else if(!this._audioInputs.has(currrentAudioId))
            this._audioInput.device = array[0]

    }

    async enableWebcam() {
        try{
            await this._updateWebcams();
            const device = this._webcam.device;

            const { resolution } = this._webcam;
            if(!device) throw new Error("No Webcam Devices found");

            this.produce('videoType', device.deviceId);
        } catch(error) {
            if(!this.toast.isActive(this.toastId)) {
                this.toast({
                    id: this.toastId,
                    description: error,
                    duration: 3000,
                    position: "top-right"
                })
            }
        }
    }

    async enableShare() {
        if(this.producerLabel.has('srceenType')) return;
        else if(this.producerLabel.has('videoType')) await this.closeProducer('videoType')

        if(!this.device.canProduce("video")) {
            // toast for missing share.
            return;
        }

        let track;
        store.dispatch(stateActions.setShareInProgress(true));

        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
              displaySurface: "monitor",
              logicalSurface: true,
              cursor: true,
              width: { max: 1920 },
              height: { max: 1080 },
              frameRate: { max: 30 },
            },
          });
    
          // May mean cancelled (in some implementations).
          if (!stream) {
            store.dispatch(stateActions.setShareInProgress(true));
    
            return;
          }
    
            track = stream.getVideoTracks()[0];
            const params = { track };
            params.encodings = [{
                rid: 'r0',
                maxBitrate: 100000,
                //scaleResolutionDownBy: 10.0,
                scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r1',
                    maxBitrate: 300000,
                    scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r2',
                    maxBitrate: 900000,
                    scalabilityMode: 'S1T3'
                }
            ];
            params.codecOptions = {
                videoGoogleStartBitrate: 1000
            };
            this._shareProducer = await this.producerTransport.produce({...params, appData:{ share: true}});

            this.producers.set(this._shareProducer.id, this._shareProducer);
            this.producerLabel.set('screenType', this._shareProducer.id)
            
            store.dispatch(
                stateActions.addProducer({
                  id: this._shareProducer.id,
                  type: "share",
                  paused: this._shareProducer.paused,
                  track: this._shareProducer.track,
                  rtpParameters: this._shareProducer.rtpParameters,
                  codec:
                    this._shareProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
                })
            );

            this._shareProducer.on("transportclose", () => {
                this._shareProducer = null;
            });
        
            this._shareProducer.on("trackended", () => {
                
        
                this.closeProducer('screenType').catch(() => {});
            });
        } catch(error) {
            console.log("Enable Share Error", error);
            // toast for Error

            if(track) track.stop();
        }

        store.dispatch(stateActions.setShareInProgress(false));
    }

    async produce(type, deviceId = null) {
        let mediaConstraints = {}
        let audio = false
        let screen = false
        switch (type) {
            case mediaType.audio:
                mediaConstraints = {
                    audio: {
                        deviceId: deviceId
                    },
                    video: false
                }
                audio = true
                break;
            case mediaType.video:
                mediaConstraints = {
                    audio: false,
                    video: {
                        width: {
                            min: 640,
                            ideal: 1920
                        },
                        height: {
                            min: 400,
                            ideal: 1080
                        },
                        deviceId: deviceId
                    }
                }
                store.dispatch(stateActions.setWebcamInProgress(true));
                break;
            default:
                return
        }
        if (!this.device.canProduce('video') && !audio) {
            if(!this.toast.isActive(this.toastId)) {
                this.toast({
                    description: "Camera Not Accessible",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
            }
            return;
        }
        if (this.producerLabel.has(type)) {
            if(!this.toast.isActive(this.toastId)) {
                this.toast({
                    description: "Camera Already In Use",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
            }
            return
        }
        console.log('--- mediacontraints ---', mediaConstraints)
        let stream;

        try {
            stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
            console.log("--- Supported Constraints ---", navigator.mediaDevices.getSupportedConstraints())

            const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
            const params = {
                track
            };

            if(screen){
                store.dispatch(stateActions.setShareInProgress(true))
            }

            if(screen && !stream){
                store.dispatch(stateActions.setShareInProgress(true))
            }

            if (!audio) {
                params.encodings = [{
                        rid: 'r0',
                        maxBitrate: 100000,
                        //scaleResolutionDownBy: 10.0,
                        scalabilityMode: 'S1T3'
                    },
                    {
                        rid: 'r1',
                        maxBitrate: 300000,
                        scalabilityMode: 'S1T3'
                    },
                    {
                        rid: 'r2',
                        maxBitrate: 900000,
                        scalabilityMode: 'S1T3'
                    }
                ];
                params.codecOptions = {
                    videoGoogleStartBitrate: 1000
                };
            }
            let producer = await this.producerTransport.produce(params)

            console.log('--- Created Producer --- ', producer)
            this.producers.set(producer.id, producer);
            // producer set after creating.

            store.dispatch(
                stateActions.addProducer({
                    id: producer.id,
                    paused: producer.paused,
                    track: producer.track,
                    rtpParameters: producer.rtpParameters,
                    codec: 
                        producer.rtpParameters.codecs[0].mimeType.split("/")[1],
                })
            )

            producer.on('trackended', () => {
                this.closeProducer(type)
            })

            producer.on('transportclose', () => {
                console.log('--- producer transport close ---')
                if (!audio) {
                    store.dispatch(stateActions.removeProducer(producer.id));
                }
                this.producers.delete(producer.id)
            })

            producer.on('close', () => {
                console.log('--- closing producer ----')
                store.dispatch(stateActions.removeProducer(producer.id));

                this.producers.delete(producer.id)
            })

            this.producerLabel.set(type, producer.id)
            store.dispatch(stateActions.setWebcamInProgress(false));
        } catch (err) {
            if(!audio){
                store.dispatch(
                    stateActions.setWebcamInProgress(false)
                );
            }
        }
    }

    async consume(producer_id, peerId) {

        this.getConsumeStream(producer_id, peerId).then(function ({
            consumer,
            peerId
        }) {
            this.consumers.set(consumer.id, consumer)
            console.log("PeerId", peerId);

            store.dispatch(
                stateActions.addConsumer(
                    {
                        id: consumer.id,
                        type: consumer.type,
                        locallyPaused: false,
                        remotelyPaused: consumer.producerPaused,
                        codec: 
                            consumer.rtpParameters.codecs[0].mimeType.split("/")[1],
                        track: consumer.track
                    },
                    peerId
                )
            )

            consumer.on('trackended', function () {
                this.removeConsumer(consumer.id)
            }.bind(this))
            consumer.on('transportclose', function () {
                this.removeConsumer(consumer.id)
            }.bind(this))

        }.bind(this))
    }

    async getConsumeStream(producerId, peerId) {
        const {
            rtpCapabilities
        } = this.device
        const data = await this.socket.request('consume', {
            rtpCapabilities,
            consumerTransportId: this.consumerTransport.id, // might be 
            producerId
        });
        const {
            id,
            kind,
            rtpParameters,
            appData
        } = data;

        let codecOptions = {};
        const consumer = await this.consumerTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
            paused: true,
            appData: {...appData, peerId}
        })

        return {
            consumer,
            peerId
        }
    }

    closeProducer(type) {
        if (!this.producerLabel.has(type)) {
            // attach a toast for this system error
            return;
        }

        let producer_id = this.producerLabel.get(type)
        console.log("--- Producer Being Closed ---", producer_id)
        
        this.socket.emit('producerClosed', {
            producer_id
        })

        this.producers.get(producer_id).close();
        store.dispatch(stateActions.removeProducer(producer_id));

        this.producers.delete(producer_id)
        this.producerLabel.delete(type)

        switch (type) {
            case mediaType.audio:
                this.event(_EVENTS.stopAudio)
                break
            case mediaType.video:
                this.event(_EVENTS.stopVideo)
                break
            case mediaType.screen:
                this.event(_EVENTS.stopScreen)
                break;
            default:
                return
        }

    }

    async pauseProducer(type) {
        if (!this.producerLabel.has(type)) {
            // toast for this error.
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: "Unknown Input Device Type",
                    id: this.toastId,
                    duration: 2000,
                    position: "top-right"
                })
            }
            return
        }
        let producer_id = this.producerLabel.get(type)

        if(type === 'videoType' || type === 'screenType'){
            this.closeProducer('videoType');

        } else if(type === 'audioType'){
            console.log("--- Reached Here ---")

            await this.socket.request('producerPaused', {producer_id: producer_id})
                .then(() => {

                    this.producers.get(producer_id).pause();
                    store.dispatch(stateActions.setProducerPaused(producer_id));
                })
                .catch((err) => {
                    if(!this.toast.isActive(this.toastId)){
                        this.toast({
                            description: "Mic Mute Failed!",
                            id: this.toastId,
                            duration: 2000,
                            position: "top-right"
                        })
                    }
                })
            
            
        } else {
            // toast handling
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: "Unknown Input Type!",
                    id: this.toastId,
                    duration: 2000,
                    position: "top-right"
                })
            }
        }
    }

    async resumeProducer(type) {
        if (!this.producerLabel.has(type)) {
            //console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        this.producers.get(producer_id).resume();
        await this.socket.request('producerResumed', {producer_id: producer_id})
        .then(() => {
        })
        .catch((err) => {
            if(!this.toast.isActive(this.toastId)){
                this.toast({
                    description: "Mic Unmute Failed!",
                    id: this.toastId,
                    duration: 2000,
                    position: "top-right"
                })
            }
        })

        store.dispatch(stateActions.setProducerResumed(producer_id));
    }

    removeConsumer(consumer_id) {
        const consumer = this.consumers.get(consumer_id);

        if (!consumer){
            return;
        }
        this.consumers.delete(consumer_id);

        consumer.close();
        const { peerId } = consumer.appData;
        console.log("Close Consumer PeerId", peerId);

        store.dispatch(stateActions.removeConsumer(consumer_id, peerId))
    }

    pauseConsumer(consumer_id){
        const consumer = this.consumers.get(consumer_id);

        if(!consumer){
            return;
        }
        consumer.pause();
        const { peerId } = consumer.appData;

        store.dispatch(
            stateActions.setConsumerPaused(consumer.id, "remote")
        )
    }

    resumeConsumer(consumer_id){
        const consumer = this.consumers.get(consumer_id);

        if(!consumer){
            return;
        }
        consumer.resume();

        store.dispatch(
            stateActions.setConsumerResumed(consumer.id, "remote")
        )
    }

    sendPublicMessage({sender, message, time, }) {
        const messageId = randomString({length: 8}).toLowerCase();

        store.dispatch(
            stateActions.addMessage({messageId, sender, message, time, status: "sending", type: "public"})
        )

        this.socket.request('publicMessage', {classId: this.classId, messageId, sender, message, time, type: "public"})
            .then((res) => {
                store.dispatch(
                    stateActions.updateMessageState({messageId: res, status: "sent"})
                )
            })
            .catch((err) => {
                if(!this.toast.isActive(this.toastId)){
                    this.toast({
                        id: this.toastId,
                        description: "Message Sending Error",
                        duration: 2000,
                        position: "top-right"
                    })
                }
            })
        
    }

    sendPrivateMessage(to){
        console.log("to send to", to);

        var message = prompt("Enter the message to send");
        const messageId = randomString({length: 8}).toLowerCase();
        var time = new Date().toTimeString().split(" ")[0];
        var type = "private";
        var sender = "Teacher";

        this.socket.request('privateMessage', {classId: this.classId, messageId, sender, message, time, type, to})
            .then((res) => {})
            .catch((err) => {
                if(!this.toast.isActive(this.toastId)){
                    this.toast({
                        id: this.toastId,
                        description: "Message Sending Error",
                        duration: 2000,
                        position: "top-right"
                    })
                }
            })
    }

    sendResponse(type){
        let response = "";

        switch(type){
            case 'handraise': {
                response = "handraise"
                break;
            }
            case 'understood': {
                response =  "understood"
                break;
            }
            case 'notUnderstood': {
                response = "notUnderstood"
                break;
            }
            default:
                return
        }

        this.socket.emit("studentResponse", {classId: this.classId, name: this.name, response});
    }

    muteAll(){

        this.socket.request('muteAll', {classId: this.classId})
            .then((res) => {
                if(!this.toast.isActive(this.toastId)){
                    this.toast({
                        id: this.toastId,
                        description: "All students muted!",
                        position: "top-right",
                        duration: 1000
                    })
                }
            })
            .catch((err) => {
                if(!this.toast.isActive(this.toastId)) {
                    this.toast({
                        id: this.toastId,
                        description: "Error in mute all. Try again later!",
                        position: "top-right",
                        duration: 1000
                    })
                }
            })
    }

    mute(socketId){
        this.socket.emit('muteOne', {classId: this.classId, socketId});
    }

    removeHim(socketId){
        this.socket.emit('removeHim', {classId: this.classId, socketId});
    }

    exit(offline = false) {

        let clean = function () {
            this._isOpen = false
            this.consumerTransport.close()
            this.producerTransport.close()
            this.socket.off('disconnect')
            this.socket.off('newProducers')
            this.socket.off('consumerClosed')
        }.bind(this)

        if (!offline) {
            this.socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
                clean()
            }.bind(this))
        } else {
            clean()
        }

        this.event(_EVENTS.exitRoom)

    }

    ///////  HELPERS //////////

    async roomInfo() {
        let info = await this.socket.request('getMyRoomInfo')
        return info
    }

    static get mediaType() {
        return mediaType
    }

    event(evt) {
        if (this.eventListeners.has(evt)) {
            this.eventListeners.get(evt).forEach(callback => callback())
        }
    }

    on(evt, callback) {
        this.eventListeners.get(evt).push(callback)
    }




    //////// GETTERS ////////

    isOpen() {
        return this._isOpen
    }

    static get EVENTS() {
        return _EVENTS
    }
}

export default ClassClient;