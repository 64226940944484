import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Timer from '@material-ui/icons/Timer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

export const mainListItems = (
  <div>
    <Link to="/teacher/home">
      <ListItem button>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>
    <Link to="/teacher/yourClasses">
    <ListItem button>
      <ListItemIcon>
        <Timer />
      </ListItemIcon>
      <ListItemText primary="Scheduled Class" />
    </ListItem>
    </Link>
    <Link to="/teacher/createAssignment">
    <ListItem button>
      <ListItemIcon>
      <AssignmentInd  />
        </ListItemIcon>
      <ListItemText primary="Assign Assignments" />
    </ListItem>
    </Link>
    <Link to="/teacher/submittedAssignments">
    <ListItem button>
      <ListItemIcon>
      <AssignmentIcon />
        </ListItemIcon>
      <ListItemText primary="Submitted Records" />
    </ListItem>
    </Link>
    <Link to="/teacher/createTest">
    <ListItem button>
      <ListItemIcon>
        <Timer />
      </ListItemIcon>
      <ListItemText primary="Create Test" />
    </ListItem>
    </Link>
    <Link to="/teacher/testView">
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />        
      </ListItemIcon>
      <ListItemText primary="Test View" />
    </ListItem>
    </Link>
    <Link to="/teacher/uploadMaterial">
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />        
      </ListItemIcon>
      <ListItemText primary="Study Material" />
    </ListItem>
    </Link>
    <Link to="/teacher/uploadVideo">
    <ListItem button>
      <ListItemIcon>
        <AddToQueueIcon />        
      </ListItemIcon>
      <ListItemText primary="Video Upload Panel" />
    </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
  </div>
);
