import styled from 'styled-components';

export const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
`

export const Holder = styled.div`
    display: grid;
    grid-template-rows: 2fr 8fr;
    height: 100%;
    width: 100%;
    grid-gap: 20px;
`

export const TitleHolder = styled.div`
    display: flex;
    align-items: center;
    font-size: 45px;
    width: 100%;
    justify-content: center;
    font-family: sans-serif;
    flex-direction: column;
`

export const Title = styled.div`
    border-bottom: 5px solid black;
    margin-bottom: 20px;
`

export const Para = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-align: center;
`

export const PosterHolder = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    padding: 20px;

    @media screen and (max-width: 768px){
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        grid-gap: 35px;
    }
`

export const Poster = styled.img`
    height: 100%;
    width: 100%;

    @media screen and (max-width: 768px){
        height: 550px;
    }
`