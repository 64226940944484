import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '@material-ui/core/Container';
import { Box, Stack, useToast } from '@chakra-ui/react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';

import MaterialTable from "material-table";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Schedule() {

  const toast = useToast();
  const toast_id = "toast_id";

  const [update, setUpdate] = useState(false);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState("");

  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [issueStartDate, setIssueStartDate] = useState("");
  const [issueEndDate, setIssueEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [videoList, setVideoList] = useState([]);

  const handleChangeStudent = (event) => {
    setStudents(event.target.value);
  };

  const createAssignment = (e) => {
    e.preventDefault();

    if((new Date(issueEndDate).getTime() - new Date(issueStartDate).getTime()) < 0){
      if(!toast.isActive(toast_id)){
        toast({
          id: toast_id,
          title: "Invalid Range Selection",
          status: "error",
          position: "top-right",
          duration: 2000,
          isClosable: true
        })
      }
    } else {
      axios.post(`${process.env.REACT_APP_SERVER}/video/addVideoLink`,{
        name: name, link: link, startTime: startTime, students, endTime: endTime, issueStartDate, issueEndDate, 
        uploadedBy: JSON.parse(localStorage.getItem('user'))._id 
      })
      .then((res) => {
        setName("");
        setLink("");
        setUpdate(!update);

        if(!toast.isActive(toast_id)){
            toast({
              id: toast_id,
              title: "Video Link Uploaded",
              position: "top-right",
              duration: 3000,
            })
        }   
      })
      .catch(err => {
          if(!toast.isActive(toast_id)){
            toast({
              id: toast_id,
              title: "Video Link Upload Failed",
              position: "top-right",
              duration: 3000,
            })
          }  
      })
    }
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getClasses`, {
      teacherId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      setClasses(res.data.classes)
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Classes",
        duration: 2000,
        isClosable: true,
        position: "top-right"
      })
    })

    axios.post(`${process.env.REACT_APP_SERVER}/video/fetchTeacherVideo`, {
      teacherId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      setVideoList(res.data.data);
    })
    .catch((err) => {
      if(!toast.isActive(toast_id)){
        toast({
          id: toast_id,
          title: "Error in fetching uploaded videos list",
          duration: 1500,
          isClosable: true,
          position: "top-right"
        })
      }
    })
  },[update])

  return (
    <Container>
      <Box p={5}>
      <Grid elevation={6} spacing={4} >
        <center><Title >Upload Video Panel</Title></center>
        <form onSubmit={createAssignment}>
          <Stack spacing={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              label="Video Name"
              name="video_name"
              autoComplete="AssignmentName"
              autoFocus
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="standard"
            />
            <FormControl fullWidth className={classes.formControl} variant="standard">
              <InputLabel id="demo-mutiple-checkbox-label">Students</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                value={students}
                onChange={handleChangeStudent}
                input={<Input />}
                required={true}
                MenuProps={MenuProps}
              >
                {classes.map((name) => (
                  <MenuItem key={name._id} value={name._id}>
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required={true}
              label="Range Start Date"
              name="video_date"
              autoComplete="AssignmentName"
              autoFocus
              fullWidth
              type="date"
              value={issueStartDate}
              onChange={(e) => setIssueStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
            />
            <TextField 
              variant="standard"
              margin="dense"
              label="Range End Date"
              required
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              value={issueEndDate}
              onChange={(e) => setIssueEndDate(e.target.value)}
              type="date"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              label="Video Available Start Time"
              name="video_start_time"
              autoComplete="AssignmentName"
              autoFocus
              fullWidth
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              label="Video Available End Time"
              name="video_end_time"
              autoComplete="AssignmentName"
              autoFocus
              type="time"
              fullWidth
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              label="Video Link"
              name="video_link"
              autoComplete="AssignmentName"
              autoFocus
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
              variant="standard"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Upload Video Link
            </Button>
            <Grid item></Grid>
          </Stack>
        </form>
      </Grid>
      </Box>
      <MaterialTable 
        title="Uploaded Video List"
        columns={[
          {title: "Name", field: "name"},
          {title: "Range Start Date", field: "issueStartDate"},
          {title: "Range End Date", field: "issueEndDate"},
          {title: "Start Time", field: "startTime"},
          {title: "End Time", field: "endTime"}
        ]}
        data={videoList}
        editable={{
          onRowDelete: (oldData) => {
            return new Promise((resolve, reject) => {
              axios.post(`${process.env.REACT_APP_SERVER}/video/deleteVideo`, {
                uploadedBy: JSON.parse(localStorage.getItem("user"))._id,
                videoId: oldData._id
              })
              .then((res) => {
                resolve();
                setUpdate(!update);
              })
              .catch((err) => {
                reject();
                if(!toast.isActive(toast_id)){
                  toast({
                    id: toast_id,
                    title: "Error in deleting video",
                    duration: 2000,
                    isClosable: true,
                    status: "error"
                  })
                }
              })
            })
          }
        }}
      />
    </Container>
  );
}