import React, {useState} from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { isAuth } from '../../helper/auth';
import { Redirect } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" href="#">
        PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/ah-HeguOe9k)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SignInSide({history}) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const toast = useToast();
  const [type, setType] = useState("");

  const forgot = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/auth/forgotPassword`, {email: email, role: type})
    .then(res => {

      setTimeout(() => {
        history.push('/home')
      }, 1500);
      toast({
        title: "Reset Password Link Sent",
        description: "Email with password reset details sent to your email",
        isClosable: true,
        position: "top",
        status: "success"
      })
    })
    .catch(err => {
      toast({
        title: "Error Occured",
        duration: 2000
      })
    })
  }

  const handleChangeType = (e) => {
    setType(e.target.value);
  }

  return (
    <>
    {isAuth() ? <Redirect to='/' /> : null}
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AllInclusiveIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password?
          </Typography>
          <form className={classes.form} onSubmit={forgot}>
          <label>Email</label>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              value={email}
              onInput={e => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Type</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={type}
                  onChange={handleChangeType}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  
                    <MenuItem key={"student"} value={"student"}>
                      <ListItemText primary={"Student"} />
                    </MenuItem>
                    <MenuItem key={"student"} value={"teacher"}>
                      <ListItemText primary={"Teacher"} />
                    </MenuItem>
                    
                </Select>
              </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              SUBMIT
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    </>
  );
}