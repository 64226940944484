import React from 'react';
import {NameSetter} from '../../helper/NameSetter';
import {
    Nav, 
    NavContainer,
    NavLogo,
    NavBtn,
    NavName
} from './HeaderComponents';

function Header(){

    return (
        <> 
            <Nav>
                <NavContainer>
                    <NavLogo> PCP </NavLogo>
                    <NavBtn>
                        <NavName>{NameSetter()}</NavName>
                    </NavBtn>
                </NavContainer>
            </Nav>
        </>
    )
}


export default Header;