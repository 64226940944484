import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Title from './Title';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

export default function Schedule() {

  const toast = useToast();
  const toast_id = "toast_id";
  const [update, setUpdate] = useState(false);

  const [material, setMaterial] = useState([]);

  const download = (url, name) => {

    window.open(`${process.env.REACT_APP_SERVER}/class/assignments/${url}/${name}`);
    // axios({
    //   url: `${process.env.REACT_APP_SERVER}/class/assignments/${url}/${name}`,
    //   method: 'GET',
    //   responseType: 'blob'
    // })
    // .then((response) => {
    //   console.log(response);
    //   console.log("Server Worked Well!");
    // })
    // .catch((err) => {
    //   toast({
    //     title: "File Download Failed",
    //     duration: 3000,
    //     status: "error",
    //     isClosable: true,
    //     position: "top"
    //   })
    // })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/fetchMaterial`,{
      studentId: JSON.parse(localStorage.getItem("user"))._id,
    })
    .then((res) => {
      setMaterial(res.data.materials);
    })
    .catch((err) => {
        if(!toast.isActive(toast_id)){
            toast({
                id: toast_id,
                title: "Error In Fetching Materials",
                duration: 3000,
                isClosable: true,
                status: "error",
                position: "top"
            })
        }
    })
  }, [update])

  return (
    <Container>
      <Title>Study Material Uploaded</Title>
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell>Material Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Uploaded By</TableCell>
            <TableCell>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { typeof material === 'undefined' ? null : material.length !== 0  ?
            material.map((entity) => {
            return (<TableRow key={entity._id}>
              <TableCell>{entity.name}</TableCell>
              <TableCell>{entity.desc}</TableCell>
              <TableCell>{(entity.uploadedBy || {}).name}</TableCell>
              <TableCell>
                <Link style={{cursor: "pointer"}} onClick={() => download(`${entity.upload}`, `${entity.name}`)}><GetAppRoundedIcon /></Link>
              </TableCell>
            </TableRow>)
          }): null}
        </TableBody>
      </Table>
    </Container>
  );
}