import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Person from './Person';
import Container from "@material-ui/core/Container";

function PersonContainer({me, peers, activeSpeakerId}) {

    return (
        <Container>
            {
            peers.map((peer) => {
                return(
                    <div data-component='Peers' style={{
                        display: me.type === "teacher" ? "inherit" : me.type === "student" ? peer.type === "teacher"? "inherit" : "none" : "none"
                    }}>
                        <div className={classnames({'peer-container': peer.type === "student"},{'teacher': peer.type === "teacher"}, {
                            'active-speaker': peer.id === activeSpeakerId
                        })} >
                            <Person key={peer.id} id={peer.id} />
                        </div>   
                    </div> 
                )
            })}
        </Container>
    )
}

const mapStateToProps = (state) => {
    const peersArray = Object.values(state.peers)

    return {
        me: state.me,
        peers: peersArray,
        activeSpeakerId: state.classes.activeSpeakerId
    }
}

const Participants = connect(
    mapStateToProps,
    null,
    null,
    {
        areStatesEqual: (next, prev) => 
        {
            return(
                prev.peers === next.peers &&
                prev.classes.activeSpeakerId === next.classes.activeSpeakerId
            )
        }
    }
)(PersonContainer);

export default Participants;