import styled from 'styled-components';

export const Nav = styled.nav`
    background: #fff;
    height: 55px;
    margin-top: 0px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size: 1rem;
    position: sticky;
    top:0;
    z-index:10;

    @media screen and (max-width: 960px) {
        transition: 00.8s all ease;
    }
`

export const NavContainer = styled.div`
    display:flex;
    justify-content: space-between;
    height: 80px;
    z-index:1;
    width:100%;
    padding:0 24px;
    max-width:1100px;
`

export const NavLogo = styled.div`
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: 400;
    text-decoration: none!important;

    &:hover {
        color: #6B46C1;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

`;

export const NavName = styled.h3`
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    font-size: 20px;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        font-family: Georgia;
    }
`;