import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import './index.css';
import './Me.css';
// import './NetworkThrottle.css';
// import './Notifications.css';
// import './ChatInput.css';
import './Peer.css';
import './Peers.css';
import './Room.css';
// import './Stats.css';
import './PeerView.css';
// import './reset.css';
import './Tabs.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);