import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Title from './Title';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useDisclosure, ModalBody, ModalContent, ModalFooter, 
  ModalHeader, ModalOverlay, Modal, useToast, Box, Stack, Center, Input, FormControl, FormLabel, Progress } from '@chakra-ui/react';
import axios from 'axios';

export default function Schedule() {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const toast_id = "toast";
  const [update, setUpdate] = useState(false);

  const [checked, setChecked] = useState([]);
  const [pending, setPending] = useState([]);
  const [formData, setFormData] = useState('')
  const [progressPercent, setProgressPercent] = useState(0)
  const [assignmentId, setAssignmentId] = useState();
  const [error, setError] = useState({
      found: false,
      message:''
  })

  const download = (url, name) => {

    window.open(`${process.env.REACT_APP_SERVER}/class/assignments/${url}/${name}`);
    // axios({
    //   url: `${process.env.REACT_APP_SERVER}/class/assignments/${url}/${name}`,
    //   method: 'GET',
    //   responseType: 'blob'
    // })
    // .then((response) => {
    //   console.log(response);
    //   console.log("Server Worked Well!");
    // })
    // .catch((err) => {
    //   toast({
    //     title: "File Download Failed",
    //     duration: 3000,
    //     status: "error",
    //     isClosable: true,
    //     position: "top"
    //   })
    // })
  }

  const modalOpen = (id) => {
    onOpen()
    setAssignmentId(id);
  }

  const upload = ({target: {files}}) => {
    let data = new FormData()
    data.append('assignments', files[0])
    data.append('email', JSON.parse(localStorage.getItem("user")).email)
    data.append('name', JSON.parse(localStorage.getItem('user')).name)
    data.append('dateup', new Date().getTime())
    data.append('id', assignmentId);
    setFormData(data)
  }

  const onSubmitPdf = (e) => {
    e.preventDefault();
    setProgressPercent(0)
    const options = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let percent = Math.floor((loaded * 100)/total)
            setProgressPercent(percent)
        }
    }
    axios.post(`${process.env.REACT_APP_SERVER}/class/uploadAssignmentStudent`, formData, options)
    .then((res) => {
        setTimeout(() => {
            setProgressPercent(0)
            onClose()
        }, 1000)
        toast({
          status: "success",
          title: "Assignment Uploaded",
          duration: 3000,
          isClosable: true
        })
        setUpdate(!update);
    })
    .catch(err => {
      setError({
          found: true,
          message:err.response.data.err
      })
      setTimeout(() => {
        setError({
            found: false,
            message:''
        })
        setProgressPercent(0);
      }, 3000)
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getPendingAssignments`,{
      studentId: JSON.parse(localStorage.getItem("user"))._id,
      email: JSON.parse(localStorage.getItem("user")).email
    })
    .then((res) => {
      setPending(res.data.pending);
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Assignments",
        duration: 3000,
        isClosable: true,
        status: "error",
        position: "top"
      })
    })

    axios.post(`${process.env.REACT_APP_SERVER}/class/fetchChecked`, {
      studentId: JSON.parse(localStorage.getItem("user"))._id,
      email: JSON.parse(localStorage.getItem("user")).email
    })
    .then((res) => {
      setChecked(res.data.assignment);
    })
    .catch((err) => {
      if(!toast.isActive(toast_id)){
        toast({
          title: "Error In Fetching Assignments",
          duration: 3000,
          isClosable: true,
          status: "error",
          position: "top"
        })
      }
    })
  }, [update])

  return (
    <Container>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} motionPreset="slideInRight" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader color="purple">
                Upload Assignment
            </ModalHeader>
            <ModalBody>
                Select the assignment pdf file and make sure that the file size is not more than 5MB.
                <Box>
                    {error.found && <div className="alert alert-danger mt-2" role="alert" >{error.message}</div>}
                    <form onSubmit={onSubmitPdf} autoComplete="off" >
                        <Stack spacing={5} pt={4}>
                        <Progress value={progressPercent} colorScheme="purple">{progressPercent}</Progress>
                            <FormControl>
                                <FormLabel>Assignment</FormLabel>
                                <Input type='file'name='assignment' placeholder="Choose the assignment pdf" variant="flushed" focusBorderColor="purple.400" onChange={upload} isRequired/>
                            </FormControl>
                            <Center>
                                <Button type="submit" colorScheme="purple" color="white" w={["100%", "75%"]}>Upload</Button>
                            </Center>
                        </Stack>
                    </form>      
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button variant="ghost" onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
      </Modal>

      <Title>Pending Assignments</Title>
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell>Assignment Name</TableCell>
            <TableCell>Assigned By</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Due Time</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Upload</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { typeof pending === 'undefined' ? null : pending.length !== 0  ?
            pending.map((entity) => {
              if((new Date(`${entity.dueDate} ${entity.startTime} `).getTime() - Date.now()) < 0){
                return (<TableRow key={entity._id}>
                  <TableCell>{entity.name}</TableCell>
                  <TableCell>{(entity.assignedBy || {}).name}</TableCell>
                  <TableCell>{new Date(entity.dueDate).toDateString()}</TableCell>
                  <TableCell>{entity.dueTime}</TableCell>
                  <TableCell align="center">
                    <Link style={{cursor: "pointer"}} onClick={() => download(`${entity.upload}`, `${entity.name}`)}><GetAppRoundedIcon /></Link>
                  </TableCell>
                  <TableCell >
                    <Button onClick={() => {modalOpen(entity._id)}} variant="contained" color="primary">Upload</Button>
                  </TableCell>
                </TableRow>)
              }
            }): null
          }
        </TableBody>
      </Table>
      <Title style={{marginTop: '5px'}}>Checked Assignment</Title>
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell>Assignment Name</TableCell>
            <TableCell>Assigned By</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Checked</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          typeof checked === 'undefined' ? null : checked.length !== 0 ?
          checked.map((entity) => {
            return (<TableRow key={entity._id}>
              <TableCell>{entity.name}</TableCell>
              <TableCell>{(entity.assignedBy || {}).name}</TableCell>
              <TableCell>{new Date(entity.dueDate).toDateString()}</TableCell>
              <TableCell>Checked</TableCell>
              {
                entity.submissions.map((submit) => {
                  if(submit.email === JSON.parse(localStorage.getItem('user')).email){
                    return(
                      <TableCell key={submit.email}>                
                        <Link style={{cursor: "pointer"}} onClick={() => download(`${submit.checked}`, `${entity.name}`)}><GetAppRoundedIcon /></Link>
                      </TableCell>
                    )
                  }
                  else {
                    return <></>
                  }
                })
              }
            </TableRow>)
          })
          :
          null
        }  
        </TableBody>
      </Table>
    </Container>
  );
}