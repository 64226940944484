import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BiMicrophoneOff, BiMicrophone } from 'react-icons/bi';
import { AiOutlineMessage } from 'react-icons/ai';
import { IoPersonRemoveOutline } from 'react-icons/io5';

const useStyles = makeStyles((theme) => ({
    holder: {
        width: "100%",
        overflow: 'auto',
        height: '60vh'
    },
    holderTouch: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    name: {
        width: '60%',
        color: 'white'
    },
    actions: {
        width: '30%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: 'white'
    }
}))

function AttendeeHolder({peer, consumers, classClient}) {

    const classes = useStyles();
    const [realClass, setRealClass] = useState("");

    classClient.then((res) => {
        setRealClass(res);
    })

    return (
        <div className={classes.holder}>
            {Object.keys(peer).length !== 0 ? 
                Object.values(peer).map((peer) => {
                return (
                <div className={classes.holderTouch}>
                    <div className={classes.name}>
                        <Typography>{peer.name}</Typography>
                    </div>
                    <div className={classes.actions}>
                        <div className={classes.indivualMessage}>
                            <AiOutlineMessage onClick={() => realClass.sendPrivateMessage(peer.id)} />
                        </div>
                        <div className={classes.muteOne} id={peer.id}>
                        {
                            peer.muteState ? 
                                <BiMicrophoneOff />
                            :
                                <BiMicrophone onClick={() => realClass.mute(peer.id)} />
                        }
                        {/* {   
                            Object.keys(consumers).map((keys) => {
                                let icon;
                                if(peer.consumers){
                                    if(peer.consumers.length !== 0){
                                        peer.consumers.map((consumer) => {
                                            if(consumer === keys && consumers[keys].track.kind === "audio"){
                                                if(!consumers[keys].remotelyPaused){
                                                    icon = <BiMicrophone onClick={() => realClass.mute(peer.id)} />
                                                } else {
                                                    console.log("this");
                                                    icon = <BiMicrophoneOff />
                                                }
                                            } else {
                                                console.log("that")
                                                icon = <BiMicrophoneOff />
                                            }
                                        })
                                    } else {
                                        console.log("their")
                                        icon = <BiMicrophoneOff />
                                    }
                                } else {
                                    console.log("theirs")
                                    icon = <BiMicrophoneOff />
                                }

                                return icon;
                            })
                        } */}
                        </div>
                        <div className = {classes.removeOne}>
                            <IoPersonRemoveOutline onClick={() => realClass.removeHim(peer.id)}/>
                        </div>
                    </div>
                </div>
                )})
            :
            null}
        </div>  
    )
}

export default AttendeeHolder;