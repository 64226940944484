import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import { isAuth } from '../helper/auth';
import axios from 'axios';
import {removeCookie, removeLocalStorage } from '../helper/auth';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    justifyContent: "space-between",
    background: "white"
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    padding: "0.25em",
    transition: "1s all ease-in-out",
    width: '70%'
  },
  toolbarLink: {
    padding: theme.spacing(0),
    flexShrink: 0,
    textDecoration: "none"
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { sections} = props;

  const [active, setActive] = useState(true);
  const [activeBtn, setActiveBtn] = useState(true);

  const signout_here = () => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/removeActiveState`, {
      id: JSON.parse(localStorage.getItem('user'))._id, 
      role: JSON.parse(localStorage.getItem('user')).role
    })
    .then((res) => {
        removeCookie('token')
        removeLocalStorage('user')
        history.push('/home')
    })
    .catch((err) => {
        alert("Signout Failed");
    })
  } 

  let history = useHistory();
  const redirect = () => {
    if(isAuth()){
      signout_here();
    } else {
      history.push('/signIn')
    }
  }

  const handleClick = () => {
    setActiveBtn(!activeBtn);
  }

  useEffect(() => {
    if(window.outerWidth < 768){
      setActive(false);
      setActiveBtn(false);
    } else {
      setActive(true);
      setActiveBtn(true);
    }

  }, [window.outerWidth])

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <IconButton>
          <img src="img/about.jpg" style={{width: "65px"}} alt="Genus"></img>
        </IconButton>
        <IconButton style={{display: active ? "none": "inline-block"}} onClick={handleClick}>
          <i className={ !activeBtn ? "fa fa-bars" : "fa fa-times"} style={{color: "black"}} ></i>
        </IconButton>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary} style={{display: !active ? "none" : "inherit"}}>
          <Typography variant="h4" style={{fontFamily: 'Georgia'}}>
            Perfect Commerce Point
          </Typography>
          <div style={{width: '50%', display: 'flex', justifyContent: 'space-evenly'}}>
            {sections.map((section) => (
              <Link
                color="primary"
                noWrap
                key={section.title}
                variant="body2"
                href={section.url}
                className={classes.toolbarLink}
                style={{padding: "4px", textDecoration: "none"}}
              >
                <Typography align="center" style={{textDecoration: "none", color: "black", fontSize: '18px'}} >{section.title}</Typography>
              </Link>
            ))}
            {isAuth() ? <Link
                color="primary"
                noWrap
                key={`${JSON.parse(localStorage.getItem('user')).role}`}
                variant="body2"
                href={`/${JSON.parse(localStorage.getItem('user')).role}`}
                className={classes.toolbarLink}
                style={{padding: "4px", textDecoration: "none"}}
              >
                <Typography align="center" style={{textDecoration: "none", color: "black", fontSize: '18px'}} >Dashboard</Typography>
              </Link> : null
            }
            <Button onClick={redirect} color="secondary" variant= "contained" size="small">
              {isAuth() ? 'Log Out' :  'Sign In'}
            </Button>
          </div>
        </Toolbar>
      </Toolbar>
      
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary} style={{display: !active ? !activeBtn ?  "none" : "inherit" : "none",     background: "#8e2de2", /* fallback for old browsers */
    background: "-webkit-linear-gradient(to right, #8e2de2, #4a00e0)", /* Chrome 10-25, Safari 5.1-6 */
    background: "linear-gradient(to right, #8e2de2, #4a00e0)"}}>
          {sections.map((section) => (
            <Link
              color="primary"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              onClick={handleClick}
              className={classes.toolbarLink}
              style={{padding: "15px", textDecoration: "none"}}
            >
              <Typography variant="h6" align="left" style={{textDecoration: "none", color: "black"}} >{section.title}</Typography>
            </Link>
          ))}
          {isAuth() ? <Link
              color="primary"
              noWrap
              key={`${JSON.parse(localStorage.getItem('user')).role}`}
              variant="body2"
              href={`/${JSON.parse(localStorage.getItem('user')).role}`}
              className={classes.toolbarLink}
              style={{padding: "15px", textDecoration: "none"}}
            >
              <Typography variant="h6" align="left" style={{textDecoration: "none", color: "black"}} >Dashboard</Typography>
            </Link> : null}
          <Button onClick={redirect} color="secondary" variant= "contained" size="medium" style={{marginTop: "10px", marginLeft: "-15px"}}>
            {isAuth() ? 'Log Out' :  'Sign In'}
        </Button>
        </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};