import React from 'react';
import { connect } from 'react-redux';
import { withRoomContext } from '../../helper/roomContext';
import PersonView from './PersonView';
import { If } from 'react-if';

const PersonHolder = (props) => {
    const {
        me,
        peer,
        audioConsumer,
        videoConsumer,
        audioMuted
    } = props;

    const audioEnabled =
        Boolean(audioConsumer) &&
        !audioConsumer.locallyPaused &&
        !audioConsumer.remotelyPaused;

    const videoVisible =
        Boolean(videoConsumer) &&
        !videoConsumer.locallyPaused &&
        !videoConsumer.remotelyPaused;
    
    const displayPeer = me.type === "teacher" ? true : me.type === "student" ? peer.type === "teacher"? true : false : false;

    return (
        <div data-component="Peer" style={{
            display: displayPeer ? "inherit" : "none"
        }}>
            <div className="indicators">
                <If condition={!audioEnabled}>
                    <div className="icon mic-off" />
                </If>

                <If condition={!videoConsumer}>
                    <div className="icon webcam-off" />
                </If>
            </div>

            <PersonView 
                peer={peer}
                audioTrack={audioConsumer ? audioConsumer.track : null}
                videoTrack={videoConsumer ? videoConsumer.track : null}
                audioMuted={audioMuted}
                videoVisible={videoVisible}
                videoMultiLayer={videoConsumer && videoConsumer.type !== "simple"}
                audioRtpParameters={audioConsumer ? audioConsumer.rtpParameters : null}
                videoRtpParameters={videoConsumer ? videoConsumer.rtpParameters : null}
                audioCodec={audioConsumer ? audioConsumer.codec : null}
                videoCodec={videoConsumer ? videoConsumer.codec : null}
                audioScore={audioConsumer ? audioConsumer.score : null}
                videoScore={videoConsumer ? videoConsumer.score : null}
                consumerCurrentSpatialLayer={
                    videoConsumer ? videoConsumer.currentSpatialLayer : null
                }
            />
        </div>
    )
}

const mapStateToProps = (state, { id }) => {
    const me = state.me;
    const peer = state.peers[id];
    const consumersArray = peer.consumers.map(
        (consumerId) => state.consumers[consumerId]
    )
    const audioConsumer = consumersArray.find(
        (consumer) => consumer.track.kind === "audio"
    );
    const videoConsumer = consumersArray.find(
        (consumer) => consumer.track.kind === "video"
    );

    return {
        me,
        peer,
        audioConsumer,
        videoConsumer,
        audioMuted: me.audioMuted
    }
}

const Person = withRoomContext(
    connect(mapStateToProps, null)(PersonHolder)
)

export default Person;