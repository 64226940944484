import React from 'react';
import {
    Section,
    Para,
    Holder,
    TitleHolder,
    Title,
    PosterHolder,
    Poster
} from './SubjectComponent.js';

function Subject({posterData}){

    return (
        <>
            <Section id="subjects">
                <Holder>
                    <TitleHolder>
                        <Title>Subjects</Title>
                        <Para>for class 11th and class 12th students</Para>
                    </TitleHolder>
                    <PosterHolder>
                    {
                        posterData.map((poster) =>{
                            return (<Poster key={poster.source} src={poster.source} />)
                        })
                    }
                    </PosterHolder>
                </Holder>
            </Section>
        </>
    )
}

export default Subject;