const initialState = {};

const response = (state = initialState, action) => {
    switch(action.type) {
        case "ADD_STUDENT_RESPONSE": {
            const {response, name, message} = action.payload;
            const respObject = {response, name, message};
            const respId = new Date().getTime();

            return {...state, [respId]: respObject};
        }

        default: {
            return state;
        }
    }
}

export default response;