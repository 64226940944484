import React, { useContext } from 'react';
import * as mediasoupClient from 'mediasoup-client';
import UrlParse from 'url-parse';
import { Provider } from 'react-redux';
import { MemoryRouter, Route } from 'react-router-dom';

import { SocketContext } from '../../helper/socketService';
import ClassClient from '../../helper/classClient';
import RoomContext from '../../helper/roomContext';
import deviceInfo from '../../helper/deviceInfo';
import store from './store';

import * as stateActions from "./stateManagers/stateActions";

import Class from './ClassInitiate';
import ClassRoom from './ClassRoom';

let classClient;
let socketId;

ClassClient.init({store});

async function socketConnect(socket) {
    return new Promise((resolve) => {
        socket.on('connect', () => {
            resolve(socket.id);
        })
    })
}

async function socketPromise(socket, classId, name, type, mediasoup, device, handler) {
    return new Promise((resolve) => {
        socket.on('connect', () => {
            const classPromise = new ClassClient({
                classId,
                name,
                type,
                mediasoup,
                device,
                handler,
                socket
            })

            resolve(classPromise);
        })
    })
}

function ClassComponent({ match }) {

    const urlParser = new UrlParse(window.location.href, true);

    const classId = match.params.meetId;
    const name = JSON.parse(localStorage.getItem("user")).name;
    const type = JSON.parse(localStorage.getItem("user")).role;
    const mediasoup = mediasoupClient;
    const device = deviceInfo();
    const handler = urlParser.query.handler;
    const socket = useContext(SocketContext);

    store.dispatch(
        stateActions.setClassId(classId)
    );

    socketId = socketConnect(socket);
    store.dispatch(
        stateActions.setMe({ peerId: socketId, name, type, device})
    );  

    // intialized class to handle everything.
    classClient = socketPromise(
        socket,
        classId,
        name,
        type,
        mediasoup,
        device,
        handler,
        socket
    )

    return(
        <Provider store = {store}>
            <RoomContext.Provider value = {classClient} >
                <MemoryRouter>
                    <Route path="/" exact component={(props) => <Class {...props} classId = {classId} /> } />
                    <Route path={`/join/${classId}`} exact component = {() => <ClassRoom />} />
                </MemoryRouter>
            </RoomContext.Provider>
        </Provider>
    )
}

export default ClassComponent;