import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { useToast, Stack } from '@chakra-ui/react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

export default function Schedule() {

  const toast = useToast();
  const [scheduled, setScheduled] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getScheduledStudent`,{
      studentId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      setScheduled(res.data.classes);
    })
    .catch((err) => {
      toast({
        title:"Error in fetching the scheduled classes",
        isClosable: true,
        position:'top',
        status:"error"
      })
    })
  }, [])

  return (
    <Container>
      <Title>Your Scheduled Classes</Title>
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Teacher</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell >Time</TableCell>
            <TableCell >Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { typeof scheduled === 'undefined' ? null : scheduled.length !== 0  ?
            scheduled.map((notice) => {
            return (<TableRow key={notice.id}>
              <TableCell>{notice.name}</TableCell>
              <TableCell>{(notice.teacher || {}).name}</TableCell>
              <TableCell>{notice.subject}</TableCell>
              <TableCell>{notice.startTime}</TableCell>
              <TableCell >
                <Stack spacing={2} direction="row">
                  {notice.link ?
                    <Link color="primary" href={`${notice.link}`} target="blank">
                      <Button variant="contained" color="primary">Open Link</Button>
                    </Link>
                    :
                    null
                  }
                  <Link color="primary" href={`/class/${notice.meetId}`}><Button variant="contained" color="primary">Join Class</Button>
                  </Link>
                </Stack>
              </TableCell>
            </TableRow>)
          }): null}
        </TableBody>
      </Table>
    </Container>
  );
}