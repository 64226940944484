import React from 'react';
import useMediaRecorder from "@wmik/use-media-recorder";
import classnames from 'classnames';

let called = false;

function Player({ srcBlob }) {
  if (!srcBlob) {
    return null;
  }

  const url = window.URL.createObjectURL(srcBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", URL.createObjectURL(srcBlob));

  if (!called) {
    link.click();
    called = true;
  }
  window.URL.revokeObjectURL(url);
  return <></>;
}

function RecorderSect() {

    let {
        error,
        status,
        mediaBlob,
        stopRecording,
        getMediaStream,
        startRecording,
      } = useMediaRecorder({
        recordScreen: true,
        blobOptions: { type: "video/mp4" },
        mediaStreamConstraints: { audio: true, video: true },
      });

    return(
        <>
        <div className={classnames('button', 'record', {
            on: status === "recording"
          }, {
            off: status !== "recording"
          })}
            style ={{ color: "white"}}
            onClick={ status !== "recording" ? 
                () => startRecording()
              :
                status === "recording" ? () => {
                  stopRecording();
                  called = false;
                }
              :
                null
            }
        />
        <Player srcBlob = {mediaBlob} />
        </>
    )
}

export default RecorderSect;