import React from 'react';
import {
    Section,
    Holder,
    TitleHolder,
    Title,
    FeatureHolder,
    FeatureCard,
    FeatureIcon,
    FeatureTextHolder,
    FeatureTitle,
    FeatureText
} from './FeatureComponent.js'

function Feature({featureData}){

    return(
        <>
            <Section id="features">
                <Holder>
                    <TitleHolder>
                        <Title>Features</Title>
                    </TitleHolder>
                    <FeatureHolder>
                        {featureData.map((feature) => {
                            return(
                                <FeatureCard key={feature.title}>
                                    <FeatureIcon><i className={`${feature.icon} fa-3x` } ></i></FeatureIcon>
                                    <FeatureTextHolder>
                                        <FeatureTitle>{feature.title}</FeatureTitle>
                                        <FeatureText>{feature.text}</FeatureText>
                                    </FeatureTextHolder>
                                </FeatureCard>
                            )
                        })}
                    </FeatureHolder>
                </Holder>
            </Section>
        </>
    )
}

export default Feature;