import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Schedule from '@material-ui/icons/Schedule';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8,4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
 
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SignUp() {
  const classes = useStyles();

  const handleChangeClass = (event) => {
    setClassId(event.target.value);
  };

  const toast = useToast();
  const toast_id = "toast";

  const [classId, setClassId] = useState("");
  const [startTime, setStartTime] = useState("");

  const [scheduledClass, setScheduledClass] = useState([]);

  const rescheduleMeet = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/class/rescheduleClass`, {
      adminId: JSON.parse(localStorage.getItem('user')).email,
      classId: classId,
      startTime: startTime
    })
    .then((res) =>{ 
      if(!toast.isActive(toast_id)){
        toast({
          title: "Class Rescheduled And Participants Notified",
          position: "top",
          isClosable: true
        })
      }
    })
    .catch(err => {
      if(!toast.isActive(toast_id)){
        toast({
          title: "Error Occured",
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getScheduledClass`,{
      adminId: JSON.parse(localStorage.getItem("user")).email
    })
    .then((res) => {
      setScheduledClass(res.data.scheduled);
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Scheduled Classes.",
        description: "Please try again in some time.",
        isClosable: true,
        position: "top"
      })
    })
  },[])
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Schedule />
        </Avatar>
        <Typography component="h1" variant="h5">
          Rescheduling a class
        </Typography>
        <form className={classes.form} onSubmit={rescheduleMeet}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Meeting Name</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={classId}
                  onChange={handleChangeClass}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {scheduledClass.map((name) => (
                    <MenuItem key={name._id} value={name.meetId}>
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} >
              <TextField
                variant="filled"
                required
                fullWidth
                label="Starting Time:-"
                disabled
              />
            </Grid>
            
            <Grid item xs={8} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="S_time"
                type="time"
                name="S_time"
                value={startTime}
                onInput = {e => setStartTime(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            DONE
          </Button>
        </form>
      </div>
    </Container>
  );
}