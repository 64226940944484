import React, { useEffect, useState } from 'react';
import {
    Modal,
    useDisclosure, 
    useToast, 
    ModalOverlay, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    ModalContent,
    List,
    ListIcon,
    ListItem,
    Button,
    Tooltip,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Input
} from '@chakra-ui/react';
import axios from 'axios';
import { intializeStudentStream, sendMessageJs } from './studentStream';
import {
    StudentJoinInterface,
    ButtonHolderStudent,
    InstructionContainer,
    InstructionTopic,
    Instructions,
    JoinPara,
    StudentClassInterface,
    ClassContainer,
    TeacherVideoHolder,
    TeacherVideo,
    ShareScreenTeacher,
    StudentActions,
    MessageContainer,
    MessageInput,
    Messages,
    DisconnectHolder,
    MediaHolder,
    ResponseHolder
} from './ClassEntryComponents';
import {
    VideoContainer,
    VideoHolder,
    Utils
} from '../ClassStart/ClassStartComponents';
import Header from '../Header';
import { MdCheckCircle } from 'react-icons/md'; 
import { AiFillLike, AiFillDislike, AiOutlineSend } from 'react-icons/ai';
import { IoHandLeftSharp } from 'react-icons/io5';
import { BsFillMicFill } from 'react-icons/bs';
import { BiMicrophoneOff, BiCameraOff, BiCamera } from 'react-icons/bi';
import { SocketContext } from '../../helper/socketService';
 
function ClassEntry({match}){

    const toast = useToast();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [allowed, setAllowed] = useState(false);
    const [found, setFound] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [message, setMessage] = useState("");

    const handleMessage = (e) => setMessage(e.target.value);

    const sendMessage = () => {

        const name = JSON.parse(localStorage.getItem('user')).name; 
        if(message !== ""){
            sendMessageJs(match.params.meetId, message, name);
            setMessage("");
        }
    }

    useEffect(()=>{

        intializeStudentStream(match.params.meetId);
        if(JSON.parse(localStorage.getItem('user')).role !== "admin"){
            axios.post(`${process.env.REACT_APP_SERVER}/class/checkInvitation/${match.params.meetId}`,{
                userId: JSON.parse(localStorage.getItem('user'))._id
            })
            .then((res) => {
                
                if(res.data.found){
                    setFound(true);
                    if(res.data.invited){
                        setAllowed(true);
                        setDisabled(false);
                    } else {
                        onOpen();
                    }
                } else{
                    onOpen();
                }
            })
            .catch(err => {
                toast({
                    title: "Error Occured.",
                    description: "If the error persists, please inform the admin",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top"
                })
            })
        }
    },[onOpen, match.params.meetId, toast])
    
    return (
        <>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                {!found ? <ModalContent>
                    <ModalHeader fontSize="25px" fontFamily="sans-serif">Class Not Found</ModalHeader>
                    <ModalBody fontSize="20px" fontFamily="Georgia">
                        You have tried to join a class that does not exist. In case of any discrepancies, please contact the admin.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="purple" onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
                :
                !allowed ?
                <ModalContent>
                    <ModalHeader fontSize="25px">Unauthorized Access Attempt</ModalHeader>
                    <ModalBody fontSize="20px">
                        You are not included as a participant in this class. In case of any discrepancies, please contact the admin.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="purple" onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
                : null
                }
            </Modal>
            
            <div id="studentJoin">
            <Header />
            <StudentJoinInterface id="studentJoin">
                <VideoContainer>
                    <VideoHolder id="videoHolder"></VideoHolder>
                    <ButtonHolderStudent>
                        <Button colorScheme="purple" id="stopAudio" mr={3} w="50%">Mic On</Button>
                        <Button colorScheme="purple" id="stopVideo" w="50%">Camera On</Button>
                    </ButtonHolderStudent>
                </VideoContainer>
                <InstructionContainer>
                    <InstructionTopic>
                        Instructions
                    </InstructionTopic>
                    <Instructions>
                        <List spacing={3}>
                            <ListItem fontSize="20px" > 
                                <ListIcon as={MdCheckCircle} color="purple.600" />
                                Make sure that you have a proper internet connection before joining the class.
                            </ListItem>
                            <ListItem fontSize="20px" >
                                <ListIcon as={MdCheckCircle} color="purple.600" />
                                You must keep your video on while attending the class.
                            </ListItem>
                            <ListItem fontSize="20px" >
                                <ListIcon as={MdCheckCircle} color="purple.600" />
                                Make sure that your surrounding is noise-free before turning on your mic.
                            </ListItem>
                            <ListItem fontSize="20px" >
                                <ListIcon as={MdCheckCircle} color="purple.600" />
                                Do not share the class link with any outsider, this is only for you and must be kept private.
                            </ListItem>
                        </List>
                    </Instructions>
                    <JoinPara>Ready to join the class?</JoinPara>
                    <Button colorScheme="purple" id="joinBtn" disabled={disabled}>Join Class</Button>
                </InstructionContainer>
            </StudentJoinInterface>
            </div>
            <StudentClassInterface id="studentInterface">
                <ClassContainer>
                    <TeacherVideoHolder>
                        <video id="ownVideo" playsInline autoPlay ></video>
                        <TeacherVideo id="teacherVideo"></TeacherVideo>
                        <ShareScreenTeacher id="shareScreenStudent"></ShareScreenTeacher>
                    </TeacherVideoHolder>
                    <StudentActions>
                        <Tabs pl={1} pr={1} variant="solid-rounded" isFitted width="100%" isManual align="center" pt={5}>
                            <TabList>
                                <Tab>Chat</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={0}>
                                    <MessageContainer>
                                        <Messages>
                                            <List p={3} spacing={2} id="teacherMessage" ></List>
                                        </Messages>
                                        <MessageInput>
                                            <Input value={message} onKeyUp={(e) => {if(e.code == 'Enter'){sendMessage()}}} onChange={handleMessage} placeholder="Message To Teacher" focusBorderColor="purple.600" />
                                            <Button colorScheme="purple" ml={3} borderRadius="25px" onClick={sendMessage}><AiOutlineSend /></Button>
                                        </MessageInput>
                                    </MessageContainer>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </StudentActions>
                </ClassContainer>
                <Utils>
                    <DisconnectHolder>
                        <Button id="leaveStudent">Leave Class</Button>
                    </DisconnectHolder>
                    <MediaHolder join={true}>
                        <Tooltip label="Audio Control" bg="white" color="purple.600">
                            <Button colorScheme="purple" id="stopAudioIconI" mr={3} w="50%">
                                <BsFillMicFill id="startAudioIcon"  /><BiMicrophoneOff id="stopAudioIcon" />
                            </Button> 
                        </Tooltip>
                        <Tooltip label="Video Control" bg="white" color="purple.600" >
                            <Button colorScheme="purple" id="stopVideoIconI" mr={3} w={"50%"}>
                                <BiCamera id="startCameraIcon"  /><BiCameraOff id="stopCameraIcon" />
                            </Button>
                        </Tooltip>
                    </MediaHolder>
                    <ResponseHolder>
                        <Tooltip label="Raise Hand" bg="white" color="purple.600" fontFamily="Georgia">
                            <Button colorScheme="purple" id="raiseHand" mr={3}><IoHandLeftSharp /></Button>
                        </Tooltip>
                        <Tooltip label="Understood" bg="white" color="purple.600" fontFamily="Georgia">
                            <Button colorScheme="purple" id="res1" mr={3}><AiFillLike /></Button>
                        </Tooltip>
                        <Tooltip label="Not understood" bg="white" color="purple.600" fontFamily="Georgia">
                            <Button colorScheme="purple" id="res2" ><AiFillDislike /></Button>
                        </Tooltip>
                    </ResponseHolder>
                </Utils>
            </StudentClassInterface>
    </>)
}

export default ClassEntry; 

// disabled state prop will be used to set the joining button and to not allow the user to join if the class Id is wrong or the
// student is not a part of the class participants list.

// Check for the re entry of the user in the class their new socket is to be entered in the room section array and the old one 
// has to be removed from the array.

// think about the option having two different videos playing the presentation and the screen sharing option.