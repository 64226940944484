import './App.css';
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom'; 
import Main from './components/MainComponent.js';

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
