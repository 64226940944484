import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from "./Title";
import { useToast } from '@chakra-ui/react';

import MaterialTable from "material-table";
import axios from 'axios';

export default function Submission(){

    const toast = useToast();
    const toast_id = "toast_test";
    const [update, setUpdate] = useState(false);

    const [testData, setTestData] = useState([]);
    const [test, setTest] = useState([]);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/test/getCreatedTest`, {
            teacherId: JSON.parse(localStorage.getItem('user'))._id
        })
        .then((res) => {
            var tempTestData = [];
            res.data.tests.forEach((item) => {
                tempTestData.push({
                    id: item._id,
                    name: item.name,
                    testDate: new Date(item.testDate).toLocaleDateString('en-GB'),
                    subject: item.subject,
                    status: `${item.attempted.length}/${item.students.length}`
                })
            })

            setTestData(tempTestData);
            setTest(res.data.tests);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    title: "Error In Fetching the Tests",
                    status: "error",
                    position: "top-right",
                    duration: 2000,
                    isClosable: true
                })
            }
        })
    }, [update])

    return (
        <> 
        <Container style={{marginBottom: "20px"}}>
            <MaterialTable 
                title="Created Test Summary"
                data={testData}
                columns={[
                    {title: "Name", field: "name", editable: "always"},
                    {title: "Test Date", field: "testDate", editable: "never"},
                    {title: "Subject", field: "subject", editable: "always"},
                    {title: "Status", field: "status", editable: "never"}
                ]}
                editable={{
                    onRowDelete: (oldData) => {
                        return new Promise((resolve, reject) => {

                            axios.post(`${process.env.REACT_APP_SERVER}/test/deleteTest`, {
                                testId: oldData.id
                            })
                            .then((res) => {
                                resolve();
                                setUpdate(!update);
                            })
                            .catch((err) => {
                                reject();
                                if(!toast.isActive(toast_id)){
                                    toast({
                                        id: toast_id,
                                        title: "Deleting Test Failed",
                                        duration: 2000,
                                        status: "error",
                                        isClosable: true
                                    })
                                }
                            })
                        })
                    }
                }}
                cellEditable = {{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            var editObj = {};
                            editObj[columnDef.field] = newValue

                            axios.post(`${process.env.REACT_APP_SERVER}/test/editTestDetail`, {
                                testId: rowData.id,
                                edit: editObj
                            })
                            .then((res) => {
                                resolve();
                                setUpdate(!update);
                            })
                            .catch((err) => {
                                reject();
                                if(!toast.isActive(toast_id)){
                                    toast({
                                        id: toast_id,
                                        title: "Editing Test Failed",
                                        duration: 2000,
                                        status: "error",
                                        isClosable: true
                                    })
                                }
                            })
                        })
                    }
                }}
            />
        </Container>
        {typeof test === 'undefined' ? null : test.length  !== 0 ? 
            test.map((assign) => {
                return (
                    <Container key={assign._id} style={{marginBottom: "20px"}}>
                        <Title>{assign.name}</Title>
                        <Table size="large">
                            <TableHead>
                                <TableCell>Name Of Student</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Marks</TableCell>
                            </TableHead>
                            <TableBody>
                            {assign.attempted.map((sub) => {
                                return (
                                    <TableRow key={sub._id}>
                                        <TableCell>{sub.name}</TableCell>
                                        <TableCell>{sub.email}</TableCell>
                                        <TableCell>
                                            {`${sub.mark}/${sub.total}`}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </Container>
                )
            })
            :null
        }
        </>
    )
}