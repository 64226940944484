import React, {useState} from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({updateNotice, updateSet}) {
  const [open, setOpen] = React.useState(false);
  const [notice, setNotice] = useState("");

  const toast = useToast();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const noticeController = () => {
    axios.post(`${process.env.REACT_APP_SERVER}/user/addNotice`, {
      notice: notice,
      adminId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      handleClose();
      updateNotice(!updateSet)
      toast({
        title:"Notice Published",
        position: "top",
        isClosable: true,
        status: "success"
      })
    })
    .catch(err => {
      toast({
        title: "Something Went Wrong",
        position: "top",
        isClosable: true,
        status: "error"
      })
    })
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Publish a Notice
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your content for the Notice
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="content"
            label="Notice"
            type="text"
            multiline
            fullWidth
            value={notice}
            onInput={(e) => {setNotice(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={noticeController} color="primary">
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
