import React, {useState, useEffect} from 'react';
import Title from './Title';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function Notice() {

  const toast = useToast();
  const [gotNotices, setGotNotices] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}/user/getNotices`)
    .then((res) => {
      setGotNotices(res.data.notices);
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Notices",
        status: "error",
        isClosable: true,
        position: "top"
      })
    })
  }, [])

  return (
    <React.Fragment>
      <Title><u>Notice Board</u></Title>
      <Grid container spacing={1} >
        { typeof gotNotices === 'undefined' ? null : gotNotices.length !== 0  ?
          gotNotices.map((notice) => {
            return(
              <Grid key={notice._id} item xs={12}>
                <Typography>{notice.notice}</Typography>
              </Grid>
            )
        }): null}
      </Grid>
    </React.Fragment>
  );
}