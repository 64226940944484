import React, { useState } from "react";
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Schedule from '@material-ui/icons/Schedule';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8,4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));
  
const ResetLoginComponent = () => {
    const classes = useStyles();
    const toast = useToast();
    const toast_id = "toast";

    const [email, setEmail] = useState("");
    const [loginType, setLoginType] = useState("");

    const resetActiveState = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_SERVER}/auth/resetActiveState`, {
            adminId: JSON.parse(localStorage.getItem("user")).email,
            email: email,
            loginType
        })
        .then((res) => {
            setEmail("");
            setLoginType("");

            if(!toast.isActive(toast_id)){
                toast({
                    title: "Login Reset Successfull",
                    position: "top",
                    isClosable: true,
                    id: toast_id,
                    status: "success"
                })
            }
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    title: "Error in Login Reset",
                    position: "top",
                    isClosable: true,
                    id: toast_id,
                    status: "error"
                })
            }
        })
    }

    return(
        <Container component="main" maxWidth="md">
            <CssBaseline />

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <Schedule />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Resetting A Login
                </Typography>
                <form className={classes.form} onSubmit={resetActiveState}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField 
                                variant="standard"
                                type="email"
                                label="Email"
                                placeholder="Enter the email of the account"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                fullWidth
                                margin="dense"
                            />
                            <FormControl required fullWidth className={classes.formControl}
                                margin="dense"
                            >
                                <InputLabel>Login Type</InputLabel>
                                <Select
                                    required
                                    label="Login Type"
                                    value={loginType}
                                    onChange={(e) => setLoginType(e.target.value)}
                                >
                                    <MenuItem value="teacher">Teacher</MenuItem>
                                    <MenuItem value="student">Student</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Reset Login
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}

export default ResetLoginComponent;