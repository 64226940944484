import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Instructions from './instruction'
import { setLocalStorage, setCookie, isAuth, authenticate } from '../../helper/auth'; 
import { GoogleLogin } from 'react-google-login';
import { Redirect } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" href="#">
        PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/ZslFOaqzERU)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export default function SignInSide({history}) {
  const classes = useStyles();
  const toast = useToast();

  const signUp = () => {
    history.push('/signUp')
  }
  const forgotpassword = () => {
    history.push('/users/password/forgot') 
  }

  const sendGoogleToken = tokenId => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/googleLogin`,{
        idToken: tokenId
    }).then(res => {
      informParent(res)
    })
    .catch(err => {
        toast({
          title: 'Google Login Error'
        })
    })
  }

  const informParent = response => {
    if(typeof response.data.tokenG !== 'undefined'){
      toast({
        title: "OTP sent to your email"
      })
      history.push(`/register/${response.data.tokenG}`)
    }else if(typeof response.data.token !== 'undefined'){
      authenticate(response, () => {
          if(isAuth()){
              if(isAuth().role === 'admin'){
                history.push('/admin')
              } else if(isAuth().role === 'teacher'){
                history.push('/teacher')
              } else {
                history.push('/student')
              }
          }
      })
    } else {
      toast({
        title: "You are not authorized yet!"
      })
    }
  }

  const responseGoogle = response =>{
    if(response.tokenId){
      sendGoogleToken(response.tokenId)
    }
  }

  const signIn = (e) => {
    e.preventDefault();

    setEmail("");
    setPassword("");

    axios.post(`${process.env.REACT_APP_SERVER}/auth/login`, {
      email: email, password: password, type: type
    })
    .then((res) => {

      if(res.data.message){
        toast({
          title: res.data.message,
          position: "top"
        })
      } else {
      setCookie("token", res.data.token);
      setLocalStorage("user", res.data.user);

      setTimeout(() => {
        if(res.data.user.role === "admin"){
          history.push('/admin');
        } else if(res.data.user.role === "teacher"){
          history.push('/teacher');
        } else {
          history.push('/student');
        }
      }, 1000);
    }
    })
    .catch((err) => {
      toast({
        title: "Error Occured",
        description: "Something Went Wrong",
        isClosable: true,
        status: "error",
        position: "top"
      })
    })
  }
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');

  const handleChangeType = (e) => {
    setType(e.target.value);
  }
  return (
    <>
    {isAuth() ? <Redirect to='/' /> : null}
    <Grid container component="main" className={classes.root}>
      
      <CssBaseline />
     
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={signIn}>
          <label>Email</label>
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              type="email"
              fullWidth
              value={email}
              onInput={ e=>setEmail(e.target.value)}
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <label> Password</label>
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              value={password}
              onInput={ e=>setPassword(e.target.value)}
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Type</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={type}
                  onChange={handleChangeType}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  
                    <MenuItem key={"student"} value={"student"}>
                      <ListItemText primary={"Student"} />
                    </MenuItem>
                    <MenuItem key={"student"} value={"teacher"}>
                      <ListItemText primary={"Teacher"} />
                    </MenuItem>
                    
                </Select>
              </FormControl>
            <br />
            <br/>
            <center ><Button variant="outlined"
              color="inherited" ><b>Instructions</b></Button></center><br/>
              <li> You can't miss even a single class, however if it happens make a recovery by viewing Repeat Telecast Class.
            </li>

            <li> You have to complete the homework on a regular basis.
            </li>
            <li> If you have any problem or doubt with the concept, you should raise your hand immediately.
            </li>

            <li>If you were having any problem while attending the class, you must inform us once the class is over.
            </li>

            <li> Before attending the class make sure that you have a proper internet connection.
            </li>

            <li>Sign in to the class at least 10 minutes before the class time.
            </li>

            <li>You must keep your video ON while attending the class.
            </li>

            <li>Student should have a very good practise of mute and unmute of audio.
            </li>

            <li>Student must sit on table and chair at home with full attention and in zero disturbance surrounding alike they sit in classroom.
            </li>
            
            <li>
              Don't share class link with any outsider this is only for you.
            </li>

            <li>
              Recording of this class is strictly prohibited in any form. Kindly refrain yourself from indulging in such activities
            </li>
            <li>
              Please note down all the material and questions provided to you in your notebook positively.
            </li>

            <li>
              Student should maintain the decorum of the class.
            </li>
            <br />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" required={true}/>}
              label="I accept all the instructions and rules"
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          
              <Grid container>
              <Grid item xs>
                <Link onClick={forgotpassword} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={signUp} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          <center>
          <GoogleLogin clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`} onSuccess={ responseGoogle } onFailure={ responseGoogle } cookiePolicy={'single_host_origin'} 
          render={renderProps => (
            <Button onClick={renderProps.onClick} disabled={renderProps.disabled} id='googlelogin' variant="contained" className={classes.submit}>
              <img src="https://img.icons8.com/fluent/48/000000/google-logo.png" alt="Registration"/>
                Sign Up with GOOGLE
            </Button>
          )}>
        </GoogleLogin></center>
        </div>
      </Grid>
    </Grid>
    </>
  );
}