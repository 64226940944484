import cookie from 'js-cookie';
import axios from 'axios';

export const setCookie = (key, value) => {
    if(window !== 'undefined'){
        cookie.set(key, value, {
            expires: 1
        })
    }
}

export const removeCookie = (key) => {
    if(window !== 'undefined'){
        cookie.remove(key, {
            expires: 1
        })
    }
}

export const getCookie = key => {
    if(window !== 'undefined'){
        return cookie.get(key)
    }
}

export const setLocalStorage = (key, value) => {
    if(window !== 'undefined'){
        localStorage.setItem(key, JSON.stringify(value))
    }
}

export const removeLocalStorage = key => {
    if(window !== 'undefined'){
        localStorage.removeItem(key)
    }
}

export const authenticate = (response, next) => {
    setCookie('token', response.data.token)
    setLocalStorage('user', response.data.user)
    next()
}

export const signout = (id, role) => {
    removeActiveState(id, role);
}

export const isAuth = () => {
    if(window !== 'undefined'){
        const cookieChecked = getCookie('token')
        if(cookieChecked){
            if(localStorage.getItem('user')){
                return JSON.parse(localStorage.getItem('user'))
            } else {
                return false
            }
        } else {
            return false
        }
    }
}

export const updateUser = (response, next) => {
    if(window !== 'undefined'){
        let auth = JSON.parse(localStorage.getItem('user'))
        auth = response.data
        localStorage.setItem('user', JSON.stringify(auth))
    }
    next()
}

export const sessionStorageClearage = (arrValues) => {
    arrValues.forEach((value) => {
        sessionStorage.removeItem(value);
    })
}

export const sessionStorageSet = (arrSet) => {
    arrSet.forEach((value) => {
        sessionStorage.setItem(value[0], value[1]);
    })
}

const removeActiveState = (id, role) => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/removeActiveState`, {
        id, role
    })
    .then((res) => {
        removeCookie('token')
        removeLocalStorage('user')
    })
    .catch((err) => {
        alert("Signout Failed");
    })
}
