import React from "react";
import classnames from "classnames";
import { If } from "react-if";
import hark from "hark";
import Spinner from "react-spinner";

export default class PeerView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          audioVolume: 0,
          videoResolutionWidth: null,
          videoResolutionHeight: null,
          videoCanPlay: false,
          videoElemPaused: false
        }
        
        this._audioTrack = null;
        this._videoTrack = null;

        this.videoElem = React.createRef(null);
        this.audioElem = React.createRef(null);

        this._hark = null;
        this._videoResolutionPeriodicTimer = null;
    }

    render() {
        const{
            isMe,
            peer,
            audioMuted,
            videoVisible,
            videoMultiLayer,
            videoRtpParameters,
            maxSpatialLayer,
            audioScore,
            videoScore,
            consumerCurrentSpatialLayer
        } = this.props;

        const {
            audioVolume,
            videoCanPlay,
            videoElemPaused
        } = this.state;

        return(
            <div data-component="PeerView">
                <div className="info">
                    <div className={classnames("peer", {"is-me": isMe})}>
                        <span className="display-name">{peer.name}</span>
                    </div>
                </div>

                <video
                    ref={this.videoElem}
                    className={classnames({
                        "is-me": isMe,
                        hidden: !videoVisible || !videoCanPlay,
                        "network-error":
                            videoVisible &&
                            videoMultiLayer && 
                            consumerCurrentSpatialLayer === null,
                    })}
                    playsInline
                    muted
                    controls={false} 
                />

                <audio 
                    ref={this.audioElem}
                    playsInline
                    muted={isMe || audioMuted}
                    controls = {false}
                />

                <div className="volume-container">
                    <div className={classnames("bar", `level${audioVolume}`)} />
                </div>

                <If condition={videoVisible && videoScore < 5}>
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                </If>

                <If condition={videoElemPaused}>
                    <div className="video-elem-paused" />
                </If>
            </div>
        )
    }

    componentDidMount() {
        const { audioTrack, videoTrack } = this.props;
        this._setTracks(audioTrack, videoTrack);
    }

    componentWillUnmount() {
        if (this._hark) this._hark.stop();
    
        clearInterval(this._videoResolutionPeriodicTimer);
        cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);
    
        const videoElem = this.videoElem.current;
    
        if (videoElem) {
          videoElem.oncanplay = null;
          videoElem.onplay = null;
          videoElem.onpause = null;
        }
    }

    componentDidUpdate() {
        const { isMe, audioTrack, videoTrack, videoRtpParameters } = this.props;
    
        const { maxSpatialLayer } = this.state;
    
        if (isMe && videoRtpParameters !== null && maxSpatialLayer === null) {
          this.setState({
            maxSpatialLayer: videoRtpParameters.encodings.length - 1,
          });
        } else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
          this.setState({ maxSpatialLayer: null });
        }
    
        this._setTracks(audioTrack, videoTrack);
    }

    _setTracks(audioTrack, videoTrack) {

        if (this._audioTrack === audioTrack && this._videoTrack === videoTrack)
          return;
    
        this._audioTrack = audioTrack;
        this._videoTrack = videoTrack;
    
        if (this._hark) this._hark.stop();
    
        this._stopVideoResolution();
  

        if (audioTrack) {
          const stream = new MediaStream();
    
          stream.addTrack(audioTrack);
          this.audioElem.current.srcObject = stream;
    
          this.audioElem.current.play().catch((error) => {
            console.log("Failed Audio Elem");
          });
    
          this._runHark(stream);
        } else {
          this.audioElem.current.srcObject = null;
        }
    
        if (videoTrack) {
          const stream = new MediaStream();
    
          stream.addTrack(videoTrack);
          this.videoElem.current.srcObject = stream;
    
          this.videoElem.current.oncanplay = () => this.setState({ videoCanPlay: true });
    
          this.videoElem.current.onplay = () => {
            this.setState({ videoElemPaused: false });
    
            this.audioElem.current.play().catch((error) => {
              console.log("Failed Audio Elem");
            });
          };
    
          this.videoElem.current.onpause = () => this.setState({ videoElemPaused: true });
    
          this.videoElem.current.play().catch((error) => {
            console.log("Failed Video Elem");
          });
    
          this._startVideoResolution();
        } else {
          this.videoElem.current.srcObject = null;
        }
    }

    _runHark(stream) {
        if (!stream.getAudioTracks()[0])
            return
    
        this._hark = hark(stream, { play: false });
    
        // eslint-disable-next-line no-unused-vars
        this._hark.on("volume_change", (dBs, threshold) => {
          // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
          //   Math.pow(10, dBs / 20)
          // However it does not produce a visually useful output, so let exagerate
          // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
          // minimize component renderings.
          let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);
    
          if (audioVolume === 1) audioVolume = 0;
    
          if (audioVolume !== this.state.audioVolume)
            this.setState({ audioVolume });
        });
    }

    _startVideoResolution() {
        this._videoResolutionPeriodicTimer = setInterval(() => {
          const { videoResolutionWidth, videoResolutionHeight } = this.state;
    
          if (
            this.videoElem.current.videoWidth !== videoResolutionWidth ||
            this.videoElem.current.videoHeight !== videoResolutionHeight
          ) {
            this.setState({
              videoResolutionWidth: this.videoElem.current.videoWidth,
              videoResolutionHeight: this.videoElem.current.videoHeight,
            });
          }
        }, 500);
    }
    
    _stopVideoResolution() {
        clearInterval(this._videoResolutionPeriodicTimer);
    
        this.setState({
          videoResolutionWidth: null,
          videoResolutionHeight: null,
        });
    }
}