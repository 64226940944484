import io from 'socket.io-client';
import Peer from 'simple-peer';
import { attendenceFunction } from '.';
import { sessionStorageClearage, sessionStorageSet } from '../../helper/auth'; 

const wrtc = require('wrtc');
// const socket = io(`${process.env.REACT_APP_SERVER}`);

var ownSocketId;
let socket;
var classId;
var myPeer = [];
var globalStream;
var shareScreen;

window.onload = function (){
    var reloadYN = sessionStorage.getItem("reloading");
    if(reloadYN){
        var classIdentifier = sessionStorage.getItem("classId")
        socket.emit('endOfClass', classIdentifier);
        sessionStorageClearage(["reloading", "classId", "inClass"]);
    }
}

const createLocalStream = (video) => {
    video.srcObject = globalStream;
    video.muted = true;  // own video is always until yoou wanna talk to your own self.
    video.addEventListener("loadedmetadata", () => {
        video.play();
    });
};

const stopLocalVideo = () =>{

    const stopVideo = document.getElementById("stopVideo");

    if(stopVideo){
        stopVideo.addEventListener("click", () => {

            if(globalStream.getVideoTracks()[0].enabled){
                globalStream.getVideoTracks()[0].enabled = false;
                document.getElementById("startCameraIcon").style.display = "block";
                document.getElementById("stopCameraIcon").style.display = "none";

            }else {
                globalStream.getVideoTracks()[0].enabled = true;
                document.getElementById("startCameraIcon").style.display = "none";
                document.getElementById("stopCameraIcon").style.display = "block";
            }
        })
    }
}

const stopLocalAudio = () => {

    const stopAudio = document.getElementById("stopAudio");

    if(stopAudio){
        stopAudio.addEventListener("click", () => {

            if(globalStream.getAudioTracks()[0].enabled){  
                globalStream.getAudioTracks()[0].enabled = false;

                document.getElementById("stopAudioIcon").style.display = "none";
                document.getElementById("startAudioIcon").style.display = "block";
            } else {
                globalStream.getAudioTracks()[0].enabled = true;
                document.getElementById("startAudioIcon").style.display = "none";
                document.getElementById("stopAudioIcon").style.display = "block";
            }
        })
    }
}

const shareScreenDisplay = () => {

    const shareBtn = document.getElementById("shareOwnBtn");
    const stopShare = document.getElementById("stopShareBtn");

    if(shareBtn){
        shareBtn.addEventListener("click", () => {

            navigator.mediaDevices.getDisplayMedia({cursor: true})
            .then((screenShareStream)=>{

                shareScreen = screenShareStream;
                shareBtn.disabled = true;
                stopShare.disabled = false;

                if(myPeer.length !== 0){
                    myPeer.forEach((peer) => {
                        peer[0].replaceTrack(globalStream.getVideoTracks()[0], screenShareStream.getVideoTracks()[0], globalStream);
                    })
                }
                document.getElementById("teacherOwnVideo").srcObject = screenShareStream; 
                screenShareStream.getTracks()[0].onended = () =>{
                    if(myPeer.length !== 0){
                        myPeer[0].forEach((peer) => {
                            peer.replaceTrack(screenShareStream.getVideoTracks()[0], globalStream.getVideoTracks()[0], globalStream)
                        })
                    }
                    document.getElementById("teacherOwnVideo").srcObject = globalStream;
                    shareBtn.disabled = false;
                    stopShare.disabled = true;
                    shareScreen = globalStream;
                }
            })
            .catch(err => {
                alert("Screen Share Error!");
            })
        })
    }
    if(stopShare){
        stopShare.addEventListener('click', () => {
            shareScreen.getVideoTracks()[0].stop();
            shareScreen.getVideoTracks()[0].dispatchEvent(new Event("ended"));
        })
    }
}

const startClassHandler = (meetId) => {

    if(document.getElementById("startClass")){
        document.getElementById("startClass").addEventListener("click", () => {
            socket.emit("startClass", meetId);
        })
    }
}

const classStream = () => {
    socket.on("teacherJoined", (socketId) => {

        ownSocketId = socketId;
        sessionStorageSet([["reloading", true], ["inClass", true], ["classId", classId]]);
        document.getElementById("joinInterface").style.display = "none";
        document.getElementById("classInterface").style.display = "flex";

        const classVideo = document.getElementById("teacherOwnVideo");

        classVideo.srcObject = globalStream;
        classVideo.muted = true;
        classVideo.addEventListener("loadedmetadata", () => {
            classVideo.play();
        });

        if(document.getElementById("tabs-5--tab-0")){
            document.getElementById("tabs-5--tab-0").addEventListener("click", () => {
                if(!(document.getElementById("tabs-5--tab-0").children[0].children[0].classList.contains("MuiBadge-invisible"))){
                    document.getElementById("tabs-5--tab-0").children[0].children[0].classList.add("MuiBadge-invisible");
                }
            })
        }
        if(document.getElementById("tabs-5--tab-1")){
            document.getElementById("tabs-5--tab-1").addEventListener("click", () => {
                if(!(document.getElementById("tabs-5--tab-1").children[0].children[0].classList.contains("MuiBadge-invisible"))){
                    document.getElementById("tabs-5--tab-1").children[0].children[0].classList.add("MuiBadge-invisible");
                }
            })
        }
        if(document.getElementById("tabs-5--tab-2")){
            document.getElementById("tabs-5--tab-2").addEventListener("click", () => {
                if(!(document.getElementById("tabs-5--tab-2").children[0].children[0].classList.contains("MuiBadge-invisible"))){
                    document.getElementById("tabs-5--tab-2").children[0].children[0].classList.add("MuiBadge-invisible");
                }
            })
        }

        checkVideo(globalStream.getVideoTracks());
        checkAudio(globalStream.getAudioTracks());
        stopClassVideo(globalStream.getVideoTracks());
        stopClassAudio(globalStream.getAudioTracks());
    });
}

const checkVideo = (trackArray) => {
    if(trackArray[0].enabled){
        document.getElementById("cOffIcon").style.display = "none";
        document.getElementById("cOnIcon").style.display = "block";
    } else {
        document.getElementById("cOffIcon").style.display = "block";
        document.getElementById("cOnIcon").style.display = "none";
    }
}

const checkAudio = (trackArray) => {
    if(trackArray[0].enabled){
        document.getElementById("aOffIcon").style.display = "none";
        document.getElementById("aOnIcon").style.display = "block";
    } else {
        document.getElementById("aOffIcon").style.display = "block";
        document.getElementById("aOnIcon").style.display = "none";
    }
}

const stopClassVideo = (trackArray) => {

    const stopOwnVideo = document.getElementById("stopOwnVideo");

    if(stopOwnVideo){

        stopOwnVideo.addEventListener("click", () => {

            if(trackArray[0].enabled){
                
                globalStream.getVideoTracks()[0].enabled = false;
                document.getElementById("cOffIcon").style.display = "block";
                document.getElementById("cOnIcon").style.display = "none";
            }else {
                globalStream.getVideoTracks()[0].enabled = true;
                document.getElementById("cOffIcon").style.display = "none";
                document.getElementById("cOnIcon").style.display = "block";
            }
        })
    }
}

const stopClassAudio = (trackArray) => {

    const stopOwnVideo = document.getElementById("stopOwnAudio");

    if(stopOwnVideo){

        stopOwnVideo.addEventListener("click", () => {

            if(trackArray[0].enabled){
                
                globalStream.getAudioTracks()[0].enabled = false;
                document.getElementById("aOffIcon").style.display = "none";
                document.getElementById("aOnIcon").style.display = "block";
            }else {
                globalStream.getAudioTracks()[0].enabled = true;
                document.getElementById("aOffIcon").style.display = "block";
                document.getElementById("aOnIcon").style.display = "none";
            }
        })
    }
}

const indivualRemove = (id) => {

    if(document.getElementById(`btn-${id}`)){

        document.getElementById(`btn-${id}`).addEventListener("click", () => {
            socket.emit("youWereRemoved", id);
        })
        
    }
}

const acceptOthersCall = () => {
    
    socket.on("handshake", (handshakeData) => {
        
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: globalStream,
            wrtc: wrtc
        })

        myPeer.push([peer]);
        peer.on("signal", data => {
            socket.emit("handshakeAccepted", {acceptor: data, for: handshakeData.sender, nameOfFor: handshakeData.name})
        })

        peer.on("stream", (stream) => {

            document.getElementById("studentVideoContainer").style.display = "flex";
            var studentVideoHolder = document.getElementById("studentVideoContainer");

            var count = studentVideoHolder.childElementCount;
            var commonWidth = studentVideoHolder.offsetWidth/ (count+1);
            var commonHeight = studentVideoHolder.offsetHeight/ (Math.floor( (count/3) + 1 ));

            var arrayItem = [...studentVideoHolder.children];
            arrayItem.forEach((elem) => {
                elem.style.width = `240px`;
                elem.style.height = `135px`;
            })

            var newStudent = document.createElement("video");
            newStudent.srcObject = stream;

            newStudent.setAttribute("id", handshakeData.sender);

            myPeer.forEach((peers) => {
                if(peers[0]._id == peer._id){
                    peers[1] = handshakeData.sender
                    peers[2] = handshakeData.name
                }
            })

            newStudent.style.width = `240px`;
            newStudent.style.height = `135px`;
            newStudent.autoplay = true;
            newStudent.playsInline = true;
            newStudent.style.transform = "scaleX(-1)";
            newStudent.style.objectFit = "fill";

            newStudent.play();

            studentVideoHolder.append(newStudent);
            document.getElementById("shareOwnBtn").disabled = false;
            document.getElementById("recordClass").disabled = false;
        })

        peer.on('error', (err) => {
            myPeer.forEach((peers) => {
                if(peers[0]._id === peer._id){
                    const lH = document.getElementById("participantsList");
                    var a = [...lH.children];
            
                    a.forEach((elem) => {

                        if(elem.firstElementChild.firstElementChild.innerText === peers[2]){
                            lH.removeChild(elem);
                        }
                    })
            
                    const vL = document.getElementById("studentVideoContainer");
                    if(document.getElementById(peers[1])){
                        vL.removeChild(document.getElementById(peers[1]));
                    }
                }
            })
            peer.destroy(err);

            var l = myPeer.filter(p => p[0]._id !== peer._id);
            myPeer = l;
        })
        
        peer.signal(handshakeData.mySignal);
    })
}

var resIndicator;

const risenHand = () => {

    socket.on("studentRaisedHand", (name, response) => {

        if(document.getElementById("rBadge").children[0].classList.contains("MuiBadge-invisible")){
            document.getElementById("rBadge").children[0].classList.remove("MuiBadge-invisible");
        }

        if(response === "Raised Hand"){
            resIndicator = "&#9995;"
        } else if(response === "Not Understood") {
            resIndicator = "&#128078;"
        } else {
            resIndicator = "&#128077;"
        }
        const item = document.createElement("li");
        item.innerHTML = `<div class="space"><span class="messageName">${name}</span> <span class="response">${resIndicator}</span></div>`;

        document.getElementById("responseList").append(item);
    })
}

const studentJoined = () => {

    socket.on("thisOneJoined", (name, id) => {

        const own = document.getElementById("teacherOwnVideo");
        if(window.outerWidth > 768){
            own.style.position = "absolute";
            own.style.top = 0;
            own.style.left = 0;
            own.style.margin = "0 5px";

            own.style.display = "block";
            own.style.width = "150px";
            own.style.height = "150px";
            own.style.zIndex = "10";
        } else {
            own.style.display = "none";
        }

        const student = document.createElement("li");
        student.innerHTML = `<div class="space"><span class="AttendenceName">${name}</span><button id="btn-${id}">Remove</button></div>`;
        student.setAttribute("class", "attendeeStyle");
        student.setAttribute("id", `uid-${id}`);

        document.getElementById("attendenceClass").disabled = false;
        document.getElementById("participantsList").append(student);

        if(document.getElementById("pBadge").children[0].classList.contains("MuiBadge-invisible")){
            document.getElementById("pBadge").children[0].classList.remove("MuiBadge-invisible");
        }
        indivualRemove(id);
    })
}

const studentMessaged = (roomId) => {
    
    socket.on("thisStudentMessaged", (name, message, studentId) => {
        
        if(document.getElementById("msgBadge").children[0].classList.contains("MuiBadge-invisible")){
            document.getElementById("msgBadge").children[0].classList.remove("MuiBadge-invisible");
        }

        var mili = new Date().getMilliseconds();
        var studentMessage = `
        <div class="message-private"><span class="privateName">&nbsp;&nbsp;&nbsp;&nbsp;<b>${name} </b>:</span><span class="privateMessage">${message}</span><span class="replyButtonStyle"><button class="replyButton" id="${message}${studentId}${mili}">Reply</button></span></div>
        `

        var studentItem = document.createElement("li");
        studentItem.innerHTML = studentMessage;

        document.getElementById("messageList").append(studentItem);

        replyPrivate(message, roomId, studentId, mili);
    })
}

const replyPrivate = (message, roomId, studentId, mili) => {

    var id = message+studentId+mili;

    document.getElementById(`${id}`).addEventListener("click", () => {
        
        var reply = prompt("Reply to this student");
        if(reply){
            socket.emit("teacherMessaging", reply, "private", roomId, studentId);
        }
    })
}

const muteEveryone = (meetId) => {
    
    const mute = document.getElementById("mute");

    if(mute){
        mute.addEventListener("click", () => {
            socket.emit("muteEveryone", meetId);
        })
    }
}

const leaveClass = (meetId) => {
    const end = document.getElementById("endClass");

    if(end){

        end.addEventListener("click", () => {
            if(myPeer.length !== 0){
                myPeer.forEach((peer) => {peer[0].destroy()})
            }
            console.log("MeetId", meetId);
            socket.emit('endOfClass', meetId);
            sessionStorageClearage(["classId", "reloading", "inClass"]);
            window.location.href = `${process.env.REACT_APP_TEACHER}`; //replace this with the actual route for the teacherPanel.
        })
    }
}

// recording section.
var mediaRecorder;
var recorded = [];

const recordClass = (meetId) => {

    const recordBtn = document.getElementById("recordClass");

    if(recordBtn){
        recordBtn.addEventListener("click", () => {
            if(recordBtn.innerText === "Record Class"){

                var options = {
                    mimeType : 'video/webm;codecs=h264,opus'
                }

                mediaRecorder = new MediaRecorder(shareScreen, options);
                mediaRecorder.start(1000);

                mediaRecorder.ondataavailable = (e) => {
                    recorded.push(e.data);
                }

                recordBtn.innerText = "Stop Recording"
            } else {
                if(mediaRecorder.state !== "inactive"){
                    mediaRecorder.stop();
                    const recordedBlob = new Blob(recorded, {
                        type: "video/webm"
                    })

                    recorded = [];
                    const videoUrl = URL.createObjectURL(recordedBlob);
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = videoUrl;
                    a.download = `${meetId}.webm`;
                    a.click();
                    window.URL.revokeObjectURL(videoUrl);

                    recordBtn.innerText = "Record Class"
                } else {
                    recorded = [];
                    recordBtn.innerText = "Record Class";
                }   
            }
        })
    }
}

//attendence Class
var classAttendence = [];

const attendence = () => {

    const attendenceBtn = document.getElementById("attendenceClass");
    if(attendenceBtn){
        attendenceBtn.addEventListener("click", () => {
            var list = [...document.getElementById("participantsList").children];

            list.forEach((elem) => {
                classAttendence.push(elem.firstElementChild.firstElementChild.innerText);
            })

            attendenceFunction(classId, classAttendence);
        })
    }
}

const studentLeft = () => {

    socket.on("thisLeft", (name, leaverId) => {
        const listHolder = document.getElementById("participantsList");
        var attendees = [...listHolder.children];

        attendees.forEach((elem) => {
            if(elem.firstElementChild.firstElementChild.innerText === name){
                listHolder.removeChild(elem);
            }
        })

        var elemss =  myPeer.filter(peer =>
            peer[1] !== leaverId 
        )
        myPeer = elemss;

        const videoList = document.getElementById("studentVideoContainer");
        if(document.getElementById(leaverId)){
            videoList.removeChild(document.getElementById(leaverId));
        }
    })
}
 
export const sendMessagePublic = (roomId, message) => {

    var itemOO = `
    <div class="yourOwnMessage"><span class="public">&nbsp;&nbsp;&nbsp;&nbsp;<b> You </b>:</span> <span class="publicMessage">${message}</span></div>
    `

    var itemHolder = document.createElement("li");
    itemHolder.innerHTML = itemOO;
    
    document.getElementById("messageList").append(itemHolder);

    socket.emit("teacherMessaging", message, "public", roomId);
}

export const intializeTeacherStream = ({meetId, socketRef}) => {
    navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
    }).then((stream) =>{
        globalStream = stream;
        shareScreen = globalStream;
        classId = meetId;
        socket = socketRef

        const myVideo = document.getElementById("videoHolder");

        createLocalStream(myVideo);
        stopLocalVideo();
        stopLocalAudio();
        shareScreenDisplay();

        startClassHandler(meetId);
        classStream();
        acceptOthersCall();
        studentJoined();

        risenHand();
        studentMessaged(meetId);

        recordClass(meetId);
        muteEveryone(meetId);
        leaveClass(meetId);
        attendence();

        studentLeft();
    })
    .catch((err) => {
        alert("Please give the required permission");
    });
}