import styled from 'styled-components';

export const StudentJoinInterface = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px;
    width: 100%
    height: 80vh;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`

export const ButtonHolderStudent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
`

export const InstructionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding: 0 30px;

    @media screen and (max-width: 768px){
        padding: 10px;
        width: 100%;
        margin-top: 20px;
    }
`

export const InstructionTopic = styled.h4`
    font-family: Georgia;
    font-size: 27px;
    text-align: center;
`

export const Instructions = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 0;
    justify-content: center;
`

export const JoinPara = styled.div`
    font-family: sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
`

export const StudentClassInterface = styled.div`
    display: none;
    width: 100%;
    flex-direction: column;
    height: 100vh;
`

export const ClassContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`

export const TeacherVideoHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    background-color: #000;
    height:88vh;

    @media screen and (max-width: 768px){
        width: 100%;
        height: 45vh;
    }
`

export const ShareScreenTeacher = styled.div`
    display: none;
`

export const TeacherVideo = styled.video`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`

export const OwnVideo = styled.video`
    display: flex;
    height: 400px;
    width: 100px;
`;

export const StudentActions = styled.div`
    display: flex;
    width: 25%;

    @media screen and (max-width: 768px){
        width: 100%;
        height: 300px;
        overflow-y: scroll;
    }
`

export const MessageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 78vh;
    flex-direction: column;

    @media screen and (max-width:768px){
        height: 30vh;
    }
`

export const Messages = styled.div`
    flex-grow: 38;
    justify-content: start;
    align-items: start;
    overflow-y: auto;
`

export const MessageInput = styled.div`
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    bottom: 0;
    padding: 10px 15px;
    flex-direction: row;
`

export const DisconnectHolder = styled.div`
    display: flex;
    align-item: center;
    justify-content: center;
    width: 30%;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const ResponseHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;

    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 15px;
    }
`

export const MediaHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 15px;
    }
`