import React, {useState, useEffect} from 'react';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8,4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
   
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  

function CreateGroup(){
    const classes = useStyles();
    const toast = useToast();

    const [groupName, setGroup] = useState("");
    const [invited, setInvited] = useState([]);
    const [students, setStudents] = useState([]);

    const handleChangeStudent = (event) => {
        setInvited(event.target.value)
    }

    const createTheGroup = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_SERVER}/group/createGroup`,{
            adminId: JSON.parse(localStorage.getItem('user')).email,
            name: groupName,
            students: invited
        })
        .then((res) => {
            setGroup("");
            setInvited([]);
            toast({
                title: "Group Created Successfully!",
                status: "success",
                isClosable: true,
                position:"top"
            })
        })
        .catch((err) => {
            toast({
                title: "Something Went Wrong!",
                position: "top"
            })
        })
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/class/getStudentList`,{
            adminId: JSON.parse(localStorage.getItem("user")).email
        })
        .then((res) => {
            setStudents(res.data.students);
        })
        .catch(err => {
            toast({
            title: "Error Occured",
            position: "top"
            })
        })
    }, [toast])

    return (
        <>
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <GroupAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Creating A New Group
                    </Typography>
                    <form className={classes.form} onSubmit={createTheGroup}>
                        <Grid item xs={12} >
                            <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="group"
                            value={groupName}
                            onChange={e => setGroup(e.target.value)}
                            label="Group Name"
                            name="groupName"
                            autoComplete=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id="demo-mutiple-name-label">Students</InputLabel>
                                <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={invited}
                                onChange={handleChangeStudent}
                                input={<Input />}
                                MenuProps={MenuProps}
                                >
                                {students.map((name) => (
                                    <MenuItem key={name._id} value={name._id}>
                                    {`${name.name}`}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Create Group
                        </Button>
                    </form>
                </div>
            </Container>
        </>
    )
}

export default CreateGroup