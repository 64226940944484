import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    message: {
        padding: '6px 1px 6px 1px',
        display: 'flex',
        flexDirection: 'column'
    }
}))

function Message({messages, unread}){

    const classes = useStyles();

    return (
        <>
        { Object.keys(messages).length !== 0 ?
            Object.keys(messages).map((key) => { 
                return (
                    <div key={key} className={classes.message}>
                        <p style={{fontSize:'18px', color: 'white', fontFamily: 'Nunito', fontWeight: '600', maxWidth: '20vw'}}>{messages[key].sender}</p>
                        <p style={{fontSize:'15px', color: 'white', fontFamily: 'Nunito', maxWidth: '20vw'}}>{messages[key].message}</p>
                    </div>
                )
            })
        :
            null
        }
        </>
    )
}

export default Message;