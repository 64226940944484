import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    response: {
        fontSize: '18px',
        color: 'white',
        fontFamily: 'Nunito', 
        fontWeight: '600', 
        maxWidth: '20vw'
    }
}))

function Response({data, response}) {

    const classes = useStyles();
    useEffect(() => {
        response(true);
    }, [])

    return(
        <>
        { Object.keys(data).length !== 0 ?
            Object.keys(data).map((key) => {
                return(
                    <div key={key} className={classes.response}>
                        {data[key].message}
                    </div>
                )
            })
            :
            null
        }
        </>
    )
}

export default Response;