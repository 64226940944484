import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function Schedule() {

  const toast_id = "toast";
  const toast = useToast(); 
  const [scheduled, setScheduled] = useState([]);

  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = (id) => {
    setOpen(true);
    setDeleteId(id);
  }

  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  }

  const deleteClass = () => {

    axios.post(`${process.env.REACT_APP_SERVER}/class/deleteClass`, {
      email: JSON.parse(localStorage.getItem('user')).email,
      classId: deleteId
    })
    .then((res) => {
      handleClose();
      setUpdate(!update);
    })
    .catch((err) => {
      handleClose();
      if(!toast.isActive(toast_id)){
        toast({
          id: toast_id,
          title: "Delete Failed",
          position: "top-right",
          duration: 3000
        })
      }
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getScheduled`,{
      adminId: JSON.parse(localStorage.getItem('user'))._id
    })
    .then((res) => {
      setScheduled(res.data.classes);
    })
    .catch((err) => {
      toast({
        title:"Error in fetching the scheduled classes",
        isClosable: true,
        position:'top',
        status:"error"
      })
    })
  }, [update])

  return (
    <React.Fragment>
      <Container>
      <Title>Scheduled Classes</Title>
      <Table size="lg">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Teacher</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { typeof scheduled === 'undefined' ? null : scheduled.length !== 0  ?
            scheduled.map((notice) => {
            return (<TableRow key={notice._id}>
              <TableCell>{notice.name}</TableCell>
              <TableCell>{notice.startTime}</TableCell>
              <TableCell>{(notice.teacher || {}).name}</TableCell>
              <TableCell>{notice.subject}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => handleDelete(notice._id)} >Delete</Button>
              </TableCell>
            </TableRow>
            )
          }): null}
        </TableBody>
      </Table>
      </Container>
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete A Class</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this particular class ?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" style={{marginLeft: '10px'}} onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" style={{marginLeft: '10px'}} onClick={deleteClass}>Delete</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}