import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import ClassStart from './ClassStart';
import ClassEntry from './ClassEntry';
import ClassComponent from './ClassComponent';
import Login from './Others/login';
import Reg from './Others/registration';
import Form from './Others/form';
import Forgot from './Others/forgotpassword';
import OTP from './Others/otp';
import Preset from './Others/passwordReset';
import DashboardS from '../StudentPanel/Dashboard'
import DashboardT from '../TeacherPanel/Dashboard'
import Dashboard from '../AdminPanel/Dashboard'
import Homepage from '../HomePage';
import AddTea from '../AdminPanel/AddingTeacher'
import ClassSchedule from '../AdminPanel/ClassScheduling'
import TeacherList from '../AdminPanel/TeacherList';
import StudentList  from '../AdminPanel/StudentList';
import Pending from '../AdminPanel/PendingConfirmation';
import Rescheduling from '../AdminPanel/Rescheduling';
import GroupView from '../AdminPanel/GroupViewComponent';
import UploadAssignment from '../StudentPanel/UploadAssignment';
import CreateAssignment from '../TeacherPanel/UploadAssignment';
import SubmissionComponent from '../TeacherPanel/Submissions';
import { AdminRoute, TeacherRoute, StudentRoute } from './Authentication/ProtectedRoute';
import CreateGroup from '../AdminPanel/CreatingGroupComponent';
import Test from '../TeacherPanel/MCQTestComponent';
import TestComponent from '../StudentPanel/TestComponent';
import TestViewComponent from '../TeacherPanel/TestViewComponent';
import StudyMaterialComponent from '../TeacherPanel/StudyMaterialComponent';
import MaterialComponent from '../StudentPanel/MaterialComponent';
import VideoComponent from '../TeacherPanel/VideoComponent';
import VideoView from '../StudentPanel/VideoComponent';
import PdfAnnotator from '../TeacherPanel/AnnotatorComponent';
import AddSubjectComponent from '../AdminPanel/AddSubject';
import { SocketContext, socket} from '../helper/socketService';
import ResetLogin from "../AdminPanel/ResetLogin";

const AdminDashboard = () => {

    let { path } = useRouteMatch();
    
    return(
        <Switch>
            <AdminRoute exact path={path} component = {(props) => <Dashboard {...props}/>} />
            <AdminRoute exact path={`${path}/home`} component = {(props) => <Dashboard {...props}/> } />
            <AdminRoute exact path={`${path}/addTeacher`} component = {(props) => <AddTea {...props}/>} />
            <AdminRoute exact path={`${path}/scheduleClass`} component = {(props) => <ClassSchedule {...props}/>} />
            <AdminRoute exact path={`${path}/pending`} component = {(props) => <Pending {...props}/>} />
            <AdminRoute exact path={`${path}/teacherList`} component = {(props) => <TeacherList {...props}/>} />
            <AdminRoute exact path={`${path}/studentList`} component = {(props) => <StudentList {...props}/>} />
            <AdminRoute exact path={`${path}/rescheduleClass`} component = {(props) => <Rescheduling {...props}/> } />
            <AdminRoute exact path={`${path}/createGroup`} component = {(props) => <CreateGroup {...props}/> } />
            <AdminRoute exact path={`${path}/groups`} component={(props) => <GroupView {...props}/>} />
            <AdminRoute exact path={`${path}/addSubject`} component = {(props) => <AddSubjectComponent {...props} />} />
            <AdminRoute exact path={`${path}/resetLogin`} component = {(props) => <ResetLogin {...props} />} />
        </Switch>
    )
}

const TeacherDashboard = () => {

    let { path } = useRouteMatch();

    return(
        <Switch>
            <TeacherRoute exact path={path} component={(props) => <DashboardT {...props}/>} />
            <TeacherRoute exact path={`${path}/home`} component = {(props) => <DashboardT {...props}/> } />
            <TeacherRoute exact path={`${path}/yourClasses`} component = {(props) => <DashboardT {...props}/>} />
            <TeacherRoute exact path={`${path}/createAssignment`} component={(props) => <CreateAssignment {...props}/> } />
            <TeacherRoute exact path={`${path}/submittedAssignments`} component = {(props) => <SubmissionComponent {...props}/>} />
            <TeacherRoute exact path={`${path}/createTest`} component = {(props) => <Test {...props}/>} /> 
            <TeacherRoute exact path={`${path}/testView`} component = {(props) => <TestViewComponent {...props}/>} />
            <TeacherRoute exact path={`${path}/uploadMaterial`} component = {(props) => <StudyMaterialComponent {...props}/>} />
            <TeacherRoute exact path={`${path}/uploadVideo`} component = {(props) => <VideoComponent {...props}/>} />
            <TeacherRoute exact path={`${path}/pdfViewer/:assignment`} component = {(props) => <PdfAnnotator {...props}/>} />
        </Switch>
    )
}

const StudentDashboard = () => {
    let { path } = useRouteMatch();

    return(
        <Switch>
            <StudentRoute exact path={path} component = {(props) => <DashboardS {...props}/>} />
            <StudentRoute exact path={`${path}/home`} component = {(props) => <DashboardS {...props}/>} />
            <StudentRoute exact path={`${path}/scheduledClass`} component = {(props) => <DashboardS {...props}/>} />
            <StudentRoute exact path={`${path}/uploadAssignment`} component = {(props) => <UploadAssignment {...props}/> } />
            <StudentRoute exact path={`${path}/attemptTest`} component = {(props) => <TestComponent {...props}/>} />
            <StudentRoute exact path={`${path}/fetchMaterial`} component = {(props) => <MaterialComponent {...props}/>} />
            <StudentRoute exact path={`${path}/viewVideo`} component = {(props) => <VideoView {...props}/>} />
        </Switch>
    )
}

function Main(){

    return (
        <SocketContext.Provider value = {socket}> 
            <Switch>
                <Route path="/home" exact component= {Homepage}></Route>
                <Route path="/signIn" component= {(props) => <Login {...props} />} />
                <Route path="/forgotpassword" component= {Forgot}></Route>
                <Route path="/signUp" component= {Reg}></Route>
                <Route path='/register/:token' component={(props) => <OTP {...props} />} /> 
                <Route exact path="/additionalDetails/:idToken" component= {(props) => <Form {...props}/>} />
                <Route path="/reset" component= {Preset}></Route>
                <AdminRoute path="/admin" component = {() => <AdminDashboard />} />
                <TeacherRoute path="/teacher" component= {() => <TeacherDashboard />} />
                <StudentRoute path="/student" component={() => <StudentDashboard /> } />
                <Route path='/users/password/forgot' exact component={(props) => <Forgot {...props} />} />
                <Route path='/auth/password/reset/:token' exact component={(props) => <Preset {...props} />} />
                {/* <Route path='/classAttend/:meetId' exact component={(props) => <ClassEntry {...props} /> } />
                <Route path='/class/:meetId' exact component={(props) => <ClassStart {...props} /> } /> */}
                <Route path = '/class/:meetId' exact component = {(props) => <ClassComponent {...props} /> } />
                <Redirect to='/home' />
            </Switch>
        </SocketContext.Provider> 
    )
}

export default Main;