import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Title from './Title';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    quizHolder: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: '10px'
    },
    questions: {
        display: 'flex',
        width: '100%',
        marginBottom: '7px',
        flexDirection: 'column'
    },
    optionHolder:{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    quesMarks:{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}))

function Test(){

    const classes = useStyles();
    const toast = useToast();
    const toast_id = "toast_test"

    const [update, setUpdate] = useState(false);
    const [tests, setTests] = useState([]);
    const [attempted, setAttempted] = useState([]);

    const [open, setOpen] = useState(false);
    const [testId, setTestId] = useState("");
    const [test, setTest] = useState([]);
    const [answer, setAnswer] = useState([]);

    const [answerfetch, setAnswerFetch] = useState([]);
    const [dialogType, setDialogType] = useState("");

    const getAnswer = (id) => {
        axios.post(`${process.env.REACT_APP_SERVER}/test/getAnswer`, {
            testID: id
        })
        .then((res) => {
            setAnswerFetch(res.data.ques);

            setDialogType("answer");
            setOpen(true);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Error in fetching answers. Try again!",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }

    const handleOpen = (id) => {
        setDialogType("quiz");
        setOpen(true);
        setTestId(id);

        axios.post(`${process.env.REACT_APP_SERVER}/test/getQuestions`, {
            testId: id
        })
        .then((res) => {
            setTest(res.data.questions);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description:"Error in fetching the test",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }


    const handleClose = () => {
        setDialogType("");
        setOpen(false);
        setTestId("");
    }

    const handleChange = (event) => {
        setAnswer({...answer, [event.target.name]: event.target.checked})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDialogType("loading");

        axios.post(`${process.env.REACT_APP_SERVER}/test/getTestValidity`, {
            testId: testId, studentId: JSON.parse(localStorage.getItem('user'))._id, answer: answer,
            email: JSON.parse(localStorage.getItem('user')).email,
            name: JSON.parse(localStorage.getItem('user')).name
        })
        .then((res) => {
            handleClose();
            setUpdate(!update);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Error in submitting the quiz. Try again!",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/test/getScheduledTest`, {
            studentId: JSON.parse(localStorage.getItem('user'))._id,
            email: JSON.parse(localStorage.getItem('user')).email
        })
        .then((res) => {
            setTests(res.data.tests)
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Error in fetching tests",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })

        axios.post(`${process.env.REACT_APP_SERVER}/test/getAttemptedTest`, {
            email: JSON.parse(localStorage.getItem('user')).email,
            id: JSON.parse(localStorage.getItem('user'))._id
        })
        .then((res) => {
            setAttempted(res.data.attempted);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Error in fetching tests",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }, [update])

    return(
        <>
        <Title>Scheduled Tests</Title>
        <Table style={{marginBottom: '10px'}} size="large">
            <TableHead>
                <TableRow>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Scheduled By</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            { typeof tests === 'undefined' ? null : tests.length !== 0  ?
                tests.map((entity) => {
                return (<TableRow key={entity._id}>
                    <TableCell align="center">{entity.name}</TableCell>
                    <TableCell align="center">{(entity.teacher || {}).name}</TableCell>
                    <TableCell align="center">{entity.subject}</TableCell>
                    <TableCell align="center">{new Date(entity.testDate).toDateString()}</TableCell>
                    <TableCell align="center">
                        {entity.testStartTime}
                    </TableCell>
                    <TableCell>
                        {entity.testEndTime}
                    </TableCell>
                    <TableCell>{((new Date(`${entity.testDate} ${entity.testStartTime} `).getTime() - Date.now()) < 0 && (new Date(`${entity.testDate} ${entity.testEndTime}`).getTime() - Date.now()) > 0) ? 
                        <Button variant="contained" color="primary" onClick={e => handleOpen(entity._id)}>Active</Button>
                        :
                        <Button variant="contained" color="primary">Inactive</Button>
                    }</TableCell>
                </TableRow>)
            }): null}
            </TableBody>
        </Table>

        <Title>Attempted Tests</Title>
        <Table size="large">
            <TableHead>
                <TableRow>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Marks</TableCell>
                    <TableCell>Answers</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            { typeof attempted === 'undefined' ? null : attempted.length !== 0 ? 
                attempted.map((entity) => {
                    return (
                        <TableRow key={entity._id}>
                            <TableCell>{entity.name}</TableCell>
                            <TableCell>{entity.subject}</TableCell>
                            <TableCell>{entity.testDate}</TableCell>
                            {entity.attempted.map((element) => {
                                if(element.email === JSON.parse(localStorage.getItem('user')).email){
                                    return (<TableCell key={element._id}>{element.mark}/{element.total}</TableCell>)
                                }

                                return <></>
                            })}
                            <TableCell>
                                <Link style={{cursor: "pointer"}} onClick={() => getAnswer(entity._id)}><Visibility /></Link>
                            </TableCell>
                        </TableRow>
                    )
                })
            : null}
            </TableBody>
        </Table>

        <Dialog fullWidth open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown >
            { dialogType === "quiz" ? 
            <DialogContent>
                <Typography style={{fontWeight: '600', fontFamily: 'Nunito', fontSize: '20px'}}>Quiz Attempt Panel</Typography>
                Don't refresh the page or close this panel before submitting the test.
                <div className={classes.quizHolder}>
                    { typeof test === 'undefined' ? null : test.length !== 0  ?
                        test.map((test, index) => {
                            return (
                                <div className={classes.questions} key={index}>
                                    <div className={classes.quesMarks}>
                                        <div style={{fontWeight: '500', fontFamily: 'Nunito', fontSize: '15px'}}>
                                            Question {index + 1}{": "}{test.question}
                                        </div>
                                        <div style={{fontWeight: '500', fontFamily: 'Nunito', fontSize: '15px'}}>
                                            {test.marks + " "}Marks
                                        </div>
                                    </div>
                                    <div>
                                    {
                                        test.options.map((option, index) => {
                                            let n = test._id + " " + index;
                                            return (
                                                <div className={classes.optionHolder} key={index}>
                                                    <Checkbox checked={answer.n} name={n} onChange={handleChange}/>
                                                    <Typography>{option.option}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            )
                        })
                        :
                        null
                    }   
                </div>
                <Button variant="contained" color="primary" style={{marginRight: '5px'}} onClick={handleSubmit}>Submit Test</Button>
                <Button variant="contained" color="primary" onClick={handleClose}>Close Quiz</Button>
            </DialogContent>
            :
            dialogType === "loading" ? 
            <DialogContent>
                <CircularProgress />
            </DialogContent>
            :
            dialogType === "answer" ?
            <DialogContent>
                <Typography style={{fontWeight: '600', fontFamily: 'Nunito', fontSize: '20px'}}>Quiz Correct Answer View</Typography>
                <div className={classes.quizHolder}>
                    {typeof answerfetch === 'undefined' ? null : answerfetch.length !== 0 ?
                        answerfetch.map((ans, index) => {
                            return (
                                <div className={classes.questions} key={index}>
                                    <div className={classes.quesMarks}>
                                        <div style={{fontWeight: '500', fontFamily: 'Nunito', fontSize: '15px'}}>
                                            Question {index + 1}{": "}{ans.question}
                                        </div>
                                        <div style={{fontWeight: '500', fontFamily: 'Nunito', fontSize: '15px'}}>
                                            {ans.marks + " "}Marks
                                        </div>
                                    </div>
                                    {
                                        ans.options.map((option, index) => {
                                            return (
                                                <div className={classes.optionHolder} key={index}>
                                                    <Checkbox checked={option.answer} />
                                                    <Typography>{option.option}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                        :
                        null
                    }
                </div>
            </DialogContent>    
            :
            null
            }
        </Dialog>
        </>
    )
}

export default Test

//onClosing the test attempt panel the useEffect will be trigerred fetching the details from the server and storing
