import React, { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" >
      PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8,4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
 
}));

export default function SignUp() {
  const classes = useStyles();
  const toast = useToast();

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const sendTeacher = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/user/addTeacher`, {
      adminId: JSON.parse(localStorage.getItem("user")).email, name: name, email: email, subject: subject, password: password
    })
    .then((res) => {

      setName("")
      setSubject("")
      setEmail("")
      setPassword("")
      
      toast({
        title: "Teacher Added Successfully",
        position: "top",
        isClosable: true,
        status:"success"
      })
    })
    .catch((err) => {
      toast({
        title: "Error Occured",
        position: "top",
        status: "error"
      })
    })
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AssignmentIndIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Adding a New Teacher
        </Typography>
        <form className={classes.form} onSubmit={sendTeacher}>
          <Grid container spacing={2}>
         
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={name}
                onInput={ e => setName(e.target.value)}
                autoComplete=""
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="Subject"
                label="Subject"
                name="subject"
                value={subject}
                onInput={e => setSubject(e.target.value)}
                autoComplete=""
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                type="email"
                id="email"
                label="E-mail"
                name="email"
                value={email}
                onInput={e => setEmail(e.target.value)}
                autoComplete=""
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Generate Password"
                name="password"
                type="password"
                value={password}
                onInput= {e => setPassword(e.target.value)}
                autoComplete=""
              />
            </Grid>
            
            <Grid item xs={12}>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Add
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}