import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
    Section
} from './FooterComponent.js';

function Footer(){

    return(
        <Section>
            <Typography variant="h6" color="textSecondary" align="center">
                {'Copyright © '}
                PCP
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            {/* <Typography align="center">
                Genus is an AI-powered video-conferencing solution developed by Codebugged AI. It lets users connect in a better online set-up and synchronize their ideas effectively.
            </Typography> */}
        </Section>
    )
}   

export default Footer;