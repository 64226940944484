import { combineReducers } from "redux";
import classes from "./classes";
import me from "./me";
import producers from "./producers";
import dataProducers from "./dataProducers";
import peers from "./peers";
import consumers from "./consumers";
import dataConsumers from "./dataConsumers";
import notifications from "./notifications";
import message from './messages';
import response from './response';

const reducers = combineReducers({
  classes,
  me,
  producers,
  dataProducers,
  peers,
  consumers,
  dataConsumers,
  notifications,
  message,
  response
});

export default reducers;
