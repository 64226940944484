import React, {useState, useEffect} from 'react';
import Title from './Title';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '@material-ui/core/Container';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import moment from "moment";

export default function Schedule() {

  const toast = useToast();
  const toast_id = "toast_id";
  const [update, setUpdate] = useState(false);

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/video/getUploadedVideo`,{
      studentId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      var showVideos = [];

      res.data.videos.forEach((item) => {
        var now = moment().format('YYYY-MM-DD');
        var inDateRange = moment(now).isBetween(item.issueStartDate, item.issueEndDate, undefined, []);

        var time = moment().format('HH:mm');
        var inTimeRange = moment().isBetween(moment(item.startTime, 'HH:mm'), moment(item.endTime, 'HH:mm'), undefined, []);
        console.log(time, inTimeRange);

        (inDateRange && inTimeRange) && showVideos.push(item);
      })
      setVideos(showVideos);
    })
    .catch((err) => {
        if(!toast.isActive(toast_id)){
            toast({
                title: "Error In Fetching Videos",
                duration: 3000,
                isClosable: true,
                status: "error",
                position: "top"
            })
        }
    })
  }, [update])

  return (
    <Container>
        { typeof videos === 'undefined' ? null : videos.length !== 0  ?
            videos.map((entity) => {
              return (
                <div key={entity._id} style={{padding: '5px',}}>
                  <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                      <Title style={{fontFamily: 'Nunito'}}>Name: {entity.name}</Title>
                      <Title style={{fontSize: '15px', fontFamily: 'Nunito'}}>Teacher: {(entity.uploadedBy || {}).name}</Title>
                  </div>
                  <div style={{width: '100%', height: '400px', position: 'relative', display: 'flex', justifyContent: 'center'}}>
                      <iframe src={`${entity.link}`}   width="85%" height="400" frameBorder="0" scrolling="no" seamless=""></iframe>
                      <div style={{width: '150px', height: '150px', position: 'absolute', opacity: '0', right: '0', top: '0px'}}>&nbsp;</div>
                  </div>
                </div>
              )
          })
          :
            null
        }
    </Container>
  );
}