import jsCookie from "js-cookie";

const USER_COOKIE = "mediasoup-demo.user";
const DEVICES_COOKIE = "mediasoup-demo.devices";
const MIC_STATUS = "mediasoup-demo.mic";

export function getUser() {
  return jsCookie.getJSON(USER_COOKIE);
}

export function setUser({ displayName }) {
  jsCookie.set(USER_COOKIE, { displayName });
}

export function getDevices() {
  return jsCookie.getJSON(DEVICES_COOKIE);
}

export function setDevices({ webcamEnabled }) {
  jsCookie.set(DEVICES_COOKIE, { webcamEnabled });
}

export function getMicStatus() {
  return jsCookie.getJSON(MIC_STATUS);
}

export function setMicStatus({ micEnabled}) {
  jsCookie.set(MIC_STATUS, { micEnabled });
}