import React from 'react';
import {
    Section,
    Holder,
    ImageHolder,
    TextHolder,
    TextTitle,
    TextDesc,
    TextQues,
    TextAns
} from './AboutComponent.js';

function About(){

    return(
        <>
            <Section id="about">
                <Holder>
                    <ImageHolder src="img/about-2.png" alt="Logo"></ImageHolder>
                </Holder>
            </Section>
        </>
    )
}

export default About;