import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Center, Button, ButtonGroup, Box as B, useToast } from '@chakra-ui/react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { mainListItems } from './Menu';
import { Link as L} from 'react-router-dom';
import { signout } from '../helper/auth';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { removeLocalStorage, removeCookie } from '../helper/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" href="/">
        PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {''}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Schedule({history}){

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signout_here = () => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/removeActiveState`, {
      id: JSON.parse(localStorage.getItem('user'))._id, 
      role: JSON.parse(localStorage.getItem('user')).role
    })
    .then((res) => {
        removeCookie('token')
        removeLocalStorage('user')
        history.push('/home')
    })
    .catch((err) => {
        alert("Signout Failed");
    })
  } 

  const [pending, setPending] = useState([]);
  const [update, setUpdate] = useState(false);
  const [functions, setFunctions] = useState(null);
  const [n, setN] = useState(0);

  const toast = useToast();

  const [groups, setGroups] = useState([]);

  const [openD, setOpenD] = useState(false);

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleClose = () => {
    setOpenD(false);
  };

  const [selected, setSelected] = useState([]);
  var selectedGroups = useRef([]);

  const PermissionModal = (id, n ,type) => {
    handleClickOpen();
    setN(n);
    type === "grant" ? 
    setFunctions(() => () => reqResponse(id, "Granted"))
    :
    setFunctions(() => () => reqResponse(id, "Rejected"))
  }

  const handleChange = (e) => {
    setSelected(e.target.value);
    selectedGroups.current = e.target.value;
  }

  const reqResponse = (id, response) =>{
    axios.post(`${process.env.REACT_APP_SERVER}/user/responseRegister`, {
      adminId: JSON.parse(localStorage.getItem('user')).email, userId: id, response: response === "Granted" ? 1 : response === "Rejected" ? 0 : null, group: selectedGroups.current
    })
    .then((res) => {
        setUpdate(!update);
        handleClose();
        toast({
          title: "Response Registered",
          status: "success"
        })
    })
    .catch(err => {
        
        toast({
          title: "Error Occured",
          status: "error"
        })
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/group/getExisting`, {
      adminId: JSON.parse(localStorage.getItem('user')).email
    })
    .then((ress) => {
      setGroups(ress.data.group);

      axios.post(`${process.env.REACT_APP_SERVER}/user/getWaiting`, {
        adminId: JSON.parse(localStorage.getItem("user")).email
      })
      .then((res) => {
        let data = [];

        res.data.pending.forEach((entity) => {
          data.push({
            "name": entity.name,
            "father": entity.fatherName,
            "mother": entity.motherName,
            "email": entity.email,
            "dob": new Date(entity.dob).toDateString(),
            "standard": entity.standard,
            "college": entity.college,
            "subject": entity.subject,
            "action": 
              <ButtonGroup>
                <Button colorScheme="purple" onClick={() => PermissionModal(entity._id, 1, "grant")}>Accept Request</Button>
                <Button colorScheme="purple" onClick={() => PermissionModal(entity._id, 2 ,"reject")}>Reject Request</Button>
              </ButtonGroup>
          })
        })
        setPending(data);
      })
      .catch(err => {
        toast({
          title: "Error In Fetching The Pending Registration Requests"
        })
      })
    })
    .catch((err) => {
      alert("Fetching of existing group details failed!");
    })
  }, [update])

  return (
    <>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherited"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            ADMIN PANEL
          </Typography>
          Log Out
          <IconButton  color="inherit" onClick={() => {signout()}}>
            <L to="/home">
              < ExitToApp/>
            </L>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Schdedule */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
      {pending.length > 0 ?
        <MaterialTable
          columns={[
            {title: 'Name', field: "name"},
            {title: 'Father Name', field: "father"},
            {title: 'Mother Name', field: "mother"},
            {title: 'E-mail', field: "email"},
            {title: 'DOB', field: "dob"},
            {title: 'Standard', field: "standard"},
            {title: 'Institution', field: "college"},
            {title: 'Subject', field: "subject"},
            {title: 'Delete', field: "action"},
          ]}
          data = {pending}
          title = "Student List"
        />
      :
      <B p={5}> 
      <Center>
        No records to display.
      </Center>
      </B>}

      <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openD} onClose={handleClose}>
                  {n === 1 ? <>
                  <DialogTitle>Select The Groups</DialogTitle>
                  <DialogContent>
                    <form className={classes.container}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="demo-dialog-select-label">Groups</InputLabel>
                        <Select
                          labelId="demo-dialog-select-label"
                          id="demo-dialog-select"
                          value={selected}
                          multiple
                          onChange={handleChange}
                          input={<Input />}
                        >
                          {groups.map((group) => (
                            <MenuItem key={group._id} value={group._id}>
                              {group.name}
                            </MenuItem>
                          )
                          )}
                        </Select>
                      </FormControl>
                    </form>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={functions} color="primary">
                      Ok
                    </Button>
                  </DialogActions>
                  </> 
                  : 
                  n === 2 ? 
                  <>
                  <DialogTitle>Delete Registration Request</DialogTitle>
                  <DialogContent>
                      Are you sure you want to reject this registration request?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={functions} color="primary">
                      Ok
                    </Button>
                  </DialogActions>
                  </>
                  :
                  null
                }
                </Dialog>
      </Paper>
            </Grid>
          </Grid>
          <Box pt={2}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
    </>
  );
}