import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useToast } from '@chakra-ui/react';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Visibility from '@material-ui/icons/Visibility';
import Link from '@material-ui/core/Link';
import Title from './Title';
import axios from 'axios';

import MaterialTable from "material-table";

export default function Submission(){

    const toast = useToast();
    const toastId = "toast_id";
    const [update, setUpdate] = useState(false);

    const [assignmentData, setAssignmentData] = useState([]);
    const [assignment, setAssignment] = useState([]);

    const download = (url, name, email, assign) => {
        window.open(`${process.env.REACT_APP_SERVER}/class/assignments/${url}/${name}_${email}_${assign}`);
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/class/getAssigned`, {
            teacherId: JSON.parse(localStorage.getItem('user'))._id
        })
        .then((res) => {
            var tempAssignment = [];
            res.data.assignments.forEach((item) => {
                tempAssignment.push({
                    id: item._id,
                    name: item.name,
                    dueDate: new Date(item.dueDate).toLocaleDateString('en-GB'),
                    assignedTo: `Students of ${item.assignedTo.name}`,
                    status: `${item.submissions.length}/${item.students.length}`
                })
            })

            setAssignmentData(tempAssignment);
            setAssignment(res.data.assignments);
        })
        .catch((err) => {
            if(!toast.isActive(toastId)){
                toast({
                    id: toastId,
                    title: "Error In Fetching the Assignments",
                    status: "error",
                    position: "top",
                    duration:"2000",
                    isClosable: true
                })
            }
        })
    }, [update])

    return (
        <> 
        <Container style={{marginBottom: "20px"}}>
            <MaterialTable 
                title="Created Assignments"
                columns={[
                    {title: "Name", field: "name"},
                    {title: "Due Date", field: "dueDate"},
                    {title: "Assigned To", field: "assignedTo"},
                    {title: "Status", field: "status"}
                ]}
                data={assignmentData}
                editable={{
                    onRowDelete : (oldData) => {
                        return new Promise((resolve, reject) => {
                            axios.post(`${process.env.REACT_APP_SERVER}/class/deleteAssignment`, {
                                id: oldData.id
                            })
                            .then((res) => {
                                resolve();
                                setUpdate(!update);
                            })
                            .catch((err) => {
                                if(!toast.isActive(toastId)){
                                    toast({
                                        id: toastId,
                                        title: "Error In Deleting the Assignments",
                                        status: "error",
                                        position: "top",
                                        duration:"2000",
                                        isClosable: true
                                    })
                                }
                                reject();
                            })
                        })
                    }
                }}
            />
        </Container>
        {typeof assignment === 'undefined' ? null : assignment.length  !== 0 ? 
            assignment.map((assign) => {
                return (
                    <Container key={assign._id} style={{marginBottom: "20px"}}>
                        <Title>{assign.name}</Title>
                        <Table size="large">
                            <TableHead>
                                <TableCell>Name Of Student</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>View</TableCell>
                                <TableCell>Download</TableCell>
                            </TableHead>
                            <TableBody>
                            {assign.submissions.map((sub) => {
                                return (
                                    <TableRow key={sub._id}>
                                        <TableCell>{sub.name}</TableCell>
                                        <TableCell>{sub.email}</TableCell>
                                        <TableCell>
                                            {
                                                new Date(`${assign.dueDate.split("T")[0]} ${assign.dueTime}`).getTime() - new Date(sub.uploadedDate).getTime() > 0 ? "On Time" : "Late"
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Link style={{cursor: "pointer"}} href = {`/teacher/pdfViewer/${sub._id}`}><Visibility /></Link>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link style={{cursor: "pointer"}} onClick={() => download(`${sub.uploaded}`, `${sub.name}`, `${sub.email}`, `${assign.name}`)}><GetAppRoundedIcon /></Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </Container>
                )
            })
            :null
        }
        </>
    )
}