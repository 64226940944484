import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Timer from '@material-ui/icons/Timer';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/AvTimer';
import LayersIcon from '@material-ui/icons/Layers';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <Link to="/admin/home">
      <ListItem button>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>
    <Link to="/admin/scheduleClass">
    <ListItem button>
      <ListItemIcon>
        <Timer />
      </ListItemIcon>
      <ListItemText primary="Schedule a class" />
    </ListItem>
    </Link>
    <Link to="/admin/rescheduleClass">
      <ListItem button>
        <ListItemIcon>
          <Timer />
        </ListItemIcon>
        <ListItemText primary="Reschedule Class" />
      </ListItem>
    </Link>
    <Link to="/admin/addTeacher">
    <ListItem button>
      <ListItemIcon>
        <AssignmentInd />
      </ListItemIcon>
      <ListItemText primary="Add a Teacher" />
    </ListItem>
    </Link>
    <Link to="/admin/addSubject">
    <ListItem button>
      <ListItemIcon>
        <ListAltIcon />
      </ListItemIcon>
      <ListItemText primary="Add a Subject" />
    </ListItem>
    </Link>
    <Link to="/admin/createGroup">
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Create Groups" />
    </ListItem>
    </Link>
    <Link to="/admin/Pending">
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Pending Confirmation" />
    </ListItem>
    </Link>
    <Link to="/admin/resetLogin">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Reset Login" />
      </ListItem>
    </Link>
    <Link to="/admin/groups">
    <ListItem button>
      <ListItemIcon>
        <ListAltIcon />
      </ListItemIcon>
      <ListItemText primary="Group List" />
    </ListItem>
    </Link>
    <Link to="/admin/studentList">
    <ListItem button>
      <ListItemIcon>
        <ListAltIcon />
      </ListItemIcon>
      <ListItemText primary="Student's List" />
    </ListItem>
    </Link>
    <Link to="/admin/teacherList">
    <ListItem button>
      <ListItemIcon>
        <ListAltIcon />
      </ListItemIcon>
      <ListItemText primary="Teacher's List" />
    </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>

  </div>
);