import React from 'react';
import {
    Section,
    TextContainer,
    TextTitle,
    TextPara
} from './introComponent.js';
import Button from '@material-ui/core/Button';

function Intro({introData}) {

    return (
        <>
            <Section>
                <TextContainer>
                    <TextTitle><u>{introData.title}</u></TextTitle>
                    <TextPara>{introData.description}</TextPara>
                    <Button size="large" color="secondary" variant="contained" style={{marginTop: "20px", textDecoration: "none"}} href="/signIn">{introData.buttonName}</Button>
                </TextContainer>
            </Section>
        </>
    )
}

export default Intro;