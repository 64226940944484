import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useToast, Stack, Box, Heading, List, ListItem, ListIcon, Center, Button, Tooltip } from '@chakra-ui/react';
import { Grid, AppBar, Toolbar, Typography, IconButton, CircularProgress} from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { MdCheckCircle } from 'react-icons/md'; 
import { BsFillMicFill } from 'react-icons/bs';
import { BiMicrophoneOff, BiCameraOff, BiCamera } from 'react-icons/bi';
import { withRoomContext } from '../../../helper/roomContext';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { removeLocalStorage, removeCookie } from '../../../helper/auth';
import * as cookiesManager from "../stateManagers/cookiesManager";

import {
    Section,
    ClassDetailContainer,
    ClassDetailWrapper,
    ButtonJoin,
    ClassStartText,
    ClassTopic,
    ClassDetail,
    ClassTextWrapper,
    ClassBtnWrapper,
    InstructionContainer,
    InstructionTopic,
    Instructions,
    JoinPara,
    VideoContainer,
    ButtonHolder,
    VideoHolder
} from './ClassInitiateComponents.js';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'white',
      height: '60px',
    },
    toolbar: {
      paddingRight: 8,

    },
    title: {
        flexGrow: 1,
        fontFamily: 'Nunito'
    }, 
    main: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        height: '80%'
    }
}))

function ClassLayout(props) {

    const {
        classId, classClient, classes, me, amActiveSpeaker, history
    } = props;

    const [realClass, setRealClass] = useState("");
    const [camera, setCamera] = useState(false);
    const [mic, setMic] = useState(false);
    const [camDisabled, setCamDisabled] = useState(true);
    const [micDisabled, setMicDisabled] = useState(true);

    const streamRef = useRef();

    // setting the state on promise resolution.
    classClient.then((res) => {
        setRealClass(res);
    })

    const classesStyle = useStyles();
    const toast = useToast();
    const toast_id = "toast_id";
    const role = me.type;

    const [meetData, setMeetData] = useState([]);
    const [good, setGood] = useState(false);
    const [test, setTest] = useState(false);

    const signout_here = () => {
        axios.post(`${process.env.REACT_APP_SERVER}/auth/removeActiveState`, {
          id: JSON.parse(localStorage.getItem('user'))._id, 
          role: JSON.parse(localStorage.getItem('user')).role
        })
        .then((res) => {
            removeCookie('token')
            removeLocalStorage('user')
            window.location.href = `${process.env.REACT_APP_CLIENT}/home`
        })
        .catch((err) => {
            alert("Signout Failed");
        })
    }

    const stopVideo = () => {
        setCamera(false);

        let streamVideo = streamRef.current;
        if(streamVideo && streamVideo.srcObject !== null ){
            let trackArray = streamVideo.srcObject.getVideoTracks();

            trackArray.forEach((track) => {
                if(track.enabled){
                    track.stop();
                }
            })
        }

        streamRef.current.srcObject = null;
    }
    const startVideo = () => {
        setCamDisabled(true);
    
        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        })
        .then((stream) => {
          streamRef.current.srcObject = stream;
          streamRef.current.play();
    
          setCamera(true);
          setCamDisabled(false);
        })
        .catch((err) => {
          alert("Camera Access Error!");
        })
    }

    const muteMic = () => {
        streamRef.current.muted = true;
        setMic(false);
    }
    const unmuteMic = () => {
        streamRef.current.muted = false;
        setMic(true);
    }

    // customize the useEffect to handle both the teacher and student calls
    useEffect(() => {
        let deviceSupport = { 
            videoSupport : false,
            audioSupport : false
        }

        navigator.mediaDevices.enumerateDevices().then((allDevices) => {
            allDevices.forEach((device) => {
                if(device.kind === "audioinput"){
                    deviceSupport.audioSupport = true;
                }
                if(device.kind === "videoinput"){
                    deviceSupport.videoSupport = true;
                }
            })

            for(var key in deviceSupport){
                if(key === "audioSupport" && !deviceSupport[key]){
                    setMicDisabled(true);
                    toast({
                        description: "Audio Input Device Not Found",
                        position: "top-right",
                        duration: 3000
                    })
                } else if(key === "videoSupport" && !deviceSupport[key]) {
                    setCamDisabled(true);
                    toast({
                        description: "Video Input Device Not Found",
                        position: "top-right",
                        duration: 3000
                    })
                } else {
                    navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: true
                    })
                    .then((stream) => {
                        setCamera("on");
                        setMic("on");
                        setMicDisabled(false);
                        setCamDisabled(false);

                        cookiesManager.setDevices({webcamEnabled: true});
                        cookiesManager.setMicStatus({micEnabled: true});
            
                        streamRef.current.srcObject = stream;
                    })  
                    .catch((err) => {
                        console.log(err);
                        setMicDisabled(true);
                        setCamDisabled(true);

                        if(!toast.isActive(toast_id)) {
                            toast({
                                id: toast_id,
                                description: "Error in accessing media devices",
                                duration: 3000,
                                position: "top-right"
                            })
                        }
                    })
                }
            }
        })
    }, [])
    
    useEffect (() => {
        
        const userRole = JSON.parse(localStorage.getItem('user')).role;

        if(userRole === "teacher"){
            axios.post(`${process.env.REACT_APP_SERVER}/class/getClassDetails`,{
                meetId: classId
            })
            .then((res) => {
                setMeetData(res.data.meet);
                setTimeout(() => {
                    setTest(true);
                }, 2000);
            })
            .catch(err => {
                if(!toast.isActive(toast_id)){
                    toast({
                        id: toast_id,
                        description: "Please try again later",
                        status: "error",
                        duration: 3000,
                        isClosable: true
                    })
                }
            })
        } else if(userRole === "student") {
            axios.post(`${process.env.REACT_APP_SERVER}/class/checkInvitation`,{
                userId: JSON.parse(localStorage.getItem('user'))._id,
                meetId: classId
            })
            .then((res) => {
                if(res.data.found && res.data.invited){
                    setGood(true);
                    setTimeout(() => {
                        setTest(true);
                    }, 2000);
                } else {
                    if(!toast.isActive(toast_id)){
                        toast({
                            id: toast_id,
                            description: res.data.message,
                            status: "error",
                            duration: 2000,
                            position: "top-right"
                        })
                    }
                }
            })
            .catch(err => {

                if(!toast.isActive(toast_id)){
                    toast({
                        id: toast_id,
                        title: "Error Occured.",
                        description: "If the error persists, please inform the admin",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                }
            })
        } else {}
    }, [])

    return (
        <>
        <AppBar className={clsx(classesStyle.appBar)} style={{backgroundColor: 'white'}}>
            <Toolbar className={classesStyle.toolbar}>
                <Typography component="h1" variant="h6" style={{color: "black"}} noWrap className = { classesStyle.title}>
                    PCP
                </Typography>
                <IconButton onClick={() => {signout_here()}}>
                    <ExitToApp />
                </IconButton>
            </Toolbar>
        </AppBar>
        <Section>
            <Grid container spacing = {3} className={classesStyle.main} style={{paddingTop: '75px'}}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <VideoContainer>
                        <Box style={{ backgroundColor: '#1E232F', borderRadius: "50px", overflow: "hidden"}} w={"90%"} h={"100%"}>
                            <video ref={streamRef} style={{    
                                width: '100%',
                                borderRadius: '50px',
                                transform: 'scaleX(-1)',
                                pointerEvents: 'none'
                            }}  
                                width = "100%"
                                autoPlay
                                playsInline
                            ></video>
                        </Box>
                        <Center w={"100%"}>
                            <ButtonHolder join={true}>
                                <Tooltip label="Audio Control" bg="white" color="purple.600">
                                    <Button style={{backgroundColor: "#1E232F", color: "white"}} id="stopAudio" mr={3} w={"100%"} 
                                        disabled={micDisabled}
                                        onClick={mic ? () => {
                                                cookiesManager.setMicStatus({ micEnabled: false });
                                                muteMic();
                                            }
                                            :
                                            () => {
                                                cookiesManager.setMicStatus({ micEnabled: true });
                                                unmuteMic();
                                            }
                                        }
                                    >
                                        <BsFillMicFill id="unmute" style={{display: !mic ? "none" : "block"}} 
                                        />
                                        <BiMicrophoneOff id="mute" style={{display: mic ? "none" : "block"}}
                                        />
                                    </Button> 
                                </Tooltip>
                                <Tooltip label="Video Control" bg="white" color="purple.600" >
                                    <Button style={{backgroundColor: "#1E232F", color: "white"}} id="stopVideo" mr={3} w={"100%"} 
                                        disabled={camDisabled}
                                        onClick={ camera ? () => {
                                                cookiesManager.setDevices({ webcamEnabled: false });
                                                stopVideo();
                                            }
                                            :
                                            () => {
                                                cookiesManager.setDevices({ webcamEnabled: true });
                                                startVideo();
                                            }
                                        }
                                    >
                                        <BiCamera id="cameron" style={{display: !camera ? "none" : "block"}}
                                        />
                                        <BiCameraOff id="cameraoff" style={{display: camera ? "none" : "block"}} />
                                    </Button>
                                </Tooltip>
                            </ButtonHolder>
                        </Center>
                    </VideoContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    { role === "teacher" ? 
                    <ClassDetailContainer>
                        {
                            meetData.length !== 0 ?
                                meetData.map((meet) => {
                                return (
                                    <ClassDetailWrapper key={meet.meetId}>
                                        <ClassTopic>{meet.name}</ClassTopic>
                                        <ClassTextWrapper>
                                            <ClassDetail>Class ID:  {meet.meetId}</ClassDetail>
                                            <ClassDetail>Class Time: {meet.startTime}</ClassDetail>
                                            <ClassDetail>Subject: {meet.subject}</ClassDetail>
                                        </ClassTextWrapper>
                                        <ClassStartText>Ready to start the class?</ClassStartText>
                                        <ClassBtnWrapper>
                                            <ButtonJoin big={true} onClick={test ? () => {realClass.startClass({history, toast, toast_id}); stopVideo();} : null}>
                                                {classes.state !== "starting" ? "Start The Class" : <CircularProgress style={{color: "white"}} />}
                                            </ButtonJoin>
                                        </ClassBtnWrapper>
                                    </ClassDetailWrapper>
                                )}
                            )
                        : 
                            <Stack p={3}>
                                <Heading style={{textAlign: 'center', marginBottom: '20px'}}>Class Details</Heading>
                                <Box fontSize={["18px", "22px"]} fontFamily="Georgia" >
                                    The class with the given Id was not found. Please make sure that the class ID is correct. If this is an unusual error, please try refreshing the page. If the error persists, please contact the admin.
                                </Box>    
                            </Stack>
                        }
                    </ClassDetailContainer>                        
                    :
                    role === "student" ?
                    <InstructionContainer>
                        <InstructionTopic>
                            Instructions
                        </InstructionTopic>
                        <Instructions>
                            <List spacing={2}>
                                <ListItem fontSize="14px" > 
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    You cannot miss even a single class, however if it happens make a recovery by viewing Repeat Telecast Class.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    You have to complete the homework on a regular basis.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    If you have any problem or doubt with the concept, you should raise your hand immediately.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    If you have any problem while attending the class, you must inform us once the class is over.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Before attending the class make sure that you have a proper Internet connection.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Sign in to the class website at least 10 minutes before the class time.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />  
                                    You must keep your video ON while attending the class.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Student should have a very good practice of mute and unmute of audio.
                                </ListItem>
                                <ListItem fontSize="14px" > 
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Students must sit on table and chair at home with full attention and in zero disturbance surrounding alike they sit in classroom.
                                </ListItem> 
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Don't share class link with any outsider this is only for you.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Recording of this class is strictly prohibited in any form. Kindly refrain yourself from indulging in such activities.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Please note down all the material and questions provided to you in your notebook positively.
                                </ListItem>
                                <ListItem fontSize="14px" >
                                    <ListIcon as={MdCheckCircle} color="purple.600" />
                                    Student should maintain the decorum of the class.
                                </ListItem>
                            </List>
                        </Instructions>
                        <JoinPara>Ready to join the class?</JoinPara>
                        <ButtonJoin id="joinBtn" disabled={!good || !test} onClick={() => {
                            stopVideo();
                            realClass.enterClass({history, toast, toast_id}); 
                        }}>
                            {classes.state !== "entering" ? "Join Class" : <CircularProgress style={{color: "white"}} />}
                        </ButtonJoin>
                    </InstructionContainer>
                    :
                    null
                    }
                </Grid>
            </Grid>
        </Section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        classes: state.classes,
        me: state.me,
        amActiveSpeaker: state.me.id === state.classes.amActiveSpeakerId
    }
}

const Class = withRoomContext(
    connect(mapStateToProps, null)(ClassLayout)
);

export default Class;