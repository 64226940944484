import io from "socket.io-client";
import Peer from 'simple-peer';
import { sessionStorageSet, sessionStorageClearage } from '../../helper/auth';

const wrtc = require('wrtc');
// const socket = io(`${process.env.REACT_APP_SERVER}`);

let socket;
var yourId;
var roomId;

window.onload = function () {
    var reloadParam = sessionStorage.getItem("reloadingS");
    if(reloadParam){
        var c = sessionStorage.getItem("classId");
        var i = sessionStorage.getItem("inClass");
        var y = sessionStorage.getItem("yourOwnId");
        var n = sessionStorage.getItem("name");

        socket.emit("leaveClass", c, y, i, n);
        sessionStorageClearage(["reloadingS", "classId", "inClass", "yourOwnId", "name"]);
    }
}

const createLocalStream = (video, stream) => {
    video.srcObject = stream;
    video.muted = true;
    video.addEventListener("loadedmetadata", () => {
        video.play();
    });
};

const stopLocalVideo = (trackArray) =>{
    const stopVideo = document.getElementById("stopVideo");

    if(stopVideo){
        stopVideo.addEventListener("click", () => {

            if(trackArray[0].enabled){

                trackArray[0].enabled = false;
                document.getElementById("stopVideo").innerText = "Camera Off";
            }else {
                trackArray[0].enabled = true;
                document.getElementById("stopVideo").innerText = "Camera On";
            }
        })
    }
}

const stopLocalAudio = (trackArray) => {

    const stopAudio = document.getElementById("stopAudio");

    if(stopAudio){
        stopAudio.addEventListener("click", () => {

            if(trackArray[0].enabled){

                trackArray[0].enabled = false;
                document.getElementById("stopAudio").innerText = "Mic Off";
            }else {
                trackArray[0].enabled = true;
                document.getElementById("stopAudio").innerText = "Mic On";
            }
        })
    }
}

const getTeacherId = (stream) => {
    socket.on("youJoined", (teacherId) => { 
        
        sessionStorageSet([["reloadingS", true], ["inClass", true], ["classId", roomId], ["yourOwnId", yourId], ["name", JSON.parse(localStorage.getItem('user')).name]]);
        document.getElementById("studentJoin").style.display = "none";
        document.getElementById("studentInterface").style.display = "flex";

        stopClassAudio(stream.getAudioTracks());
        stopClassVideo(stream.getVideoTracks());
        forcedMute(stream.getAudioTracks());

        checkVideo(stream.getVideoTracks());
        checkAudio(stream.getAudioTracks());

        studentLeave();
    });
}

const checkVideo = (trackArray) => {
    if(trackArray[0].enabled){
        document.getElementById("stopCameraIcon").style.display = "none";
        document.getElementById("startCameraIcon").style.display = "block";
    } else {
        document.getElementById("stopCameraIcon").style.display = "block";
        document.getElementById("startCameraIcon").style.display = "none";
    }
}

const checkAudio = (trackArray) => {
    if(trackArray[0].enabled){
        document.getElementById("stopAudioIcon").style.display = "none";
        document.getElementById("startAudioIcon").style.display = "block";
    } else {
        document.getElementById("stopAudioIcon").style.display = "block";
        document.getElementById("startAudioIcon").style.display = "none";
    }
}


const stopClassAudio = (trackArray) => {
    const stopAudioIcon = document.getElementById("stopAudioIconI");

    if(stopAudioIcon){
        stopAudioIcon.addEventListener("click", () => {

            if(trackArray[0].enabled){

                trackArray[0].enabled = false;
                document.getElementById("stopAudioIcon").style.display = "block";
                document.getElementById("startAudioIcon").style.display = "none";
            }else {
                trackArray[0].enabled = true;
                document.getElementById("stopAudioIcon").style.display = "none";
                document.getElementById("startAudioIcon").style.display = "block";
            }
        })
    }
}

const stopClassVideo = (trackArray) => {
    const stopAudioIcon = document.getElementById("stopVideoIconI");

    if(stopAudioIcon){
        stopAudioIcon.addEventListener("click", () => {

            if(trackArray[0].enabled){

                trackArray[0].enabled = false;
                document.getElementById("stopCameraIcon").style.display = "block";
                document.getElementById("startCameraIcon").style.display = "none";
            }else {
                trackArray[0].enabled = true;
                document.getElementById("stopCameraIcon").style.display = "none";
                document.getElementById("startCameraIcon").style.display = "block";
            }
        })
    }
}

const forcedMute = (trackArray) => {
    socket.on("getMuted", () => {
        if(trackArray[0].enabled){
            trackArray[0].enabled = false;
            
            document.getElementById("stopAudioIcon").style.display = "block";
            document.getElementById("startAudioIcon").style.display = "none";
        }       
    })
}

const forceRemove = () => {
    socket.on('forceRemove', () => {
        alert("You were removed by the teacher");
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    })
}

const joinClassHandler = (meetId) =>{ 

    const name = JSON.parse(localStorage.getItem('user')).name; //this name would be grabbed from the localStorage fraction.
    document.getElementById("joinBtn").addEventListener("click", () => {
        socket.emit("joinClass", meetId, name);
    })
}

const wrongClassHandler = () => {
    
    socket.on("wrongClass", () => {
        alert(
            "The class has not yet started or you entered wrong class id."
        )
    })
}

const raiseHandControl = (meetId) => {

    const name = JSON.parse(localStorage.getItem('user')).name; // this part will be accessed from the localStorage once the login section is done.

    document.getElementById("raiseHand").addEventListener("click", () => {
        socket.emit("raiseHand", name, "Raised Hand", meetId);
        document.getElementById("raiseHand").disabled = true;

        setTimeout(() => {
            document.getElementById("raiseHand").disabled = false;    
        }, 5000);
    })
    document.getElementById("res1").addEventListener("click", () => {
        socket.emit("raiseHand", name, "Understood", meetId);
        document.getElementById("res1").disabled = true;

        setTimeout(() => {
            document.getElementById("res1").disabled = false;    
        }, 5000);
    })
    document.getElementById("res2").addEventListener("click", () => {
        socket.emit("raiseHand", name, "Not Understood", meetId);
        document.getElementById("res2").disabled = true;

        setTimeout(() => {
            document.getElementById("res2").disabled = false;    
        }, 5000);
    })
    
}

const callForOthersStream = (stream) => {
    socket.on("youJoined", (ownSocketId ,roomUsers) => {
        
        yourId = ownSocketId;
        var id = roomUsers[0];
        document.getElementById("ownVideo").style.width = "300px";
        document.getElementById("ownVideo").style.height = "300px";
        document.getElementById("ownVideo").style.position = "absolute";
        createLocalStream(document.getElementById("ownVideo"), stream);

        if(window.outerWidth > 768){
            document.getElementById("ownVideo").style.position = "absolute";
            document.getElementById("ownVideo").style.top = 0;
            document.getElementById("ownVideo").style.left = 0;
            document.getElementById("ownVideo").style.margin = "10px";

            document.getElementById("ownVideo").style.zIndex = "10";
            document.getElementById("ownVideo").style.transform = "scaleX(-1)";
            document.getElementById("ownVideo").style.width = "150px";
            document.getElementById("ownVideo").style.height = "150px";
        }

        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
            wrtc: wrtc
        })

        peer.on('signal', data => {
            socket.emit("callUserGetStream", {toCall: id, dataSentAlong: data, sender: ownSocketId, name: JSON.parse(localStorage.getItem('user')).name});
        })

        peer.on('stream', stream => {
            document.getElementById("teacherVideo").srcObject = stream;
            document.getElementById("teacherVideo").style.transform = "scaleX(1)";
            document.getElementById("teacherVideo").addEventListener("loadedmetadata", () => {
                document.getElementById("teacherVideo").play();
            })
        })
        
        peer.on('error', (err) => {
            peer.destroy(err);
            window.location.reload();
        })

        socket.on("accepted", (data) => {
            peer.signal(data.forYou);
        })
    })

    socket.on("classEnded", () => {
        alert("The class has ended.");
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    })
}

const studentLeave = () => {
    const leaveClass = document.getElementById("leaveStudent");
    const name = JSON.parse(localStorage.getItem('user')).name; //localStorage will get this.

    if(leaveClass){
        leaveClass.addEventListener("click", () => {
            sessionStorageClearage(["reloadingS", "roomId", "inClass", "yourOwnId"]);
            socket.emit("leaveClass", roomId, yourId, name);

            window.location.href = `${process.env.REACT_APP_STUDENT}`;
        })
    }
}

export const sendMessageJs = (roomId, message, name) => {

    var myMessage = `
    <div class="yourOwnMessage"><span class="privateName">&nbsp;&nbsp;&nbsp;&nbsp;<b> You </b>:</span><span class="privateMessage" >${message}</span></div>
    `

    var itemO = document.createElement("li");
    itemO.innerHTML = myMessage;

    document.getElementById("teacherMessage").append(itemO);
    socket.emit("studentMessaging", name, roomId, message, yourId);
}

const listenMessage = () => {
    socket.on("teacherReplyPrivate", (message) => {
        var privateMessage = `
        <div class="message-private"><span class="privateName">Teacher :</span><span class="privateMessage">${message}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        `

        var itemP = document.createElement("li");
        itemP.innerHTML = privateMessage;

        document.getElementById("teacherMessage").append(itemP);
    })

    socket.on("teacherMessageEveryone", (message) => {
        var everyoneMessage = `
        <div class="message-everyone">
        <span class="public">Teacher :</span><span class="publicMessage"> ${message}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        `

        var item = document.createElement("li")
        item.innerHTML = everyoneMessage;

        document.getElementById("teacherMessage").append(item);
    })
}

export const intializeStudentStream = ({meetId, socketRef}) => {

    navigator.mediaDevices.getUserMedia({
        video: {
            width:{
                min: 360,
                ideal: 420,
                max: 640
            },
            height:{
                min: 144,
                ideal: 240,
                max: 360
            },
            frameRate: 10
        },
        audio: true
    })
    .then((stream) => {
        const myVideo = document.getElementById("videoHolder");
        roomId = meetId;
        socket = socketRef;

        createLocalStream(myVideo, stream);
        stopLocalVideo(stream.getVideoTracks());
        stopLocalAudio(stream.getAudioTracks());

        joinClassHandler(meetId);
        wrongClassHandler();
        callForOthersStream(stream);
        getTeacherId(stream);

        raiseHandControl(meetId);
        listenMessage();
        forceRemove();
    })
    .catch(err => {
        alert("Please provide the required permissions");
    })
}
