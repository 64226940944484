import React from 'react';
import Header from './Header';
import Intro from './intro';
import About from './about';
import Feature from './features';
import Subject from './subject';
import Footer from './footer';
import { headerData, introData, aboutData, featureData, posterData } from './dataHome';
import { Stack } from "@chakra-ui/react";

function HomePage() {

    return (
        <Stack spacing={2}>
            <Stack spacing={0}>
            <Header sections={headerData}/>
            <Intro introData={introData} />
            </Stack>
            <About aboutData={aboutData} id="about"/>
            <Feature featureData={featureData} id="features" />
            <Subject posterData={posterData} id="subjects" />
            <Footer />
        </Stack>
    )
}

export default HomePage;