import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Timer from '@material-ui/icons/Timer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import YouTubeIcon from '@material-ui/icons/YouTube';

export const mainListItems = (
  <div>
    <Link to="/student/home">
      <ListItem button>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>
    <Link to="/student/scheduledClass">
    <ListItem button>
      <ListItemIcon>
        <Timer />
      </ListItemIcon>
      <ListItemText primary="Scheduled Class" />
    </ListItem>
    </Link>
    <Link to="/student/attemptTest">
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Attempt Test" />
    </ListItem>
    </Link>
    <Link to="/student/UploadAssignment">
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Upload Assignment"></ListItemText>
    </ListItem>
    </Link>
    <Link to="/student/fetchMaterial">
    <ListItem button>
      <ListItemIcon>
        <AssignmentInd />
      </ListItemIcon>
      <ListItemText primary="Study Material"></ListItemText>
    </ListItem>
    </Link>
    <Link to="/student/viewVideo">
    <ListItem button>
      <ListItemIcon>
        <YouTubeIcon />
      </ListItemIcon>
      <ListItemText primary="Video View"></ListItemText>
    </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>

  </div>
);