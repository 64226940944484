import React, { useState, useRef, useEffect} from 'react';
import {pdfjs} from 'react-pdf';
import jsPDF from 'jspdf';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

const Test = ({match}) => {

    const toast_id = "toast-course";
    const toast = useToast();

    const Drawing = useRef(false)
    const Erasing = useRef(false)
    const [ptr, setPtr] = useState(false)
    const [ptr2, setPtr2] = useState(false)

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    let pdfDoc = null;
    useEffect(() => {

        const canvasContainer = document.getElementById("holder")
        var scale = 1.5;

        function renderPage(page){
            var viewport = page.getViewport({scale})
            var canvas = document.createElement('canvas')
            canvas.classList.add(page._pageIndex)
            canvas.style.position = "relative"
            canvas.style.left = 0
            canvas.style.top = 44 + (viewport.height*page._pageIndex)
            canvas.style.zIndex = 1
            canvas.style.border = "2px solid black";
            canvas.style.marginBottom = "5px";
            var layer = document.createElement('canvas')
            layer.height = viewport.height
            layer.width = viewport.width
            layer.style.left = 0
            layer.style.top = 44 + (viewport.height*page._pageIndex)
            //layer.log(viewport.height*page._pageIndex)
            layer.style.zIndex = 0
            layer.style.position = "absolute"
            layer.classList.add("layer"+page._pageIndex.toString())
            canvas.appendChild(layer);
            canvasContainer.appendChild(canvas)
            var ctx = canvas.getContext("2d")

        var renderContext = {
            canvasContext : ctx,
            viewport
        }

        canvas.height = viewport.height
        canvas.width = viewport.width

        
        page.render(renderContext).promise.then(()=> {
        })
        }

        function renderPages(pdfDoc){
            for(var num=1;num<=pdfDoc.numPages;num++){
                pdfDoc.getPage(num).then(page => {
                    renderPage(page);
                })
            }  
        }

        pdfjs.getDocument(`${process.env.REACT_APP_SERVER}/fetch/${match.params.assignment}`).promise.then(pdfDoc_ => {
            pdfDoc = pdfDoc_
            renderPages(pdfDoc)
        }).catch((err) =>{ 
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Failed to fetch the pdf",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }, [])

    function FreeHand(event){
        if (!Drawing.current){
            return
        }
        var canvas = document.getElementsByClassName(event.target.className)
        canvas = canvas[0]
        var ctx;
        if(canvas){
            ctx = canvas.getContext('2d')
        } else {
            return
        }
        let painting = false

        function startPosition(event){
            painting = true
            draw(event)
        }

        function endPosition(){
            painting = false
            ctx.beginPath()
        }

        function draw(event){
            if (!painting){
                return
            }
            if (!Drawing.current){
                return
            }
            ctx.lineWidth = 5
            ctx.lineCap = "round"
            ctx.strokeStyle = '#ff0000'
            var x = event.clientX;    
            var y = event.clientY; 
            var n = window.pageYOffset || canvas.scrollTop;
            if (event.target.className === 'layer0'){
                ctx.lineTo(x, y+n-44);
                ctx.stroke()
                ctx.beginPath()
                ctx.moveTo(x,y+n-44)
            }
            
            else{
                ctx.lineTo(x,y - event.target.offsetTop +n);
                ctx.stroke()
                ctx.beginPath()
                ctx.moveTo(x,y - event.target.offsetTop +n)
            }
        }
        
        canvas.onmousedown =  startPosition
        canvas.onmouseup = endPosition
        canvas.onmousemove = draw    

    }

    function Erase(event){
        if (!Erasing.current){
            return
        }
        var canvas = document.getElementsByClassName(event.target.className);
        var ctx;
        canvas = canvas[0]
        if(canvas){
            ctx = canvas.getContext('2d');
        }
        let erasing = false

        function startPosition(event){
            erasing = true
            draw(event)
        }

        function endPosition(){
            erasing = false
            ctx.beginPath()
        }

        function draw(event){
            if (!erasing){
                return
            }
            if (!Erasing.current){
                return
            }
            ctx.lineWidth = 5
            ctx.lineCap = "round"
            var x = event.clientX;    
            var y = event.clientY; 
            var n = window.pageYOffset || canvas.scrollTop;
            if (event.target.className === 'layer0'){
                ctx.clearRect(x, y+n-44,20,20);
            }
            else{
                ctx.clearRect(x,y - event.target.offsetTop +n,20,20);
            }
        }
        
        canvas.onmousedown =  startPosition
        canvas.onmouseup = endPosition
        canvas.onmousemove = draw    

    }

    function annot(event){
        FreeHand(event)
    }

    function download(){
        var canvas_coll = document.getElementsByTagName('canvas');
        var canvas = [...canvas_coll];
        let width = canvas[0].width; 
        let height = canvas[0].height;
        let pdf = null ;
        //set the orientation
        if(width > height){
            pdf = new jsPDF('l', 'px', [width, height]);
        }
        else{
            pdf = new jsPDF('p', 'px', [height, width]);
        }
        
        pdf.addImage(canvas[0], 'PNG', 0, 0, width, height,'','FAST');
        pdf.addImage(canvas[1], 'PNG', 0, 0, width, height,'','FAST');
        for (let i = 2 ; i < canvas.length; i= i + 2){
            pdf.addPage();
            if(canvas[i]){
                pdf.addImage(canvas[i], 'PNG', 0, 0,width,height,'','FAST');
            }
            if(canvas[i + 1]){
                pdf.addImage(canvas[i+1], 'PNG', 0, 0,width,height,'','FAST');   
            }
        }

        var pdf_saved = pdf.output('blob');         
        var formData = new FormData();

        formData.append('pdf', pdf_saved);
        formData.append('id', match.params.assignment);

        axios.post(`${process.env.REACT_APP_SERVER}/class/checkStore`, formData)
        .then((res) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Marked as checked",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Failed to fetch the pdf",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        Genus Pdf-Annotator
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="nav navbar-nav ml-auto mb-2 mb-lg-0">
                            <li className="nav-item mr-1">
                                <button className="btn btn-primary" onClick={()=>download()}>Checked</button>
                            </li> 
                            <li className="nav-item mr-1">
                                <button className="btn btn-primary" onClick={() => {Drawing.current = !Drawing.current; setPtr(!ptr)}} >Drawing : { ptr ? <span>On</span> : <span>Off</span> }</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div id="holder" onClick={event => annot(event)}></div>
        </div>
    )
}

export default Test