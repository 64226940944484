import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root:{
        width: '100%',
        padding: '15px'
    },
    headingTest: {
        fontFamily: 'Nunito',
        fontWeight: '500',
        width: '100%',
        textAlign: 'center'
    },
    formHolder:{
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    fieldHolder:{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    optionHeading:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    optionHolder: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    option:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    preview:{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}))

function Test(){

    const classes = useStyles();
    const toast_id = "toast_course";
    const toast = useToast();

    const [testName, setTestName] = useState("");
    const [subject, setSubject] = useState("");
    const [testDate, setTestDate] = useState("");
    const [testStartTime, setTestStartTime] = useState("");
    const [testEndTime, setTestEndTime] = useState("");
    const [students, setStudents] = useState([]);

    const [update, setUpdate] = useState(false);
    const [studentsAll, setStudentsAll] = useState([]);
    const [questions, setQuestions] = useState([]);

    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    const [queText, setQueText] = useState("");
    const [queOptions, setQueOptions] = useState("");
    const [queAnswer, setQueAnswer] = useState(false);
    const [mark, setMark] = useState(0);

    const [options, setOptions] = useState([]);

    const handleStudentChange = (e) => {
        setStudents(e.target.value);
    }
    const handleClose = (e) => {
        setOpen(false);
    }
    const handleOpen = (e, type) =>{
        setOpen(true);
        setDialogType(type);
    }

    const addQuestion = (e) => {
        e.preventDefault();

        let ansCorrect = false;
        options.forEach((opt) => {
            if(opt.answer){
                ansCorrect = true;
            }
        })

        if(queText === "" || options.length === 0 ){
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: 'Either question or options are not added',
                    duration: 3000,
                    position:"top-right"
                })
            }
        } else if(options.length < 2) {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: 'Add more options before adding question',
                    duration: 3000,
                    position:"top-right"
                })
            }
        } else if(!ansCorrect){
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: 'The question has no correct answers!',
                    duration: 3000,
                    position:"top-right"
                })
            }
        } else {
            setQuestions([...questions, {question: queText, options: options, marks: mark}]);
            setQueText("");
            setOptions([]);
            setMark(0);
        }
    }

    const handleQuestionDelete = (index) => {
        if(index > -1){
            questions.splice(index, 1);
            setQuestions([...questions]);
        }

        if(questions.length === 0){
            handleClose();
        }
    }

    const handleOptionAdd = (e) => {
        if(queOptions !== ""){
            setOptions([...options, {"option": queOptions, "answer": queAnswer}]);
            setQueOptions("");
            setQueAnswer(false);
        } else {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    description: "Add text to the option before adding",
                    duration: 3000,
                    position:"top-right"
                })
            }
        }
    }

    const handleOptionDelete = (index) => {

        if(index > -1){
            options.splice(index, 1);
            setOptions([...options]);
        }
    }

    const createMCQTest = (e) => {
        e.preventDefault();

        if(questions.length === 0){
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    title: "Add questions before creating test",
                    durartion: 3000,
                    position: 'top-right'
                })
            }
        } else {
            axios.post(`${process.env.REACT_APP_SERVER}/test/createTest`, {
                name: testName, subject: subject, testDate, testStartTime, testEndTime, students, questions,
                teacherId: JSON.parse(localStorage.getItem('user'))._id
            }).then((res) => {
                if(!toast.isActive(toast_id)){
                    toast({
                        id: toast_id,
                        title: "Test Creation Success",
                        duration: 3000,
                        position: "top-right"
                    })
                }
                setTestName("");
                setSubject("");
            })
            .catch((err) => {
                if(!toast.isActive(toast_id)){
                    toast({
                        id: toast_id,
                        title: "Test Creation Failed",
                        duration: 3000,
                        position: "top-right"
                    })
                }
            })
        }
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/class/getClasses`, {
            teacherId: JSON.parse(localStorage.getItem("user"))._id
        })
        .then((res) => {
            setStudentsAll(res.data.classes);
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    id: toast_id,
                    title: "Error in fetching class list",
                    duration: 3000,
                    position: "top-right"
                })
            }
        })
    }, [update])

    return (
        <>
        <Paper className={classes.root} square variant="elevation" elevation="3">
            <Typography className={classes.headingTest} variant="h4">Create Objective Test</Typography>
            <div className={classes.formHolder}>
                <Typography className={classes.headingTest} style={{fontSize: '20px', padding: '10px 15px 15px 15px'}} variant="h4">Provide the details to create and schedule a MCQ Test.</Typography>
                <form onSubmit={createMCQTest} style={{width: '100%', paddingTop: '20px'}}>
                    <TextField 
                        required
                        fullWidth
                        label="Test Name"
                        type="text"
                        placeholder = "Enter a name for the test"
                        value={testName}
                        onChange={e=>setTestName(e.target.value)}
                        name="testName"
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        required
                        fullWidth
                        label="Name of Subject"
                        type="text"
                        placeholder="Subject of test"
                        value={subject}
                        onChange={e=>setSubject(e.target.value)}
                        name="subjectTest"
                        style={{marginBottom: '10px'}}
                    />
                    <div className={classes.fieldHolder}>
                        <TextField 
                            required
                            style={{width: '30%'}}
                            name="testDate"
                            label="Test Date"
                            value={testDate}
                            type="date"
                            onChange={e=>setTestDate(e.target.value)}
                        />
                        <TextField 
                            required
                            style={{width: '30%'}}
                            name="testStart"
                            label="Test Start"
                            type="time"
                            value={testStartTime}
                            onChange={e=>setTestStartTime(e.target.value)}
                        />
                        <TextField 
                            required
                            style={{width: '30%'}}
                            name="testEnd"
                            label="Test End"
                            type="time"
                            value={testEndTime}
                            onChange={e=>setTestEndTime(e.target.value)}
                        />
                    </div>
                    <FormControl fullWidth style={{marginBottom: '10px'}}>
                        <InputLabel>Select Student Group For Test</InputLabel>
                        <Select
                            required
                            multiple
                            value={students}
                            onChange={handleStudentChange}
                        >
                            {studentsAll.map((student) => {
                                return <MenuItem key={student._id} value={student._id}>{student.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Typography className={classes.headingTest} style={{fontSize: '15px', padding: '10px'}}>
                        The test currently contains { questions.length } questions.
                    </Typography>
                    <div className={classes.fieldHolder}>
                        <Button variant="contained" type="button" color="primary" onClick={(e) => handleOpen(e, "add")}>Add Questions</Button>
                        {questions.length > 0 ? 
                            <Button variant="contained" type="button" color="primary" onClick={(e) => handleOpen(e, "preview")}>Preview Questions</Button>
                            :
                            null
                        }
                        <Button variant="contained" type="submit" color="primary">Create Test</Button>
                    </div>
                </form>
            </div>
        </Paper>
        <Dialog open={open} fullWidth disableBackdropClick disableEscapeKeyDown onClose={handleClose}>
            {dialogType === "add" ? 
                <DialogContent>
                    <Typography className={classes.headingTest} style={{fontWeight: '600', marginBottom:'10px'}} variant="h5">Questions Panel</Typography>
                    <div className={classes.fieldHolder}>
                        <TextField
                            fullWidth
                            type="text"
                            value={queText}
                            onChange={e=>setQueText(e.target.value)}
                            label="Question"
                            placeholder="Enter the question text"
                            style={{marginBottom: '10px', fontFamily:'Nunito', width: '75%'}}
                        />
                        <TextField 
                            type="number"
                            value={mark}
                            onChange={e=>setMark(e.target.value)}
                            label="Mark"
                            style={{width: '15%'}}
                        />
                    </div>
                    <div className={classes.optionHolder}>
                        <div className={classes.optionHeading}>
                            <Typography variant="h6">Options</Typography>
                            <Button variant="contained" color="primary" onClick={handleOptionAdd}>Add Option</Button> 
                        </div>
                        <div className={classes.options} id="options">
                            <div className={classes.option}>
                                <Checkbox checked={queAnswer} onClick={e=>setQueAnswer(e.target.checked)}/>
                                <TextField 
                                    required
                                    label="Option"
                                    style={{width:'90%'}}
                                    placeholder="Type the option"
                                    value={queOptions}
                                    onChange={e=>setQueOptions(e.target.value)}
                                />
                            </div>
                            {
                                options.map((option, index) => {
                                    return (
                                        <div className={classes.option}>
                                            <Checkbox disabled checked={option.answer}/>
                                            <TextField 
                                                required
                                                label="Option"
                                                style={{width:'90%'}}
                                                placeholder="Type the option"
                                                value={option.option}
                                            />
                                            <CloseIcon onClick={(e)=>handleOptionDelete(index)} />
                                        </div>
                                    )
                                })  
                            }
                        </div>
                    </div>
                </DialogContent>
            :
            dialogType === "preview" ?
                <DialogContent>
                    <Typography className={classes.headingTest} style={{fontWeight: '600', marginBottom:'10px'}} variant="h5">Preview Questions Panel</Typography>
                    {
                        questions.map((question, index) =>{
                            return (
                                <div className={classes.preview}>
                                    <div className={classes.fieldHolder}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={question.question}
                                            label="Question"
                                            placeholder="Enter the question text"
                                            style={{marginBottom: '10px', fontFamily:'Nunito', width: '75%'}}
                                        />
                                        <TextField 
                                            type="number"
                                            value={question.marks}
                                            label="Mark"
                                            style={{width: '15%'}}
                                        />
                                    </div>
                                    <div className={classes.optionHolder}>
                                    {
                                        question.options.map((option) => {
                                            return (
                                                <div className={classes.option}>
                                                    <Checkbox disabled checked={option.answer}/>
                                                    <TextField 
                                                        required
                                                        label="Option"
                                                        style={{width:'90%'}}
                                                        placeholder="Type the option"
                                                        value={option.option}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    <div className={classes.fieldHolder}>
                                        <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
                                        <Button variant="contained" color="primary" onClick={e=>handleQuestionDelete(index)}>Delete Question</Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </DialogContent>
            :
                <DialogContent>
                    No Content To Show
                </DialogContent>
            }
            {dialogType !== "preview" ?
                <DialogActions>
                    <div className={classes.fieldHolder}>
                        <Button variant="contained" type="button" color="primary" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="button" color="primary" onClick={addQuestion}>Add Question</Button>
                    </div>
                </DialogActions>
            :
                null
            }
        </Dialog>
        </>
    )
}

export default Test;