import React, { useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ChatIcon from '@material-ui/icons/Chat';
import PeopleIcon from '@material-ui/icons/People';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { useToast } from '@chakra-ui/react';
import { MdExpandMore, MdSend } from 'react-icons/md';
import styled from "styled-components";
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Attendee from './Attendee';
import Message from './Message';
import Response from './Response';
import { Badge } from '@material-ui/core';

const Utils = styled.div`

    padding: 7px;
    height: 100vh;

    @media (min-width: 769px) {
        width: 24%;
    }

    @media (max-width: 768px) {
        width: 100%
    }
`

const useStyles = makeStyles((theme) => ({
    root:{
        flexGrow: 1
    },
    utils:{
        padding: '7px',
        height: '100vh',
        width: '35vw'
    },
    accordionStyles: {
        backgroundColor: '#2C3E50',
        color: 'white',
        width: '100%'
    },
    sectionTitle: {
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 600
    },
    messageForm: {
        display: 'inline-flex',
        position: 'absolute',
        bottom: 15,
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputMessage: {
        color: 'white',
        fontFamily: 'Nunito',
        fontSize: '15px',
        display: 'inline-flex',
    },
    btnFormMessage: {
        display: 'inline-flex',
        float: 'right'
    },
    messages: {
        overflow: 'auto',
        height: '75vh',
        width: '100%'
    },
    tab: {
        minWidth: '30%',
        width: '33%'
    }
}))

function Utility(props) {

    const {
        peers,
        consumers,
        me,
        classClient,
        messages,
        response
    } = props;

    classClient.then((res) => {
        setRealClass(res);
    })

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [realClass, setRealClass] = useState("");
    const [message, setMessage] = useState("");
    const [unreadCount, setUnreadCount] = useState(false);
    const [responseCount, setResponse] = useState(false);

    const sendPublicMessage = (e) => {
        e.preventDefault();

        let sender = "";

        if(me.type === "teacher") {
            sender = "Teacher"
        } else {
            sender = me.name;
        }

        if(message !== ""){
            realClass.sendPublicMessage({sender, message: message, time: new Date().toTimeString().split(" ")[0]});
            setMessage("");
        }
    }

    return(
        <Utils className={classes.utils}>
            {me.type === "teacher" ? 
            <TabContext value = {value} style={{width: '100%'}}>
                <TabList
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    style={{color: "white", width: "100%"}}
                >
                    <Tab value={0} label={<Badge badgeContent={Object.keys(peers).length} color="primary"><PeopleIcon style={{color: "white"}} /></Badge>} classes = {{root: classes.tab}} />
                    <Tab value = {1} label={unreadCount ? <Badge variant="dot" color="primary"><ChatIcon /></Badge> : <ChatIcon /> } onClick={() => setUnreadCount(false)} style={{color: "white"}} classes = {{root: classes.tab}} >
                    </Tab>
                    <Tab value={2} label={responseCount ? <Badge variant = "dot" color="primary"><RecordVoiceOverIcon style={{color: "white"}} /></Badge> : <RecordVoiceOverIcon style={{color: "white"}} />} onClick = {() => setResponse(false)} classes = {{root: classes.tab}} />
                </TabList>
                <TabPanel style={{width: '100%', height: '75vh', overflow: 'auto'}} value = {0}>
                    <Attendee peer={peers} consumers={consumers} classClient={classClient} />
                </TabPanel>
                <TabPanel style={{width: '100%'}}  value={1}>
                    <div id="messageHolder" className={classes.messages}>
                        <Message messages={messages} style={{color: "white"}} unread={setUnreadCount}/>
                    </div>
                    <form className={classes.messageForm} onSubmit = {sendPublicMessage}>
                        <Input
                            className={classes.inputMessage}
                            value={message}
                            onChange={e=>setMessage(e.target.value)}
                            placeholder="Send public message"
                            variant="outlined"
                            style={{width: "80%"}}
                        />
                        <Button className={classes.btnFormMessage} type="submit">
                            <MdSend style={{color: 'white'}}/>
                        </Button>
                    </form>
                </TabPanel>
                <TabPanel style={{width: '100%'}} value = {2}>
                    <Response data={response} response={setResponse} />
                </TabPanel>
            </TabContext>
            :
            <TabContext value={value} style={{width: '100%'}}>
                <TabList
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    style={{color: "white", width: "100%"}}
                >
                    <Tab value = {0} label={unreadCount ? <Badge badgeContent="dot" color="primary"><ChatIcon style={{color: "white"}} /></Badge> : <ChatIcon style={{color: "white"}} />} onClick = {() => setUnreadCount(false)} style={{color: "white"}} classes = {{root: classes.tab}} />
                </TabList>
                <TabPanel style={{width: '100%'}} value={0}>
                    <div id="messageHolder" className={classes.messages}>
                        <Message messages={messages} unread={setUnreadCount} />
                    </div>
                    <form className={classes.messageForm} onSubmit = {sendPublicMessage}>
                        <Input
                            className={classes.inputMessage}
                            value={message}
                            onChange={e=>setMessage(e.target.value)}
                            placeholder="Send public message"
                            variant="outlined"
                            style={{width: "80%"}}
                        />
                        <Button className={classes.btnFormMessage} type="submit">
                            <MdSend style={{color: 'white'}}/>
                        </Button>
                    </form>
                </TabPanel>
            </TabContext>
            }
        </Utils>
    )
}

const mapStateToProps = (state) => {
    const peersArray = state.peers
    const consumerArray = state.consumers
    const messages = state.message;
    const response = state.response;

    return {
        peers: peersArray,
        consumers: consumerArray,
        me: state.me,
        messages,
        response
    }
}

const Utilities = connect(
    mapStateToProps,
    null
)(Utility);

export default Utilities;