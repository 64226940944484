import React, {useState, useEffect} from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import Title from './Title';
import Publish from './publish';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {AiFillDelete} from 'react-icons/ai';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function Notice() {

  const preventDefault = (event) => {
    event.preventDefault();
  }

  const [functions, setFunction] = useState(null);
  const [openD, setOpenD] = useState(false);

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleClose = () => {
    setOpenD(false);
  };

  const modalOpen = (id) => {
    handleClickOpen();
    setFunction(() => () => deleteNotice(id));
  }

  const toast = useToast();
  const [gotNotices, setGotNotices] = useState([]);
  const [update, setUpdate] = useState(false);

  const deleteNotice = (id) => {
    axios.post(`${process.env.REACT_APP_SERVER}/user/deleteNotice`,{
      adminId: JSON.parse(localStorage.getItem('user')).email,
      noticeId: id
    })
    .then((err) => {
      handleClose();
      setUpdate(!update);
      toast({
        title: "Notice Deleted Successfully",
        duration: 3000
      })
    })
    .catch((err) => {
      handleClose();
      toast({
        title: "Deleting Notice Failed",
        duration: 3000
      })
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}/user/getNotices`)
    .then((res) => {
      setGotNotices(res.data.notices);
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Notices",
        status: "error",
        isClosable: true,
        position: "top"
      })
    })
  }, [update])

  return (
    <>
      <Grid container>
        <Title>Notice Board</Title>
        <Grid container spacing={2} style={{marginBottom: "25px"}}>
          { typeof gotNotices === 'undefined' ? null : gotNotices.length !== 0  ?
            gotNotices.map((notice) => {
              return(
                <Grid key={notice._id} item xs={12}>
                  <Typography>{notice.notice}
                  <Button><AiFillDelete onClick={() => {modalOpen(notice._id)}} w={10} h={6} color="red" /></Button>
                  </Typography>
                </Grid>
              )
          }): null}
        </Grid>
        <Link color="primary" href="#" onClick={preventDefault}>
          <Publish updateNotice = {setUpdate} updateSet={update}/>
        </Link>
      </Grid>

      <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openD} onClose={handleClose}>
        <DialogTitle>Delete This Notice</DialogTitle>
        <DialogContent>
            <Typography>Are you sure you want to delete this notice?</Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
        </Button>
        <Button onClick={functions} color="primary">
            Delete Notice
        </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}