import styled from 'styled-components';

export const Section = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('img/intro-bg.jpg');
    background-size: cover;
    justify-content: center;
    align-items: center;

    @media and screen (max-width: 768px){
        width: 100vh;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 10px;
`

export const TextTitle = styled.h5`
    color: white;
    font-size: 45px;
    font-style: bold;
    font-family: 'Shippori Mincho B1', serif;
    text-align: center;
    margin-bottom: 35px;

    @media screen and (max-width: 768px){
        font-size: 30px;
    }
`

export const TextPara = styled.div`
    color: white;
    font-size: 30px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
`

