import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { AiFillDelete } from 'react-icons/ai';
import Button from '@material-ui/core/Button';
import { Dialog, DialogContent, DialogActions, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    holder: {
        display: 'flex',
        width: '100%',
        padding: '10px',
        flexDirection: 'column'
    },
    btnHolder: {
        display: 'grid',
        placeItems: 'center',
        widht: '100%',
        padding: '15px'
    }
}))

function AddSubject() {
    const classes = useStyles();

    const toast = useToast();
    const toast_id = "toast_id";

    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [subject, setSubject] = useState([]);
    const [newSubject, setNewSubject] = useState("");

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setNewSubject("");
    }

    const deleteSubject = (id) => {

        axios.post(`${process.env.REACT_APP_SERVER}/subject/deleteSubject`, {
            subjectID: id
        })
        .then((res) => {
            setUpdate(!update);

            if(!toast.isActive(toast_id)){
                toast({
                    description: "Deleted Successfully",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right",
                    status: "success"
                })
            }
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    description: "Error in deleting subject",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        })
    }

    const addSubject = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_SERVER}/subject/addSubject`, {
            subject: newSubject
        })
        .then((res) => {
            handleClose();
            setUpdate(!update);

            if(!toast.isActive(toast_id)){
                toast({
                    description: "Add Subject Successfully",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right",
                    status: "success"
                })
            }
        })
        .catch((err) => {
            if(!toast.isActive(toast_id)){
                toast({
                    description: "Error in adding subject",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        })
    }

    useEffect(() => {

        axios.post(`${process.env.REACT_APP_SERVER}/subject/getSubjects`)
        .then((res) => {
            
            let subjectData = [];

            res.data.subjectOptions.forEach((element) => {
                subjectData.push({
                    "subject": element.subject,
                    "delete": <AiFillDelete onClick={() => deleteSubject(element._id)} w={6} h={6} color="red.600" />
                })
            })

            setSubject(subjectData);
        })
        .catch((err) => {

            if(!toast.isActive(toast_id)){
                toast({
                    description: "Error in fetching list of subjects",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        })

    }, [update])

    return (
        <>
        <div className={classes.holder}>
            <MaterialTable
                columns = {[
                    {title: "Subject Name", field: "subject"},
                    {title: "Delete Actions", field: "delete"}
                ]}
                data = {subject}
                title = "Active Subject List"
            />
            <div className={classes.btnHolder}>
                <Button variant="contained" color="primary" onClick={handleOpen}>Add Subject</Button>
            </div>
        </div>
        <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={open}>
            <DialogContent>
                <form onSubmit={addSubject}>
                    <TextField 
                        fullWidth
                        name="subjectNew"
                        label="New Subject Name"
                        placeholder = "Enter new subject name"
                        value={newSubject}
                        onInput={e=>setNewSubject(e.target.value)}
                    />
                    <Button variant="contained" color="primary" type="submit" style={{marginTop: '10px'}}>
                        Add Subject
                    </Button>
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default AddSubject;