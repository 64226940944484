import React from "react";
import { connect } from "react-redux";
import { withRoomContext } from '../../helper/roomContext';
import PersonView from './PersonView';

class Me extends React.Component {
    constructor(props) {
        super(props);
    
        this._mounted = false;
        this._rootNode = null;
    }

    render() {
        const {
            me,
            audioProducer,
            videoProducer
        } = this.props

        let micState;

        if(!me.canSendMic) micState = "unsupported";
        else if(!audioProducer) micState = "unsupported";
        else if(!audioProducer.paused) micState = "on";
        else micState = "off";

        let webcamState;

        if (!me.canSendWebcam) webcamState = "unsupported";
        else if (videoProducer && videoProducer.type !== "share")
          webcamState = "on";
        else webcamState = "off";
        
        let changeWebcamState;

        if(
            Boolean(videoProducer) && 
            videoProducer.type !== "share" && 
            me.canChangeWebcam
        )
            changeWebcamState = "on";
        else changeWebcamState = "unsupported";

        let shareState;
        if (Boolean(videoProducer) && videoProducer.type === "share")
            shareState = "on";
        else shareState = "off";
  
        const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

        let tip;

        return (
            <div data-component="Me"
                ref={(node) => (this._rootNode = node)}
                data-tip={tip}
                data-tip-disable={!tip}
            >
                <PersonView 
                    isMe
                    peer={me}
                    audioTrack={audioProducer ? audioProducer.track : null}
                    videoTrack={videoProducer ? videoProducer.track : null}
                    videoVisible={videoVisible}
                    audioCodec={audioProducer ? audioProducer.codec : null}
                    videoCodec={videoProducer ? videoProducer.codec : null}
                    audioScore={audioProducer ? audioProducer.score : null}
                    videoScore={videoProducer ? videoProducer.score : null}
                    audioRtpParameters={
                        audioProducer ? audioProducer.rtpParameters : null
                    }
                    videoRtpParameters={
                        videoProducer ? videoProducer.rtpParameters : null
                    }
                />
            </div>
        )
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }
}

const mapStateToProps = (state) => {
    const producersArray = Object.values(state.producers);
    const audioProducer = producersArray.find(
      (producer) => producer.track.kind === "audio"
    );
    const videoProducer = producersArray.find(
      (producer) => producer.track.kind === "video"
    );
  
    return {
        connected: state.classes.state === "connected",
        me: state.me,
        audioProducer: audioProducer,
        videoProducer: videoProducer,
    };
}

const MeContainer = withRoomContext(
    connect(mapStateToProps, null)(Me)
)

export default MeContainer;