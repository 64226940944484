import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './login.css';
import { Redirect } from 'react-router-dom';
import { isAuth } from '../../helper/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" >
      PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8,4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
 
}));


export default function SignUp({history, match}) {
  const classes = useStyles();
  const [otp, setOTP] = useState('');
  const toast = useToast();

  const verification = (e) => {
    e.preventDefault();

    setOTP("");

    axios.post(`${process.env.REACT_APP_SERVER}/auth/register/${match.params.token}`,{
      sentOtp: otp
    })
    .then((res) => {
      history.push(`/additionalDetails/${res.data.idToken}`);
    })
    .catch(err => {
      toast({
        title: "Error Occured",
        description: "Something Went Wrong",
        isClosable: true,
        status: "error",
        position: "top"
      })
    })
  }

  return (
    <>
    {isAuth() ? <Redirect to='/' /> : null}
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AssignmentIndIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          E-mail Verification
        </Typography>
        <form className={classes.form} onSubmit={verification}>
          <Grid container spacing={2}>
         
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="OTP"
                label="OTP"
                name="OTP"
                value={otp}
                onInput={ e=>setOTP(e.target.value)}
              />
            </Grid>
            
            
            <Grid item xs={12}>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            SUBMIT
          </Button>
          <h5 align="center"> *Enter the OTP sent to your E-mail ID* </h5>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
}