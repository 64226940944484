import React from 'react';
import { connect } from 'react-redux';
import {
    Conference,
    TabContainer
} from './ClassRoomComponents';
import { makeStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../helper/roomContext';
import classnames from "classnames";
import Participants from '../Participants';
import Me from '../Me';
import Tabs from '../Tabs';
import Utilities from '../Utilities';

const useStyles = makeStyles((theme) => ({
    iconHolder: {
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: '100%'
    },
    iconStyle:{
        color: 'white',
        width: '100%',
        height: '4vh',
        cursor: 'pointer'
    },
    heading: {
        fontFamily: 'Nunito',
        fontWeight: '600',
        fontSize: '25px',
        color: 'white'
    },
    content:{
        fontFamily: 'Nunito',
        fontSize: '20px'
    },
    linkId:{
        fontFamily: 'Nunito',
        fontWeight: '600',
        fontSize: '20px'
    },
    videoHolder: {
        display: 'grid',
        placeItems: 'center'
    },
    videoClass: {
        borderRadius: '15px',
        overflow: 'hidden',
        transform: 'scaleX(-1)',
        width: '100%',
        height: '100%',
    },
    utils:{
        padding: '7px',
        height: '100vh',
        display: 'grid',
        placeItems:'center'
    },
    accordionStyles: {
        backgroundColor: '#2C3E50',
        color: 'white',
        width: '100%'
    },
    sectionTitle: {
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 600
    },
    messageForm: {
        display: 'inline-flex',
        position: 'absolute',
        bottom: 15
    },
    inputMessage: {
        color: 'white',
        fontFamily: 'Nunito',
        fontSize: '15px',
        display: 'inline-flex'
    },
    btnFormMessage: {
        display: 'inline-flex'
    }
}));

class ClassRoom extends React.Component {

    render () {
        const {
            classClient, classes, me, amActiveSpeaker
        } = this.props;
    
        return(
            <Conference data-component="Room">
                <Participants />
                
                <TabContainer>
                    <Tabs />
                </TabContainer>
                <Utilities classClient={classClient} className={classnames({'mobileStyle' : me.device.type === "mobile" })}/>
                <div className = {classnames("me-container", {
                    "active-speaker": amActiveSpeaker
                })}>
                    <Me />
                </div>
            </Conference>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        classes: state.classes,
        me: state.me,
        amActiveSpeaker: state.me.id === state.classes.activeSpeakerId,
    }
}

const ClassRoomContainer = withRoomContext(
    connect(mapStateToProps, null)(ClassRoom)
)

export default ClassRoomContainer;