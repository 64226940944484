import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import {AiFillDelete} from 'react-icons/ai';
import {AiOutlineUserAdd} from 'react-icons/ai';
import { Button, useToast} from '@chakra-ui/react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8,4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
   
  }));
  
function GroupView(){

    const classes = useStyles();

    const [update, setUpdate] = useState(false);
    const [openD, setOpenD] = useState(false);

    const toast = useToast();

    const [functions, setFunction] = useState(null);
    const [action, setAction] = useState("");
    const [group, setGroup] = useState([]);
    const [toAdd, setToAdd] = useState([]);
    const [tableData, setTableData]  = useState([]);

    const [left, setLeft] = useState([]);

    var selectedStudents = useRef([]);

    const handleClickOpen = () => {
        setOpenD(true);
      };
    
      const handleClose = () => {
        setOpenD(false);
      };

    const modalOpen = (id, type) => {
        handleClickOpen();
        if(type === "delete"){ 
            setFunction(() => () => deleteGroup(id))
            setAction("delete")
        } else {
            axios.post(`${process.env.REACT_APP_SERVER}/group/getRemainingStudents`,{
                adminId: JSON.parse(localStorage.getItem('user')).email,
                groupId: id
            })
            .then((res) => {
                setLeft(res.data.left);
            })
            .catch((err) => {
                toast({
                    title: "Error In Fetching Student Details",
                    duration: 2000,
                    isClosable: true,
                    status: 'error'
                })
            })
            setFunction(() => () => addStudent(id))
            setAction("add")
        }
    }

    const modalOpenRemove = (groupId, studentId) => {
        handleClickOpen();
        setAction("remove");
        setFunction(() => () => removeStudent(groupId, studentId));
    }

    const handleChangeStudent = (e) => {
        setToAdd(e.target.value);
        selectedStudents.current = e.target.value;
    }
    const deleteGroup = (id) => {
        axios.post(`${process.env.REACT_APP_SERVER}/group/deleteGroup`, {
            adminId: JSON.parse(localStorage.getItem('user')).email,
            groupId: id
        })
        .then((res) => {
            handleClose();
            setUpdate(!update);
        })
        .catch((err) => {
            handleClose();
            toast({
                title: "Deleting User Failed",
                postion: "top",
                duration: 3000,
                status: "error"
            })
        })
    }

    const addStudent = (groupId) => {
        axios.post(`${process.env.REACT_APP_SERVER}/group/addStudent`, {
            adminId: JSON.parse(localStorage.getItem('user')).email,
            groupId: groupId,
            student: selectedStudents.current
        })
        .then((res) => {
            handleClose();
            setUpdate(!update);
            toast({
                title: "Students Added Successfully",
                duration: 3000
            })
        })
        .catch((err) => {
            handleClose();
            toast({
                title: "Error In Adding Student",
                duration: 4000
            })
        })
    }

    const removeStudent = (groupId, studentId) => {
        axios.post(`${process.env.REACT_APP_SERVER}/group/removeStudent`,{
            adminId: JSON.parse(localStorage.getItem('user')).email,
            groupId: groupId,
            studentId: studentId
        })
        .then((res) => {
            handleClose();
            setUpdate(!update);
            toast({
                title: "Removed Successfully",
                duration: 3000
            })
        })
        .catch((err) => {
            handleClose();
            toast({
                title: "Removal Failed",
                duration: 3000
            })
        })
    }

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/group/getExistingGroupStatus`,{
            adminId: JSON.parse(localStorage.getItem('user')).email
        })
        .then((res) => {
            setTableData(res.data.group);

            let data = [];
            res.data.group.forEach((group) => {
                data.push({
                    "name": group.name,
                    "num": group.students.length,
                    "delete" : <AiFillDelete onClick={() => {modalOpen(group._id, "delete")}} w={6} h={6} color="red" />,
                    "add": <AiOutlineUserAdd onClick={() => {modalOpen(group._id, "add")}} w={6} h={6} color="green"/>
                })
            })

            setGroup(data);
        })
        .catch(err => {
            toast({
                position: "top",
                isClosable: true,
                duration: 4000,
                status: "error",
                title: "Error In Fetching Group Details"
            })
        })
    }, [update, toast])

    return (
        <>
            <MaterialTable
                columns = {[
                    {title: 'Group Name', field: 'name'},
                    {title: 'Number of students', field: 'num'},
                    {title: 'Delete Group', field: 'delete'},
                    {title: 'Add Students', field: 'add'}
                ]}
                data = {group}
                title = "Group List"
            />

            {typeof tableData === 'undefined' ? null : tableData.length  !== 0 ?
                
                tableData.map((data) => {
                    return (
                        <>
                            <Container key={data._id} style={{marginBottom: "20px", marginTop: "25px"}}>
                                <Title>Members of Group {`${data.name}`}</Title>
                                <Table size="large">
                                    <TableHead>
                                        <TableCell>Name Of Student</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Mobile Number</TableCell>
                                        <TableCell>Remove Student</TableCell>
                                    </TableHead>
                                    <TableBody>
                                    {data.students.map((sub) => {
                                        return (
                                            <TableRow key={sub._id}>
                                                <TableCell>{sub.name}</TableCell>
                                                <TableCell>{sub.email}</TableCell>
                                                <TableCell>
                                                    {sub.numberS}
                                                </TableCell>
                                                <TableCell>
                                                    {<AiFillDelete onClick={() => {modalOpenRemove(data._id, sub._id)}} w={6} h={6} color="red" />}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                </Table>
                            </Container>
                        </>
                    )
                })
                :
                null
            }

            <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openD} onClose={handleClose}>
            {action === "delete" ?
                <>
                <DialogTitle>Select The Students</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this group?</Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={functions} color="primary">
                    Delete Group
                </Button>
                </DialogActions>
                </>
                :
                action === "add" ? 
                <>
                <DialogTitle>Select The Students</DialogTitle>
                <DialogContent>
                <form className={classes.container}>
                    <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-dialog-select-label"></InputLabel>
                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={toAdd}
                        multiple
                        onChange={handleChangeStudent}
                        input={<Input />}
                    >
                        {left.map((group) => (
                        <MenuItem key={group._id} value={group._id}>
                            {`${group.name}`}
                        </MenuItem>
                        )
                        )}
                    </Select>
                    </FormControl>
                </form>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={functions} color="primary">
                    Ok
                </Button>
                </DialogActions>
                </>
                :
                action === "remove" ? 
                <>
                <DialogTitle>Select The Students</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove this student from the group?</Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={functions} color="primary">
                    Ok
                </Button>
                </DialogActions>
                </>
                : null}
            </Dialog>
        </>
    )
}

export default GroupView;