import styled from 'styled-components';

export const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const Holder = styled.div`
    display: grid;
    justify-content: space-between;
    padding: 20px;
    grid-gap: 35px;
    grid-template-columns: auto auto;

    @media screen and (max-width: 768px){
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        justify-content: center;
        grid-gap: 0px;
    }
`

export const ImageHolder = styled.img`
`

export const TextHolder = styled.div`
    text-align: start;
`

export const TextTitle = styled.div`
    font-size: 45px;
    font-family: sans-serif;
    font-weight: 500;
`

export const TextDesc = styled.div`
    font-size: 20px;
    font-family: Georgia;
    margin-bottom: 20px;
`

export const TextQues = styled.div`
    font-size: 35px;
    margin-bottom: 10px;
    font-family: sans-serif;
`

export const TextAns = styled.li`
    font-size: 20px;
    font-family: Georgia;
`