import React, { useState, useEffect} from 'react';
import { useToast, Stack } from '@chakra-ui/react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import axios from 'axios';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

export default function Schedule() {

  const toast = useToast();
  const toastId = "toastId";
  const [scheduled, setScheduled] = useState([]);

  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [classId, setClassId] = useState("");

  const handleOpne = (resp, id) => {
    setOpen(true);
    setClassId(id);
  }

  const handleLinkAdd = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/class/addZoomLink`, {
      classId, link
    })
    .then((res) => {
      setOpen(false);
      setLink("");

      if(!toast.isActive(toastId)){
        toast({
          id: toastId,
          title: "Adding Link Successfull!",
          duration: 1500,
          isClosable: true
        })
      }
    })
    .catch((err) => {
      if(!toast.isActive(toastId)){
        toast({
          id: toastId,
          title: "Adding Link Failed",
          status: "error",
          duration: 1500,
          isClosable: true
        })
      }
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getScheduledTeacher`,{
      teacherId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      setScheduled(res.data.classes);
    })
    .catch((err) => {
      toast({
        title:"Error in fetching the scheduled classes",
        isClosable: true,
        position:'top',
        status:"error"
      })
    })
  }, [])
  return (
    <Container>
      <Title>Your Scheduled Classes</Title>
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell style={{width: '25%'}}>Name</TableCell>
            <TableCell style={{width: '25%'}}>Time</TableCell>
            <TableCell style={{width: '25%'}}>Subject</TableCell>
            <TableCell style={{width: '25%'}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { typeof scheduled === 'undefined' ? null : scheduled.length !== 0  ?
            scheduled.map((notice) => {
            return (<TableRow key={notice._id}>
              <TableCell>{notice.name}</TableCell>
              <TableCell>{notice.startTime}</TableCell>
              <TableCell>{notice.subject}</TableCell>
              <TableCell >
                <Stack spacing={2} direction="row">
                  <Link color="primary" onClick={() => handleOpne(true, notice._id)}>
                    <Button variant="contained" color="primary">Add Link</Button>
                  </Link>
                  <Link color="primary" href={`/class/${notice.meetId}`}><Button variant="contained" color="primary">Start Class</Button>
                  </Link>
                </Stack>
              </TableCell>
            </TableRow>)
          }): null}
        </TableBody>
      </Table>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>
          Add Link to the Class
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleLinkAdd}>
            <Stack spacing={4}>
              <TextField 
                variant="standard"
                required
                fullWidth
                value={link}
                onChange={(e) => setLink(e.target.value)}
                label="Zoom Link"
              />
              <Button
                type="submit"
                color="primary"
                fullWidth
                variant="contained"
              >
                Add Link
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}