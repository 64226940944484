export const headerData = [
    {
        title: "About",
        url: "#about"
    },
    {
        title: "Features",
        url: "#features"   
    },
    {
        title: "Subjects",
        url:"#subjects"
    }
]

export const introData = {
    title: "PERFECT COMMERCE POINT",
    description: "Here at Perfect Commerce Point we teach commerce subjects for class 11th and 12th.",
    buttonName: "Get Started"
}

export const aboutData = {
    title: "About Us",
    desc: "Perfect Commerce Point is the Name of Excellence from the last 32 years and a leading institute in the field of Commerce Education for the students who have opted Commerce as their career.",
    question: "WHO COULD JOIN:",
    answer: [
        "Students who are interested in conceptual, in-depth studies for all the areas of subjects.",
        "Students who are committed to do regular assignments and test- series.",
        "Then, this is the right platform to chase your aspirations."
    ]
}

export const featureData = [
    {
        "icon": "fa fa-group",
        "title": "Live Classes",
        "text": "Join the live class sessions for online studies"
    },
    {
    
        "icon": "fa fa-book",
        "title": "Assignments",
        "text": "Complete assignments on time and get remarks"
    },
    {
        "icon": "fa fa-sign-in",
        "title": "Register Yourself",
        "text":"Start right after you get registered"
    },
    {
        "icon": "fa fa-bullhorn",
        "title": "Online Test/MCQs",
        "text": "Online Test/MCQs conducted periodically to test the students performance"
    }
]

export const posterData = [
    {
        source: "img/portfolio/PCP Gopesh Panjabi.png"
    },
    {
        source: "img/portfolio/PCP Abhinav .png"
    }
]