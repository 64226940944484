import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { isAuth, authenticate } from '../../helper/auth'; 
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './login.css';
import Instructions from './instruction';
import { useHistory, Redirect } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="blue" >
      PCP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8,4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
 
}));

export default function SignUp() {
  const classes = useStyles();
  const toast = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  
  let history = useHistory();
  const signIn = () => {
    history.push('/signIn')
  }

  const sendGoogleToken = tokenId => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/googleLogin`,{
        idToken: tokenId
    }).then(res => {
        informParent(res)
    })
    .catch(err => {
        toast({
          title: 'Google Login Error'
        })
    })
  }

  const informParent = response => {

    if(typeof response.data.tokenG !== 'undefined'){
      toast({
        title: "OTP sent to your email"
      })
      history.push(`/register/${response.data.tokenG}`)
    }else if(typeof response.data.token !== 'undefined'){
      authenticate(response, () => {
          if(isAuth()){
              if(isAuth().role === 'admin'){
                history.push('./admin')
              } else if(isAuth().role === 'teacher'){
                history.push('./teacher')
              } else {
                history.push('./student')
              }
          }
      })
    } else {
      toast({
        title: "You are not authorized yet!"
      })
    }
  }

  const responseGoogle = response =>{
      sendGoogleToken(response.tokenId)
  }

  const register = (e) => {
    e.preventDefault()
    
    axios.post(`${process.env.REACT_APP_SERVER}/auth/register`, {
      firstName, lastName, email, password
    })
    .then((res) => {

      setfirstName("");
      setlastName("");
      setPassword("");
      setEmail("");

      toast({
        title: "Registration Initiated",
        description: "Email containing a verification code has been sent to the email",
        isClosable: false,
        status: "success",
        position: "top"
      })

      setTimeout(() => {
        history.push(`/register/${res.data.token}`);
      }, 1000)
    })
    .catch(err => {
      toast({
        title: "Error Occured",
        description: "Something Went Wrong",
        isClosable: true,
        status: "error",
        position: "top"
      })
    })
  }

  return (
    <>
    {isAuth() ? <Redirect to='/' /> : null}
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AssignmentIndIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registration
        </Typography>
        <form className={classes.form} onSubmit={register}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                required={true}
                value={firstName}
                onInput={ e=>setfirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onInput={ e=>setlastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="email"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onInput={ e=>setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onInput={ e=>setPassword(e.target.value)}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
            <center >
              <Button variant="outlined"
              color="inherited" ><b>Instructions</b></Button>
              </center>
            <br />
              <li> You can't miss even a single class, however if it happens make a recovery by viewing the Repeat Telecast Class.
            </li>

            <li> You have to complete the homework on a regular basis.
            </li>
            <li> If you have any problem or doubt with the concept, you should put it out infront of us immediately.
            </li>

            <li>If you were having any problem while attending the class, you must inform us once the class is over.
            </li>

            <li> Before attending the class make sure that you have a proper internet connection.
            </li>

            <li>Connect atleast 10 minutes before the class time.
            </li>

            <li>You must keep your video ON while attending the class.
            </li>

            <li>Student should have a very good practise of mute and unmute of audio.
            </li>

            <li>Student must sit on table and chair at home with full attention and in zero disturbance surrounding alike they sit in classroom.
            </li>
            
            <li>
              Don't share class link with any outsider this is only for you.
            </li>
            <li>
              Recording of this class is strictly prohibited in any form. Kindly refrain from indulging in such activities.
            </li>

            <li>
              Please note down all the material and questions provided to you in your notebook positively.
            </li>

            <li>
              Student should maintain the decorum of the class.
            </li>
            </Grid>
            <br />
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" required />}
                label="I agree to the terms and conditions"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
            
          <Grid container justify="flex-end">
            <Grid item>
              <Link onClick={signIn} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>

        <GoogleLogin clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`} onSuccess={ responseGoogle } onFailure={ responseGoogle } cookiePolicy={'single_host_origin'} 
          render={renderProps => (
            <Button onClick={renderProps.onClick} disabled={renderProps.disabled} id='googlelogin' variant="contained" className={classes.submit}>
              <img src="https://img.icons8.com/fluent/48/000000/google-logo.png" alt="Registration"/>
                Sign Up with GOOGLE
            </Button>
          )}>
        </GoogleLogin>
        <Button id='glogin' disabled >
          <u> For new users only </u>
        </Button>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
}