import styled from 'styled-components';

export const JoinTeacherInterface = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px;
    width: 100%
    height: 80vh;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`

export const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    height: 480px;
    padding: 0 50px;

    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0px;
        height: 300px;
    }
`

export const ShareScreenHolder = styled.video`
    width: 100%;
    height: 80%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    display: none;
`

export const VideoHolder = styled.video`    
    width: auto;
    border-radius: 50px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`

export const ButtonHolder = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    margin-top: ${({join}) => (join ? '10px' : '0px' )};
    width: ${({join}) => (join ? 'auto' : '40%')};
    padding: 0 45px;
    grid-area: holder;

    @media screen and (max-width: 768px){
        justify-content: center;
        align-item: center;
    }

`

export const ClassDetailContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    padding-top: 75px;

    @media screen and (max-width: 768px){
        width: 100%;
        padding-top: 30px;
    }
`

export const ClassDetailWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction : column;
    width: 100%;
`

export const ClassTopic = styled.div`
    font-size: 35px;
    font-family: Georgia;
    font-weight: 400;
    margin-bottom: 20px;
`

export const ClassTextWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    padding: 0 30px;
`

export const ClassDetail = styled.div`
    font-size: 20px;
    font-family: Vendata;
`

export const ClassStartText = styled.div`
    font-size: 25px;
    padding: 15px 0;
`

export const ClassBtnWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ButtonJoin = styled.div`
    border-radius: 4px;
    width: 80%;
    font-family: Georgia;
    background: ${({primary}) => (primary ? '#4B59F7': '#6B46C1')};
    white-space: nowrap;
    padding: ${({ big }) => ( big ? '14px 48px' : '10px 20px')};
    color: ${({ dark }) => (!dark ? '#fff' : '#6B46C1')};
    font-size: 20px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ?   '#6B46C1' : '#4B59F7')};
        border: none;
        transform: scale(1.02);
    }

    @media screen and (max-width: 960px) {
        width: 90%
    }
`

export const ClassInterface = styled.div`
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`

export const VideoHolderClass = styled.div`
    display: grid;
    grid-template-columns: 14fr 6fr;
    background-color: black;
    height: 85vh;

    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`

export const OwnVideoHolder = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
`

export const OwnVideo = styled.video`
    height: 100%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`

export const StudentVideoContainer = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    overflow: scroll;

    @media screen and (max-width: 768px){
        height: 40vh;
    }
`

export const ParticipantsHolder = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
`

export const Utils = styled.div`
    width: 100%;
    height: 15vh;
    background-color: #6B46C1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
        flex-direction: column;
        height: 30vh;
    }
`

export const Disconnect = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-areas: disconnect;
    width: 30%;

    @media screen and (max-width: 768px){
        padding: 20px;
        width: 100%;
    }
`

export const Attendence = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-areas: attendence;
    width: 30%;
    flex-direction: row;

    @media screen and (max-width: 768px){
        padding: 20px;
        width: 100%;
    }
`

export const MessageContainerTeacher = styled.div`
    display: flex;
    width: 100%;
    height: 75vh;
    flex-direction: column;

    @media screen and (max-width:768px){
        height: 30vh;
    }
`
