import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '@material-ui/core/Container';
import { Box, Stack, useToast } from '@chakra-ui/react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Schedule() {

  const toast = useToast();
  const toast_id = "toast_id";

  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState("");
  const [desc, setDesc] = useState(""); 

  const [name, setName] = useState("");
  const [formData, setFormData] = useState('')

  const upload = ({target: {files}}) => {
    let data = new FormData()
    data.append('material', files[0])
    data.append('name', name)
    data.append('uploadedBy', JSON.parse(localStorage.getItem('user'))._id)
    data.append('student', students);
    data.append('desc', desc);
    setFormData(data)
  }

  const handleChangeStudent = (event) => {
    setStudents(event.target.value);
  };

  const createAssignment = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_SERVER}/class/addStudyMaterial`, formData)
    .then((res) => {
        setName("");

        if(!toast.isActive(toast_id)){
            toast({
                id: toast_id,
                title: "Study Material Uploaded",
                position: "top-right",
                duration: 3000,
            })
        }   
    })
    .catch(err => {
        if(!toast.isActive(toast_id)){
            toast({
                id: toast_id,
                title: "Study Material Upload Failed",
                position: "top-right",
                duration: 3000,
            })
        }  
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER}/class/getClasses`, {
      teacherId: JSON.parse(localStorage.getItem("user"))._id
    })
    .then((res) => {
      setClasses(res.data.classes)
    })
    .catch((err) => {
      toast({
        title: "Error In Fetching Classes",
        duration: 3000,
        isClosable: true,
        position: "top"
      })
    })
  },[])

  return (
    <Container>
      <Box p={5}>
      <Grid elevation={6} spacing={4} >
        <center><Title >Upload Study Material</Title></center>
        <form onSubmit={createAssignment}>
          <Stack spacing={4}>
            <TextField
              margin="normal"
              required={true}
              label="Study Material Name"
              id="Assignment"
              name="Assignment"
              autoComplete="AssignmentName"
              autoFocus
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField 
              variant="standard"
              fullWidth
              required
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              label="Enter Description"
              type="text"
            />
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Students</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                value={students}
                onChange={handleChangeStudent}
                input={<Input />}
                required={true}
                MenuProps={MenuProps}
              >
                {classes.map((name) => (
                  <MenuItem key={name._id} value={name._id}>
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              name="assignment"
              type="file"
              id="file"
              fullWidth
              onChange={upload}
            />
            <p>Select a pdf file of not more than 15MB.</p>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Upload
            </Button>
            <Grid item></Grid>
          </Stack>
        </form>
      </Grid>
      </Box>
    </Container>
  );
}