import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export function ProtectedTeacherRoute(props){
    return (
        (JSON.parse(localStorage.getItem("user")).role === "teacher" || JSON.parse(localStorage.getItem("user")).role === "admin") ? 
            <Route path={props.path} exact={props.exact} component={props.component} /> 
        :  
        <Redirect to="/home" />)
}

export function ProtectedStudentRoute(props){
    return (
        (JSON.parse(localStorage.getItem("user")).role === "student") ? 
            <Route path={props.path} exact={props.exact} component={props.component} /> 
        :  
        <Redirect to="/home" />)
}

export function AdminRoute(props){
    return(
        (JSON.parse(localStorage.getItem("user")).role === "admin" ?
        <Route path={props.path} exact={props.exact} component={props.component} /> 
            :
        <Redirect to="/home" />
        )
    )
}

export function TeacherRoute(props){
    return(
        (JSON.parse(localStorage.getItem("user")).role === "teacher" ?
        <Route path={props.path} exact={props.exact} component={props.component} /> 
            :
        <Redirect to="/home" />
        )
    )
}

export function StudentRoute(props){
    return(
        (JSON.parse(localStorage.getItem("user")).role === "student" ?
        <Route path={props.path} exact={props.exact} component={props.component} /> 
            :
        <Redirect to="/home" />
        )
    )
}