const initialState = {};

const message = (state = initialState, action) => {
    switch(action.type) {
        case "ADD_MESSAGE": {
            const { messageId, sender, message, time, status } = action.payload;
            const messObject = { sender, message, time, status};

            return { ...state, [messageId]: messObject};
        }

        case "UPDATE_MESSAGE_STATUS": {
            const { messageId, status } = action.payload;
            const message = state[messageId]
            
            const newMessage = { ...message, status: status};

            return {...state, [messageId]: newMessage}
        }

        default: {
            return state;
        }
    }
}

export default message;