import React, { useEffect, useState } from "react"
import * as cookiesManager from "./stateManagers/cookiesManager";
import { connect } from "react-redux";
import * as stateActions from "./stateManagers/stateActions";
import { withRoomContext } from "../../helper/roomContext";
import { If } from "react-if";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import Recorder from './Recorder';
import axios from 'axios';

const Tabs = (props) => {

  const [micState, setMicState] = useState(false);

  useEffect(() => {
    const micStatus = cookiesManager.getMicStatus()
    setMicState((micStatus || {}).micEnabled)

    if(classClient._micProducer && !cookiesManager.getMicStatus().micEnabled) classClient.muteMic()
  }, [])

  useEffect(() => {
    if(classClient._micProducer && !cookiesManager.getMicStatus().micEnabled) classClient.muteMic()
  }, [micState])

  // let errorR, statusR, mediaBlobR, stopRecordingR, getMediaStreamR, startRecordingR; 

  const dispatch = useDispatch();
  const [realClass, setRealClass] = useState("");

  const {
    classClient,
    connected,
    me,
    peers,
    classes,
    audioProducer,
    videoProducer
  } = props;

  let webcamState;

  classClient.then((res) => {
    setRealClass(res);
  })

  if (!me.canSendWebcam) webcamState = "unsupported";
  else if (videoProducer && videoProducer.type !== "share") webcamState = "on";
  else webcamState = "off";

  let micStatus

  if (!me.canSendMic) micStatus = "unsupported";
  else if (!audioProducer) micStatus = "unsupported";
  else if (!audioProducer.paused && micState) micStatus = "on";
  else micStatus = "off";

  let shareState;

  if (Boolean(videoProducer) && videoProducer.type === "share")
    shareState = "on";
  else shareState = "off";

  let changeWebcamState;

  if (
    Boolean(videoProducer) &&
    videoProducer.type !== "share" &&
    me.canChangeWebcam
  )
    changeWebcamState = "on";
  else changeWebcamState = "unsupported";

  if(classClient._micProducer && !cookiesManager.getMicStatus().micEnabled) classClient._micProducer.pause()

  const attendence = (peers) => {
    let peerName = [];

    Object.values(peers).forEach((peer) => {
      peerName = [...peerName, peer.name]
    })
    
    if(peerName.length !== 0){
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER}/class/attendence/${classes.url}`,
        responseType: 'blob',
        headers:{},
        data:{
          attendence: peerName
        }
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute("download", `Attendence_${classes.url}.csv`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          alert("File Download Failed");
        });
    }
  }

  return (
    <If condition={connected}>
      <div 
        data-component="Tabs"
      >
      <div className="controls">
        <div
          className={classnames('button', 'webcam', webcamState, {
            disabled : me.webcamInProgress || me.shareInProgress
          })}
          onClick={() =>
          {
            if (webcamState === 'on')
            {
              cookiesManager.setDevices({ webcamEnabled: false });
              realClass.closeProducer("videoType");
            }
            else
            {
              cookiesManager.setDevices({ webcamEnabled: true });
              realClass.enableWebcam();
            }
          }}
        />
        <div
          onClick={micState ? () =>
          {
            setMicState(!micState)
            micStatus = "off"
            cookiesManager.setMicStatus({micEnabled: false})
               realClass.pauseProducer("audioType")
          }

              : () => {
                setMicState(!micState)
                micStatus = "on"
                cookiesManager.setMicStatus({micEnabled: true})
               realClass.resumeProducer("audioType")
            }
          }
          className={classnames('button', 'mic', micStatus)}
        />
        <If condition={me.type === "teacher"}>
          <>
        <div
          className={classnames('button', 'share', shareState, {
            disabled : me.shareInProgress || me.webcamInProgress
          })}
          onClick={() =>
          {
            if (shareState === 'on')
              realClass.closeProducer('screenType');
            else
              realClass.enableShare();
          }}
        />
        {
          me.device.type === "mobile" ? <></> : <Recorder />
        }
        <div className={classnames('button', 'muteAll')}
          onClick={() => realClass.muteAll() }
        >
        </div>
        <div className={classnames('button', 'attendence')}
          onClick={() => attendence(peers)}
        ></div>
        </>
        </If>
        <If condition={me.type === "student"}>
          <>
          <div className={classnames('button', 'handraise')}
            onClick={() => realClass.sendResponse('handraise')}
          ></div>
          <div className={classnames('button', 'understood')}
            onClick={() => realClass.sendResponse('understood')}
          ></div>
          <div className={classnames('button', 'notUnderstood')}
            onClick={() => realClass.sendResponse('notUnderstood')}
          ></div>
          </>
        </If>
        <div className={classnames('button', 'disconnect')}
          onClick={() => {window.location.href = `${process.env.REACT_APP_CLIENT}/${me.type}`}}
        >
        </div>
      </div>
      </div>
    </If>
  );
};

const mapStateToProps = (state) => {
  const producersArray = Object.values(state.producers);
  const audioProducer = producersArray.find(
    (producer) => producer.track.kind === "audio"
  );
  const videoProducer = producersArray.find(
    (producer) => producer.track.kind === "video"
  );
  const peers = state.peers;
  const classes = state.classes;

  return {
    connected: state.classes.state === "started" || state.classes.state === "entered",
    me: state.me,
    audioProducer: audioProducer,
    videoProducer: videoProducer,
    peers: peers,
    classes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStatsPeerId: (peerId) =>
      dispatch(stateActions.setRoomStatsPeerId(peerId)),
  };
};

const TabsContainer = withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(Tabs)
);

export default TabsContainer;
